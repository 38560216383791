//import React, { Component } from 'react';
import React from 'react';
//import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";

import timeConverterForBoxes from './../../../BoilerPlate/TimeConvertersForBoxes';
import TextField from '@material-ui/core/TextField';

import colors from './../../../ColorScheme/Colors';


const useStyles = theme => ({
  accordion: {
    background: colors.backgroundColor,
    border: '1px solid ' + colors.textColorBold,

//    minheight : '100vh',
  },
  searchcell: {
    width: "800px",
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },
  textfieldAccordion: {
    color: colors.textColorBold,
  },
  textfield: {
    color: colors.textColorPlain,
  },
});


const DateTextField = (props) => {
  const { classes } = props;

  if (props.editable === true) {
	  return (
		<div>
		  <TextField
		    className = {classes.searchcell}
			variant="outlined"
			margin="normal"
			fullWidth
			id={props.fieldName}
			label={props.fieldLabel}
			name={props.fieldName}
			type="date"
			inputProps={{
			   autoComplete: 'off',
			   className: classes.textfield,
			}}
		    InputLabelProps={{
		      shrink: true,
		      className: classes.textfield,
		    }}
		    defaultValue = {timeConverterForBoxes( props.defaultValue )}
		    onBlur={props.handleChange}
		  />
		</div>
	  );
  }
  else {
	  return (
		<div>
		  <TextField
		    className = {classes.searchcell}
			variant="outlined"
			margin="normal"
			fullWidth
			id={props.fieldName}
			label={props.fieldLabel}
			name={props.fieldName}
			type="date"
			inputProps={{
			   autoComplete: 'off',
			   className: classes.textfield,
			}}
		    InputLabelProps={{
		      shrink: true,
		      className: classes.textfield,
		    }}
		    value = {timeConverterForBoxes( props.defaultValue )}
		    onBlur={props.handleChange}
		  />
		</div>
	  );
  }
}

//class DateTextField extends Component {
////	constructor(props) {
////		super(props);
////	}

//	render() {
//      const { classes } = this.props;

//	  return (
//        <div>
//		  <TextField
//	        className = {classes.searchcell}
//			variant="outlined"
//			margin="normal"
//			fullWidth
//			id={this.props.fieldName}
//			label={this.props.fieldLabel}
//			name={this.props.fieldName}
//			type="date"
//			inputProps={{
//			   autoComplete: 'off',
//			   className: classes.textfield,
//			}}
//	        InputLabelProps={{
//	          shrink: true,
//	          className: classes.textfield,
//	        }}
//	        defaultValue = {timeConverterForBoxes( this.props.defaultValue )}
//	        onBlur={this.props.handleChange}
//		  />
//        </div>
//	  );
//	}
//}

export default withStyles(useStyles)(DateTextField);

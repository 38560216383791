import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import DisplayOnlyTextField from './CustomFields/DisplayOnlyTextField';
import RadioGroupYesNo from './CustomFields/RadioGroupYesNo';
import RadioGroupSymptoms from './CustomFields/RadioGroupSymptoms';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

const physicalListPre = ['HeadachePre',
                      'NauseaPre',
                      'VomitingPre',
                      'BalanceProblemsPre',
                      'DizzinessPre',
                      'VisualProblemsPre',
                      'FatiguePre',
                      'SensitivityToLightPre',
                      'SensitivityToNoisePre',
                      'NumbnessTinglingPre',
                   ]

const thinkingListPre = ['MentalFogPre',
                      'FeelingSlowedDownPre',
                      'DifficultyConcentratingPre',
                      'DifficultyRememberingPre',
					 ]

const sleepListPre = ['DrowsinessPre',
                   'SleepingLessThanUsualPre',
                   'SleepingMoreThanUsualPre',
                   'TroubleFallingAsleepPre',
			      ]

const emotionalListPre = ['IrritabilityPre',
                       'SadnessPre',
                       'NervousnessPre',
                       'FeelingMoreEmotionalPre',
			           ]

var totalListPre = [...physicalListPre];
totalListPre.push(...thinkingListPre);
totalListPre.push(...sleepListPre);
totalListPre.push(...emotionalListPre);


const physicalList = ['HeadachePost',
                      'NauseaPost',
                      'VomitingPost',
                      'BalanceProblemsPost',
                      'DizzinessPost',
                      'VisualProblemsPost',
                      'FatiguePost',
                      'SensitivityToLightPost',
                      'SensitivityToNoisePost',
                      'NumbnessTinglingPost',
                   ]

const thinkingList = ['MentalFogPost',
                      'FeelingSlowedDownPost',
                      'DifficultyConcentratingPost',
                      'DifficultyRememberingPost',
					 ]

const sleepList = ['DrowsinessPost',
                   'SleepingLessThanUsualPost',
                   'SleepingMoreThanUsualPost',
                   'TroubleFallingAsleepPost',
			      ]

const emotionalList = ['IrritabilityPost',
                       'SadnessPost',
                       'NervousnessPost',
                       'FeelingMoreEmotionalPost',
			           ]

var totalList = [...physicalList];
totalList.push(...thinkingList);
totalList.push(...sleepList);
totalList.push(...emotionalList);



class PCSSPost extends Component {
	constructor(props) {
		super(props);

		this.handleChangeNone = this.handleChangeNone.bind(this);
	}

	computeNumberOfSymptoms(list, event) {
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if ((value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else {
				total += 1
			}
		}

		return total
	}

	computePercentOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}

			if ((value !== '0') && (value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else if (value === '0') {
				total += 1
			}
			else {
				totalYes += 1
				total += 1
			}
		}

		const percent = totalYes / Math.max(total, 1) * 100

		return percent
	}

	computeAverageOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if (value === '0') {
				total += 1
			}
			else if (value === '1') {
				totalYes += 1
				total += 1
			}
			else if (value === '2') {
				totalYes += 2
				total += 1
			}
			else if (value === '3') {
				totalYes += 3
				total += 1
			}
			else if (value === '4') {
				totalYes += 4
				total += 1
			}
			else if (value === '5') {
				totalYes += 5
				total += 1
			}
			else if (value === '6') {
				totalYes += 6
				total += 1
			}
		}

		const average = totalYes / Math.max(total, 1)

		return average
	}

    handleChangeNone = event =>{
		// A placeholder to avoid an error.  Does nothing
    }

    handleChange = event =>{
		this.props.handleChange(event);
    }

    handleChangePhysical = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(physicalList, event)
		const percent = this.computePercentOfSymptoms(physicalList, event)
		const average = this.computeAverageOfSymptoms(physicalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		const pre = this.getSISPre()
		const improve = (pcssScore - pre) / Math.max(pre, 0.01) * 100.0


		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfPhysicalSymptomsEndorsedPost', 
            'PercentOfPhysicalSymptomsEndorsedPost', 
            'AverageScoreOfPhysicalSymptomsPost',
            'NumberOfTotalSymptomsEndorsedPost',
            'PercentOfTotalSymptomsEndorsedPost', 
            'AverageScoreOfTotalSymptomsPost',
            'PCSSIndexScorePost',
			'PercentImprovementPCSS'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore,
			improve
          ]
        );
    }

    handleChangeThinking = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(thinkingList, event)
		const percent = this.computePercentOfSymptoms(thinkingList, event)
		const average = this.computeAverageOfSymptoms(thinkingList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		const pre = this.getSISPre()
		const improve = (pcssScore - pre) / Math.max(pre, 0.01) * 100.0


		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfThinkingSymptomsEndorsedPost', 
            'PercentOfThinkingSymptomsEndorsedPost', 
            'AverageScoreOfThinkingSymptomsPost',
            'NumberOfTotalSymptomsEndorsedPost',
            'PercentOfTotalSymptomsEndorsedPost', 
            'AverageScoreOfTotalSymptomsPost',
            'PCSSIndexScorePost',
			'PercentImprovementPCSS'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore,
			improve
          ]
        );
    }

    handleChangeSleep = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(sleepList, event)
		const percent = this.computePercentOfSymptoms(sleepList, event)
		const average = this.computeAverageOfSymptoms(sleepList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		const pre = this.getSISPre()
		const improve = (pcssScore - pre) / Math.max(pre, 0.01) * 100.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfSleepSymptomsEndorsedPost', 
            'PercentOfSleepSymptomsEndorsedPost', 
            'AverageScoreOfSleepSymptomsPost',
            'NumberOfTotalSymptomsEndorsedPost',
            'PercentOfTotalSymptomsEndorsedPost', 
            'AverageScoreOfTotalSymptomsPost',
            'PCSSIndexScorePost',
			'PercentImprovementPCSS'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore,
			improve
          ]
        );
    }

    handleChangeEmotional = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(emotionalList, event)
		const percent = this.computePercentOfSymptoms(emotionalList, event)
		const average = this.computeAverageOfSymptoms(emotionalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		const pre = this.getSISPre()
		const improve = (pcssScore - pre) / Math.max(pre, 0.01) * 100.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfEmotionalSymptomsEndorsedPost', 
            'PercentOfEmotionalSymptomsEndorsedPost', 
            'AverageScoreOfEmotionalSymptomsPost',
            'NumberOfTotalSymptomsEndorsedPost',
            'PercentOfTotalSymptomsEndorsedPost', 
            'AverageScoreOfTotalSymptomsPost',
            'PCSSIndexScorePost',
			'PercentImprovementPCSS'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore,
			improve
          ]
        );
    }

	getSISPre = () => {
		var value = ''

		// Get the value from the right source
		if (this.props.patientJSON === '') {
			value = this.props.patientJSONDefault['PCSSIndexScorePre'];
		}			
		else if ('PCSSIndexScorePre' in this.props.patientJSON) {
			value = this.props.patientJSON['PCSSIndexScorePre'];
		}
		else {
			value = this.props.patientJSONDefault['PCSSIndexScorePre'];
		}

		return value
	}

	getNewest(eventName) {
		var value = 0
		if (this.props.patientJSON === '') {
			if (this.props.patientJSONDefault === '') {
			}
			else if (eventName in this.props.patientJSONDefault) {
				value = this.props.patientJSONDefault[eventName];
			}
		}
		else {
			if (eventName in this.props.patientJSON) {
				value = this.props.patientJSON[eventName];
			}
			else {
				if (this.props.patientJSONDefault === '') {
				}
				else if (eventName in this.props.patientJSONDefault) {
					value = this.props.patientJSONDefault[eventName];
				}
			}		
		}

		return value
	}

//	computeImprovement(key) {
//		var pre = 1.0 //this.computePCSSIndexScore(totalListPre, '')
//		var post = 2.0 // this.computePCSSIndexScore(totalList, '')

//		const improve = (post - pre) / Math.max(pre, 0.01) * 100.0

//		this.props.handlePatientJSONUpdate([key], [improve]);

//		return improve
//	}


	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <RadioGroupSymptoms 
            fieldName = "HeadachePost"
            fieldLabel = "Headache - Post"
            defaultValue={this.props.patientJSONDefault.HeadachePost} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NauseaPost"
            fieldLabel = "Nausea - Post"
            defaultValue={this.props.patientJSONDefault.NauseaPost} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VomitingPost"
            fieldLabel = "Vomiting - Post"
            defaultValue={this.props.patientJSONDefault.VomitingPost} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "BalanceProblemsPost"
            fieldLabel = "Balance Problems - Post"
            defaultValue={this.props.patientJSONDefault.BalanceProblemsPost} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DizzinessPost"
            fieldLabel = "Dizziness - Post"
            defaultValue={this.props.patientJSONDefault.DizzinessPost} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VisualProblemsPost"
            fieldLabel = "Visual Problems - Post"
            defaultValue={this.props.patientJSONDefault.VisualProblemsPost} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FatiguePost"
            fieldLabel = "Fatigue - Post"
            defaultValue={this.props.patientJSONDefault.FatiguePost} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToLightPost"
            fieldLabel = "Sensitivity to Light - Post"
            defaultValue={this.props.patientJSONDefault.SensitivityToLightPost} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToNoisePost"
            fieldLabel = "Sensitivity to Noise - Post"
            defaultValue={this.props.patientJSONDefault.SensitivityToNoisePost} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NumbnessTinglingPost"
            fieldLabel = "Numbness or Tingling - Post"
            defaultValue={this.props.patientJSONDefault.NumbnessTinglingPost} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "MentalFogPost"
            fieldLabel = "Mental Fog - Post"
            defaultValue={this.props.patientJSONDefault.MentalFogPost} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingSlowedDownPost"
            fieldLabel = "Feeling Slowed Down - Post"
            defaultValue={this.props.patientJSONDefault.FeelingSlowedDownPost} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyConcentratingPost"
            fieldLabel = "Difficulty Concentrating - Post"
            defaultValue={this.props.patientJSONDefault.DifficultyConcentratingPost} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyRememberingPost"
            fieldLabel = "Difficulty Remembering - Post"
            defaultValue={this.props.patientJSONDefault.DifficultyRememberingPost} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DrowsinessPost"
            fieldLabel = "Drowsiness - Post"
            defaultValue={this.props.patientJSONDefault.DrowsinessPost} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingLessThanUsualPost"
            fieldLabel = "Sleeping Less Than Usual - Post"
            defaultValue={this.props.patientJSONDefault.SleepingLessThanUsualPost} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingMoreThanUsualPost"
            fieldLabel = "Sleeping More Than Usual - Post"
            defaultValue={this.props.patientJSONDefault.SleepingMoreThanUsualPost} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "TroubleFallingAsleepPost"
            fieldLabel = "Trouble Falling Asleep - Post"
            defaultValue={this.props.patientJSONDefault.TroubleFallingAsleepPost} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "IrritabilityPost"
            fieldLabel = "Irritability - Post"
            defaultValue={this.props.patientJSONDefault.IrritabilityPost} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SadnessPost"
            fieldLabel = "Sadness - Post"
            defaultValue={this.props.patientJSONDefault.SadnessPost} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NervousnessPost"
            fieldLabel = "Nervousness - Post"
            defaultValue={this.props.patientJSONDefault.NervousnessPost} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingMoreEmotionalPost"
            fieldLabel = "Feeling More Emotional - Post"
            defaultValue={this.props.patientJSONDefault.FeelingMoreEmotionalPost} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "PainOtherThanHeadachePost"
            fieldLabel = "Pain Other Than Headache - Post"
            defaultValue={this.props.patientJSONDefault.PainOtherThanHeadachePost} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithPhysicalActivityPost"
	        fieldLabel = "Worsen with Physical Activity - Post"
	        defaultValue={this.props.patientJSONDefault.WorsenWithPhysicalActivityPost}
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithCognitiveActivityPost"
	        fieldLabel = "Worsen with Cognitive Activity - Post"
	        defaultValue={this.props.patientJSONDefault.WorsenWithCognitiveActivityPost} 
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <DisplayOnlyTextField 
	        fieldName = "PCSSIndexScorePost"
	        fieldLabel = "PCSS Index Score - Post (Calculated)"
	        value = {this.getNewest("PCSSIndexScorePost").toFixed(2)}
	        handleChange={this.handleChange}
	      />

		  <DisplayOnlyTextField 
	        fieldName = "PercentImprovementPCSS"
	        fieldLabel = "Percent PCSS Improvement (Calculated)"
	        value = {this.getNewest('PercentImprovementPCSS').toFixed(2)}
	        handleChange={this.handleChange}
	      />

        </div>
	  );
	}
}

export default withStyles(useStyles)(PCSSPost);



//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfPhysicalSymptomsEndorsedPost"
//	        fieldLabel = "Number of Physical Symptoms Endorsed - Post (Calculated)"
//	        value = {this.getNewest('NumberOfPhysicalSymptomsEndorsedPost')}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfPhysicalSymptomsEndorsedPost"
//	        fieldLabel = "Percent of Physical Symptoms Endorsed - Post (Calculated)"
//	        value = {this.getNewest('PercentOfPhysicalSymptomsEndorsedPost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfPhysicalSymptomsPost"
//	        fieldLabel = "Average Score of Physical Symptoms - Post (Calculated)"
//	        value = {this.getNewest('AverageScoreOfPhysicalSymptomsPost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfThinkingSymptomsEndorsedPost"
//	        fieldLabel = "Number of Thinking Symptoms Endorsed - Post (Calculated)"
//	        value = {this.getNewest('NumberOfThinkingSymptomsEndorsedPost')}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfThinkingSymptomsEndorsedPost"
//	        fieldLabel = "Percent of Thinking Symptoms Endorsed - Post (Calculated)"
//	        value = {this.getNewest('PercentOfThinkingSymptomsEndorsedPost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfThinkingSymptomsPost"
//	        fieldLabel = "Average Score of Thinking Symptoms - Post (Calculated)"
//	        value = {this.getNewest('AverageScoreOfThinkingSymptomsPost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfSleepSymptomsEndorsedPost"
//	        fieldLabel = "Number of Sleep Symptoms Endorsed - Post (Calculated)"
//	        value = {this.getNewest('NumberOfSleepSymptomsEndorsedPost')}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfSleepSymptomsEndorsedPost"
//	        fieldLabel = "Percent of Sleep Symptoms Endorsed - Post (Calculated)"
//	        value = {this.getNewest('PercentOfSleepSymptomsEndorsedPost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfSleepSymptomsPost"
//	        fieldLabel = "Average Score of Sleep Symptoms - Post (Calculated)"
//	        value = {this.getNewest('AverageScoreOfSleepSymptomsPost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfEmotionalSymptomsEndorsedPost"
//	        fieldLabel = "Number of Emotional Symptoms Endorsed - Post (Calculated)"
//	        value = {this.getNewest('NumberOfEmotionalSymptomsEndorsedPost')}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfEmotionalSymptomsEndorsedPost"
//	        fieldLabel = "Percent of Emotional Symptoms Endorsed - Post (Calculated)"
//	        value = {this.getNewest('PercentOfEmotionalSymptomsEndorsedPost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfEmotionalSymptomsPost"
//	        fieldLabel = "Average Score of Emotional Symptoms - Post (Calculated)"
//	        value = {this.getNewest('AverageScoreOfEmotionalSymptomsPost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfTotalSymptomsEndorsedPost"
//	        fieldLabel = "Number of Total Symptoms Endorsed - Post (Calculated)"
//	        value = {this.getNewest('NumberOfTotalSymptomsEndorsedPost')}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfTotalSymptomsEndorsedPost"
//	        fieldLabel = "Percent of Total Symptoms Endorsed - Post (Calculated)"
//	        value = {this.getNewest('PercentOfTotalSymptomsEndorsedPost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfTotalSymptomsPost"
//	        fieldLabel = "Average Score of All Symptoms - Post (Calculated)"
//	        value = {this.getNewest('AverageScoreOfTotalSymptomsPost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PCSSIndexScorePost"
//	        fieldLabel = "PCSS Index Score - Post (Calculated)"
//	        value = {this.getNewest('PCSSIndexScorePost').toFixed(2)}
//	        handleChange={this.handleChange}
//	      />






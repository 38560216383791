import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import StandardTextField from './CustomFields/StandardTextField';
import RadioGroupSymptoms from './CustomFields/RadioGroupSymptoms';
import DateTextField from './CustomFields/DateTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});


class CovidPre extends Component {
	constructor(props) {
		super(props);

		this.handleChangeNone = this.handleChangeNone.bind(this);
	}

    handleChangeNone = event =>{
		// A placeholder to avoid an error.  Does nothing
    }

    handleChange = event =>{
		this.props.handleChange(event);
    }

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <RadioGroupSymptoms 
            fieldName = "CovidHeadachePre"
            fieldLabel = "Covid Headache - Pre"
            defaultValue={this.props.patientJSONDefault.CovidHeadachePre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidBalanceProblemsPre"
            fieldLabel = "Covid Balance Problems - Pre"
            defaultValue={this.props.patientJSONDefault.CovidBalanceProblemsPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidDrowsinessPre"
            fieldLabel = "Covid Drowsiness - Pre"
            defaultValue={this.props.patientJSONDefault.CovidDrowsinessPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />
          
          <RadioGroupSymptoms 
            fieldName = "CovidFeelingSlowedDownPre"
            fieldLabel = "Covid Feeling Slow Down - Pre"
            defaultValue={this.props.patientJSONDefault.CovidFeelingSlowedDownPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />
          
          <RadioGroupSymptoms 
            fieldName = "CovidSensitivityLightPre"
            fieldLabel = "Covid Light Sensitivity - Pre"
            defaultValue={this.props.patientJSONDefault.CovidSensitivityLightPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />
          
          <RadioGroupSymptoms 
            fieldName = "CovidNauseaPre"
            fieldLabel = "Covid Nausea - Pre"
            defaultValue={this.props.patientJSONDefault.CovidNauseaPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />
          
          <RadioGroupSymptoms 
            fieldName = "CovidSensitivityNoisePre"
            fieldLabel = "Covid Noise Sensitivity - Pre"
            defaultValue={this.props.patientJSONDefault.CovidSensitivityNoisePre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />
          
          <RadioGroupSymptoms 
            fieldName = "CovidVomitingPre"
            fieldLabel = "Covid Vomiting - Pre"
            defaultValue={this.props.patientJSONDefault.CovidVomitingPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />
	
		  <RadioGroupSymptoms 
            fieldName = "CovidDizzinessPre"
            fieldLabel = "Covid Dizziness - Pre"
            defaultValue={this.props.patientJSONDefault.CovidDizzinessPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidVisualProblemsPre"
            fieldLabel = "Covid VisualProblems - Pre"
            defaultValue={this.props.patientJSONDefault.CovidVisualProblemsPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidFatiguePre"
            fieldLabel = "Covid Fatigue - Pre"
            defaultValue={this.props.patientJSONDefault.CovidFatiguePre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidNumbnessTinglingPre"
            fieldLabel = "Covid Numbness Tingling - Pre"
            defaultValue={this.props.patientJSONDefault.CovidNumbnessTinglingPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidMentalFogPre"
            fieldLabel = "Covid Mental Fog - Pre"
            defaultValue={this.props.patientJSONDefault.CovidMentalFogPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidDifficultyConcentratingPre"
            fieldLabel = "Covid Difficulty Concentrating - Pre"
            defaultValue={this.props.patientJSONDefault.CovidDifficultyConcentratingPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidDifficultyRememberingPre"
            fieldLabel = "Covid Difficulty Remembering - Pre"
            defaultValue={this.props.patientJSONDefault.CovidDifficultyRememberingPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidSleepingLessThanUsualPre"
            fieldLabel = "Covid Sleeing Less Than Usual - Pre"
            defaultValue={this.props.patientJSONDefault.CovidSleepingLessThanUsualPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidSleepingMoreThanUsualPre"
            fieldLabel = "Covid Sleeping More Than Usual - Pre"
            defaultValue={this.props.patientJSONDefault.CovidSleepingMoreThanUsualPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidTroubleFallingAsleepPre"
            fieldLabel = "Covid Trouble Falling Asleep - Pre"
            defaultValue={this.props.patientJSONDefault.CovidTroubleFallingAsleepPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidIrritabilityPre"
            fieldLabel = "Covid Irritability - Pre"
            defaultValue={this.props.patientJSONDefault.CovidIrritabilityPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidSadnessPre"
            fieldLabel = "Covid Sadness - Pre"
            defaultValue={this.props.patientJSONDefault.CovidSadnessPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidNervousnessPre"
            fieldLabel = "Covid Nervousness - Pre"
            defaultValue={this.props.patientJSONDefault.CovidNervousnessPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidAnxiousnessPre"
            fieldLabel = "Covid Anxiousness - Pre"
            defaultValue={this.props.patientJSONDefault.CovidAnxiousnessPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />
          
		  <RadioGroupSymptoms 
            fieldName = "CovidFeelingMoreEmotionalPre"
            fieldLabel = "Covid Feedling More Emotional - Pre"
            defaultValue={this.props.patientJSONDefault.CovidFeelingMoreEmotionalPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidCoughPre"
            fieldLabel = "Covid Cough - Pre"
            defaultValue={this.props.patientJSONDefault.CovidCoughPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidDifficultySpeakingPre"
            fieldLabel = "Covid Difficulty Speaking - Pre"
            defaultValue={this.props.patientJSONDefault.CovidDifficultySpeakingPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidGIDisruptionPre"
            fieldLabel = "Covid GI Disruption - Pre"
            defaultValue={this.props.patientJSONDefault.CovidGIDisruptionPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidLossOfSmellTastePre"
            fieldLabel = "Covid Loss of Smell and/or Taste - Pre"
            defaultValue={this.props.patientJSONDefault.CovidLossOfSmellTastePre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidPersonalityChangesPre"
            fieldLabel = "Covid Personality Changes - Pre"
            defaultValue={this.props.patientJSONDefault.CovidPersonalityChangesPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidSensitivityLightNoisePre"
            fieldLabel = "Covid Sensitivity to Light and/or Noise - Pre"
            defaultValue={this.props.patientJSONDefault.CovidSensitivityLightNoisePre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidDifficultyBreathingPre"
            fieldLabel = "Covid Difficulty Breathing - Pre"
            defaultValue={this.props.patientJSONDefault.CovidDifficultyBreathingPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidSymptomsIncreaseWithCognitiveActivityPre"
            fieldLabel = "Covid Symptoms Increase with Cognitive Activity - Pre"
            defaultValue={this.props.patientJSONDefault.CovidSymptomsIncreaseWithCognitiveActivityPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidSymptomsIncreaseWithPhysicalActivityPre"
            fieldLabel = "Covid Symptoms Increase with Physical Activity - Pre"
            defaultValue={this.props.patientJSONDefault.CovidSymptomsIncreaseWithPhysicalActivityPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidWakingUpNotRestedPre"
            fieldLabel = "Covid Waking Up Not Rested - Pre"
            defaultValue={this.props.patientJSONDefault.CovidWakingUpNotRestedPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidPainChestPre"
            fieldLabel = "Covid Chest Pain - Pre"
            defaultValue={this.props.patientJSONDefault.CovidPainChestPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidPainJointsPre"
            fieldLabel = "Covid Joint Pain - Pre"
            defaultValue={this.props.patientJSONDefault.CovidPainJointsPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidPainMusclesPre"
            fieldLabel = "Covid Muscle Pain - Pre"
            defaultValue={this.props.patientJSONDefault.CovidPainMusclesPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidPainNeckPre"
            fieldLabel = "Covid Neck Pain - Pre"
            defaultValue={this.props.patientJSONDefault.CovidPainNeckPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidPainStomachPre"
            fieldLabel = "Covid Stomach Pain - Pre"
            defaultValue={this.props.patientJSONDefault.CovidPainStomachPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "CovidPainOtherPre"
            fieldLabel = "Covid Pain - Other - Pre"
            defaultValue={this.props.patientJSONDefault.CovidPainOtherPre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />
          
		  <StandardTextField 
            fieldName = "CovidChangeInBloodPressurePre"
            fieldLabel = "Covid Change in Blood Pressure - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInBloodPressurePre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidChangeInFaintOrLightheadedPre"
            fieldLabel = "Covid Change in Faintness or Lightheadedness - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInFaintOrLightheadedPre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidChangeInHairLossPre"
            fieldLabel = "Covid Change in Hair Loss - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInHairLossPre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidChangeInHeartRatePre"
            fieldLabel = "Covid Change in Heart Rate - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInHeartRatePre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidChangeInHormonesPre"
            fieldLabel = "Covid Change in Hormones - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInHormonesPre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidChangeInHungerOrThirstPre"
            fieldLabel = "Covid Change in Hunger or Thirst - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInHungerOrThirstPre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidChangeInRashPre"
            fieldLabel = "Covid Change in Rash - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInRashPre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidChangeInSexualFunctionPre"
            fieldLabel = "Covid Change in Sexual Function - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInSexualFunctionPre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidChangeInSwallowingPre"
            fieldLabel = "Covid Change in Swallowing - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInSwallowingPre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidChangeInTemperatureRegPre"
            fieldLabel = "Covid Change in Temperature Regulation - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInTemperatureRegPre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidChangeInTinnitusPre"
            fieldLabel = "Covid Change in Tinnitus - Pre"
            defaultValue={this.props.patientJSONDefault.CovidChangeInTinnitusPre} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <DateTextField 
            fieldName = "CovidWhenDidYouFirstExperienceSymptoms"
            fieldLabel = "When Did You First Experience Symptoms?"
            defaultValue={this.props.patientJSONDefault.CovidWhenDidYouFirstExperienceSymptoms} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CovidDidYouReceivePositiveTestOrDX"
            fieldLabel = "Did You Receieve a Positive Test or Diagnosis?"
            defaultValue={this.props.patientJSONDefault.CovidDidYouReceivePositiveTestOrDX} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "CovidWhatCareDidYouReceiveEarly"
            fieldLabel = "What Care Did You Receive Early?"
            defaultValue={this.props.patientJSONDefault.CovidWhatCareDidYouReceiveEarly} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "CovidDescribeAnyTestingOrTreatments"
            fieldLabel = "Describe Any Testing or Treatments"
            defaultValue={this.props.patientJSONDefault.CovidDescribeAnyTestingOrTreatments} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "CovidHaveYouHadABrainInjury"
            fieldLabel = "Have You Had a Brain Injury?"
            defaultValue={this.props.patientJSONDefault.CovidHaveYouHadABrainInjury} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "CovidAdditionalBrainInjuryInfo"
            fieldLabel = "Additional Brain Injury Info"
            defaultValue={this.props.patientJSONDefault.CovidAdditionalBrainInjuryInfo} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
	
        </div>
	  );
	}
}

export default withStyles(useStyles)(CovidPre);








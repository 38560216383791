//import React from 'react';



export default function timeConverterForBoxes(timeObject){
  // If the key exists:
  if (typeof timeObject !== 'undefined') {
	  if ("$date" in timeObject) {
		  const a = new Date(timeObject.$date);
          const localDate = new Date(a.getTime() + a.getTimezoneOffset() * 60000)
		  const year = localDate.getFullYear();
		  const month = ('0' + (localDate.getMonth()+1)).slice(-2);
		  const day = ('0' + (localDate.getDate())).slice(-2);
		  const time = `${year}-${month}-${day}`
//		  console.log(time, 'time');
		  return time;
	  } else {
	  	return ''
		}
    } else {
      return ''
      }
}

import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import StandardTextField from './CustomFields/StandardTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

class FNCISafety extends Component {
//	constructor(props) {
//		super(props);
//	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <StandardTextField 
            fieldName = "PatientWeight"
            fieldLabel = "Patient Weight"
            defaultValue={this.props.patientJSONDefault.PatientWeight} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "PatientHeight"
            fieldLabel = "Patient Height"
            defaultValue={this.props.patientJSONDefault.PatientHeight} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "Pregnant"
            fieldLabel = "Pregnant"
            defaultValue={this.props.patientJSONDefault.Pregnant} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "MetalInBody"
            fieldLabel = "Metal In Body"
            defaultValue={this.props.patientJSONDefault.MetalInBody} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "MetalInBodyDescription"
            fieldLabel = "Metal In Body Description"
            defaultValue={this.props.patientJSONDefault.MetalInBodyDescription} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "CorrectiveLenses"
            fieldLabel = "Corrective Lenses"
            defaultValue={this.props.patientJSONDefault.CorrectiveLenses} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "BracesOrPermanentRetainer"
            fieldLabel = "Braces Or Permanent Retainer"
            defaultValue={this.props.patientJSONDefault.BracesOrPermanentRetainer} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "TypeOfBracesOrPermanentRetainer"
            fieldLabel = "Type of Braces or Permanent Retainer"
            defaultValue={this.props.patientJSONDefault.TypeOfBracesOrPermanentRetainer} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "Claustrophobic"
            fieldLabel = "Claustrophobic"
            defaultValue={this.props.patientJSONDefault.Claustrophobic} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "AneurysmClip"
            fieldLabel = "Aneurysm Clip"
            defaultValue={this.props.patientJSONDefault.AneurysmClip} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "PacemakerOrICD"
            fieldLabel = "Pacemaker Or ICD"
            defaultValue={this.props.patientJSONDefault.PacemakerOrICD} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "ElectronicImplant"
            fieldLabel = "Electronic Implant"
            defaultValue={this.props.patientJSONDefault.ElectronicImplant} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "TissueExpander"
            fieldLabel = "Tissue Expander"
            defaultValue={this.props.patientJSONDefault.TissueExpander} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "InjuryInEyeIromMetal"
            fieldLabel = "Metal in the Eye From an Injury"
            defaultValue={this.props.patientJSONDefault.InjuryInEyeIromMetal} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "InplantHeldByAMagnet"
            fieldLabel = "Inplant Held in Place by a Magnet"
            defaultValue={this.props.patientJSONDefault.InplantHeldByAMagnet} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "Biostimulator"
            fieldLabel = "Biostimulator"
            defaultValue={this.props.patientJSONDefault.Biostimulator} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "EarImplant"
            fieldLabel = "Ear Implant"
            defaultValue={this.props.patientJSONDefault.EarImplant} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DrugPump"
            fieldLabel = "Drug Pump"
            defaultValue={this.props.patientJSONDefault.DrugPump} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "InternalElectrodes"
            fieldLabel = "Internal Electrodes"
            defaultValue={this.props.patientJSONDefault.InternalElectrodes} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "MagneticallyActivatedDevice"
            fieldLabel = "Magnetically Activated Device"
            defaultValue={this.props.patientJSONDefault.MagneticallyActivatedDevice} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "SpinalFusionOrFixationDevice"
            fieldLabel = "Spinal Fusion or Fixation Device"
            defaultValue={this.props.patientJSONDefault.SpinalFusionOrFixationDevice} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "SpinalCordStimulator"
            fieldLabel = "Spinal Cord Stimulator"
            defaultValue={this.props.patientJSONDefault.SpinalCordStimulator} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "Neurostimulator"
            fieldLabel = "Neurostimulator"
            defaultValue={this.props.patientJSONDefault.Neurostimulator} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "Prosthesis"
            fieldLabel = "Prosthesis"
            defaultValue={this.props.patientJSONDefault.Prosthesis} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "PiercingOrTattoo"
            fieldLabel = "Piercing or Tattoo"
            defaultValue={this.props.patientJSONDefault.PiercingOrTattoo} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "PostSurgicalHardware"
            fieldLabel = "Post Surgical Hardware"
            defaultValue={this.props.patientJSONDefault.PostSurgicalHardware} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "HearingAid"
            fieldLabel = "Hearing Aid"
            defaultValue={this.props.patientJSONDefault.HearingAid} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "EmergencyInjuryFromAMetalObject"
            fieldLabel = "Injury From a Metal Object"
            defaultValue={this.props.patientJSONDefault.EmergencyInjuryFromAMetalObject} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "AccessPort"
            fieldLabel = "Access Port"
            defaultValue={this.props.patientJSONDefault.AccessPort} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "JointReplacement"
            fieldLabel = "Joint Replacement"
            defaultValue={this.props.patientJSONDefault.JointReplacement} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "MetallicStent"
            fieldLabel = "Metallic Stent"
            defaultValue={this.props.patientJSONDefault.MetallicStent} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "RadiationSeedsOrImplants"
            fieldLabel = "Radiation Seeds or Implants"
            defaultValue={this.props.patientJSONDefault.RadiationSeedsOrImplants} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "Shunts"
            fieldLabel = "Shunts"
            defaultValue={this.props.patientJSONDefault.Shunts} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "SurgicalStaplesSutures"
            fieldLabel = "Surgical Staples or Sutures"
            defaultValue={this.props.patientJSONDefault.SurgicalStaplesSutures} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "WigOrHairImplantsWithMetal"
            fieldLabel = "Wig or Hair Implants with Metal"
            defaultValue={this.props.patientJSONDefault.WigOrHairImplantsWithMetal} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />



        </div>
	  );
	}
}

export default withStyles(useStyles)(FNCISafety);




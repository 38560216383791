import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Amplify from 'aws-amplify';

Amplify.configure({
    Auth: {

//        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//        identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

        // REQUIRED - Amazon Cognito Region
        region: 'us-west-2',

//        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
//        // Required only if it's different from Amazon Cognito Region
//        identityPoolRegion: 'XX-XXXX-X',


        // OPTIONAL - Amazon Cognito User Pool ID
//        userPoolId: 'us-west-2_58ntZymwM',  // personal
        userPoolId: 'us-west-2_c18vQ40Br',  // cfx

//        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//        userPoolWebClientId: '59brt5uu67127qjrllvjf78nt4',  // personal
        userPoolWebClientId: '71rs96icqf4ncu1oid2ighmq2',  // cfx

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

//        // OPTIONAL - Configuration for cookie storage
//        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
//        cookieStorage: {
//        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
//            domain: '.yourdomain.com',
//        // OPTIONAL - Cookie path
//            path: '/',
//        // OPTIONAL - Cookie expiration in days
//            expires: 365,
//        // OPTIONAL - Cookie secure flag
//        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
//            secure: true
//        },

//        // OPTIONAL - customized storage object
//        storage: MyStorage,

//        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
//        authenticationFlowType: 'USER_PASSWORD_AUTH',

//        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
//        clientMetadata: { myCustomKey: 'myCustomValue' },

         // OPTIONAL - Hosted UI configuration
//        oauth: {
//            domain: 'https://testpool111.auth.us-west-2.amazoncognito.com',
//            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
//            redirectSignIn: 'https://54.219.194.157/',
//            redirectSignOut: 'https://54.219.194.157/',
//            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
//        }
        oauth: {
            domain: 'https://cfx1.auth.us-west-2.amazoncognito.com',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://54.71.17.208/',
            redirectSignOut: 'http://54.71.17.208/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});

// You can get the current config object
//const currentConfig = Auth.configure();


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();





//import Amplify, { Auth } from 'aws-amplify';
//import awsconfig from './aws-exports';

//Amplify.configure(awsconfig);




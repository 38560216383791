import React, { Component} from 'react'
import { withStyles } from "@material-ui/core/styles"

import { withAuthenticator } from '@aws-amplify/ui-react';


import Home from './Home/Home';
import NewPatient from './PatientPage/NewPatient';
import ViewPatient from './PatientPage/ViewPatient';
import EditPatient from './PatientPage/EditPatient';
import DeletePatient from './PatientPage/DeletePatient';
import UploadJotformPatients from './PatientPage/UploadJotformPatients';
//import UploadJotformCovidPatients from './PatientPage/UploadJotformCovidPatients';
import LoggedOut from './BoilerPlate/LoggedOut';
import colors from './ColorScheme/Colors';


const useStyles = theme => ({
  allPage: {
//    minheight : '100vh',
    backgroundColor: colors.backgroundColor,
  },
});

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
		  whichPage: "Home",
		  patientJSONID: "",
		};
	}

	getPageContent(whichPage, setPage, patientJSONID) {
	  switch (whichPage) {
		case "Home":
		  return <Home setPage={setPage} />;
		case "NewPatient":
		  return <NewPatient setPage={setPage} />;
		case "ViewPatient":
		  return <ViewPatient setPage={setPage} patientJSONID={this.state.patientJSONID} />;
		case "EditPatient":
		  return <EditPatient setPage={setPage} patientJSONID={this.state.patientJSONID} />;
		case "DeletePatient":
		  return <DeletePatient setPage={setPage} patientJSONID={this.state.patientJSONID} />;
		case "JotformUpload":
		  return <UploadJotformPatients setPage={setPage} />;
		//case "JotformCovidUpload":
		//  return <UploadJotformCovidPatients setPage={setPage} />;
		case "LoggedOut":
		  return <LoggedOut setPage={setPage} patientJSONID={this.state.patientJSONID} />;
		default:
		  throw new Error('Unknown page');
	  }
	}

	setPage = (pageName, patientJSONID) => {
		this.setState(state => ({ whichPage: pageName }));
		this.setState(state => ({ patientJSONID: patientJSONID }));
	};

	render() {
//        const { classes } = this.props;

		return (
		  <React.Fragment>
			{this.getPageContent(this.state.whichPage, this.setPage, '')}
		  </React.Fragment>
		);
	}
}

export default withAuthenticator(withStyles(useStyles)(App), false);
//export default withStyles(useStyles)(App);



//		  <React.Fragment className={classes.allPage}>

//	componentDidMount() {
//	 this.updateLoggedStatus();
//	 Hub.listen('auth', this.updateLoggedStatus);
//	}

//	componentWillUnmount() {
//	 Hub.remove('auth');
//	}

//	updateLoggedStatus = async () => {
//	 const username = await this.getCurrentUsername()
//	 let newLoggedStatus = false;
//	 if (username) newLoggedStatus = true;
//	 this.setState({ isLogged: newLoggedStatus });
////	 this.forceUpdate();
//	 this.setPage("Home");
////	 this.setState(state => ({ isLogged: 'logged' }));
//	}

//	getCurrentUsername()  {
//	 return new Promise((resolve, reject) => {
//	 Auth.currentAuthenticatedUser()
//	  .then(user => {
//		  if (user.username) {
//	   console.log(user.username)
//	   resolve(user.username)
//		  } else {
//	   console.log(user)
//	   resolve(null)
//		  }
//	  })
//	  .catch(err => {
//		  console.log(err)
//		  resolve(null)
//	  });
//	 })
//	}


//	async signOut() {
//		await Auth.signOut()
//			 .then(data => console.log(data))
//			 .catch(err => console.log(err));

////			.then(() => {
////				if (this.props.onStateChange) { this.props.onStateChange('signIn', null); }
////				this.props.onStateChange('signedOut', null);
////				window.location.reload(false);
////		})
////			.catch(err => {
////			console.log('err: ', err)
////		})
//	}


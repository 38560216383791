import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import StandardTextField from './CustomFields/StandardTextField';
import DateTextField from './CustomFields/DateTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

class MedicalHistory extends Component {
//	constructor(props) {
//		super(props);
//	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <StandardTextField 
            fieldName = "patient_last_name2"
            fieldLabel = "Patient Last name 2"
            defaultValue={this.props.patientJSONDefault.patient_last_name2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "patient_phone_type"
            fieldLabel = "Patient Phone Type (mobile, home)"
            defaultValue={this.props.patientJSONDefault.patient_phone_type} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "patient_phone_prov"
            fieldLabel = "Patient Phone Provider"
            defaultValue={this.props.patientJSONDefault.patient_phone_prov} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "patient_handed"
            fieldLabel = "Patient Handed"
            defaultValue={this.props.patientJSONDefault.patient_handed} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "patientAddress2"
            fieldLabel = "Patient Address 2"
            defaultValue={this.props.patientJSONDefault.patientAddress2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "military_branch"
            fieldLabel = "Military Branch"
            defaultValue={this.props.patientJSONDefault.military_branch} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "military_speciality"
            fieldLabel = "Military Specialty"
            defaultValue={this.props.patientJSONDefault.military_speciality} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "military_deployment"
            fieldLabel = "Military Deployment"
            defaultValue={this.props.patientJSONDefault.military_deployment} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "avg_hour_sleep"
            fieldLabel = "Average Hours of Sleep"
            defaultValue={this.props.patientJSONDefault.avg_hour_sleep} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "ethnicity"
            fieldLabel = "Ethnicity"
            defaultValue={this.props.patientJSONDefault.ethnicity} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "edu_level"
            fieldLabel = "Education Level"
            defaultValue={this.props.patientJSONDefault.edu_level} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "lenghtOfLOC"
            fieldLabel = "Length of Loss of Consciousness"
            defaultValue={this.props.patientJSONDefault.lenghtOfLOC} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "confusionOrDisorientation"
            fieldLabel = "Confusion or Disorientation"
            defaultValue={this.props.patientJSONDefault.confusionOrDisorientation} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "postTraumaticAmnesia"
            fieldLabel = "Post Traumatic Amnesia"
            defaultValue={this.props.patientJSONDefault.postTraumaticAmnesia} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "post_traumatic_amnesia_length"
            fieldLabel = "Post Traumatic Amnesia Length"
            defaultValue={this.props.patientJSONDefault.post_traumatic_amnesia_length} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "brainSurgery"
            fieldLabel = "Brain Surgery"
            defaultValue={this.props.patientJSONDefault.brainSurgery} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <DateTextField 
            fieldName = "dateOfSurgery"
            fieldLabel = "Date of Surgery"
            defaultValue={this.props.patientJSONDefault.dateOfSurgery} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "current_work_school"
            fieldLabel = "Are you currently working or in school?"
            defaultValue={this.props.patientJSONDefault.current_work_school} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "ability_change_work_school"
            fieldLabel = "Have your abilities changed?"
            defaultValue={this.props.patientJSONDefault.ability_change_work_school} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "explain_work_school_change"
            fieldLabel = "Explain"
            defaultValue={this.props.patientJSONDefault.explain_work_school_change} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "state_of_condition"
            fieldLabel = "State of Condition"
            defaultValue={this.props.patientJSONDefault.state_of_condition} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "law_firm"
            fieldLabel = "Law Firm"
            defaultValue={this.props.patientJSONDefault.law_firm} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "attorney_fax"
            fieldLabel = "Attorney Fax Number"
            defaultValue={this.props.patientJSONDefault.attorney_fax} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "doctor_first_name"
            fieldLabel = "Doctor First Name"
            defaultValue={this.props.patientJSONDefault.doctor_first_name} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "doctor_last_name"
            fieldLabel = "Doctor Last Name"
            defaultValue={this.props.patientJSONDefault.doctor_last_name} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "doctor_phone"
            fieldLabel = "Doctor Phone"
            defaultValue={this.props.patientJSONDefault.doctor_phone} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODX_Osteoporosis"
            fieldLabel = "DODX Osteoporosis"
            defaultValue={this.props.patientJSONDefault.DODX_Osteoporosis} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "Institutionalized_in_the_last_5_years"
            fieldLabel = "Institutionalized in the last 5 years?"
            defaultValue={this.props.patientJSONDefault.Institutionalized_in_the_last_5_years} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "ExplainInstitutionalized"
            fieldLabel = "Explain"
            defaultValue={this.props.patientJSONDefault.ExplainInstitutionalized} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Medical_Marijuana"
            fieldLabel = "Habitual Medical Marijuana"
            defaultValue={this.props.patientJSONDefault.habitual_Medical_Marijuana} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Coffee"
            fieldLabel = "Habitual Coffee"
            defaultValue={this.props.patientJSONDefault.habitual_Coffee} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Energy_Drinks"
            fieldLabel = "Habitual Energy Drinks"
            defaultValue={this.props.patientJSONDefault.habitual_Energy_Drinks} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Tobacco"
            fieldLabel = "Habitual Tobacco"
            defaultValue={this.props.patientJSONDefault.habitual_Tobacco} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Drugs"
            fieldLabel = "Habitual Drugs"
            defaultValue={this.props.patientJSONDefault.habitual_Drugs} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Exercise"
            fieldLabel = "Habitual Exercise"
            defaultValue={this.props.patientJSONDefault.habitual_Exercise} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Sleep"
            fieldLabel = "Habitual Sleep"
            defaultValue={this.props.patientJSONDefault.habitual_Sleep} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Soft_Drinks"
            fieldLabel = "Habitual Soft Drinks"
            defaultValue={this.props.patientJSONDefault.habitual_Soft_Drinks} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Salty_Foods"
            fieldLabel = "Habitual Salty Foods"
            defaultValue={this.props.patientJSONDefault.habitual_Salty_Foods} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Water"
            fieldLabel = "Habitual Water"
            defaultValue={this.props.patientJSONDefault.habitual_Water} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "habitual_Sugar"
            fieldLabel = "Habitual Sugar"
            defaultValue={this.props.patientJSONDefault.habitual_Sugar} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "family_history_"
            fieldLabel = "Family History"
            defaultValue={this.props.patientJSONDefault.family_history_} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "Type_of_Corrective_Lenses"
            fieldLabel = "Type of Corrective Lenses"
            defaultValue={this.props.patientJSONDefault.Type_of_Corrective_Lenses} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "Research_consent_"
            fieldLabel = "Reserach consent"
            defaultValue={this.props.patientJSONDefault.Research_consent_} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "How_long_ago_was_your_injury_"
            fieldLabel = "How long ago was your injury?"
            defaultValue={this.props.patientJSONDefault.How_long_ago_was_your_injury_} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "Hospitalization_in_the_Last_5_years"
            fieldLabel = "Hospitalization in the last 5 years?"
            defaultValue={this.props.patientJSONDefault.Hospitalization_in_the_Last_5_years} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

        </div>
	  );
	}
}

export default withStyles(useStyles)(MedicalHistory);




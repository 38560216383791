

//let colors = {
//	backgroundColor: "#33393b",
//	searchRowColor: "#33393b",
//	headRowColor: "#252a2b",
//	//rowColor1: "#08a4bd",
//	//rowColor2: "#8bb174",
//	//borderColor: "#858786",
//	textColorPlain: "#ffffff",
//	textColorBold: "#fbaf00",
//	buttonColorPlain: "#fcfcfc",
//	buttonColorBold: "#fbaf00",
//};

let colors = {
	backgroundColor: "#001435",
	searchRowColor: "#001435",
	headRowColor: "#000116",
	//rowColor1: "#08a4bd",
	//rowColor2: "#8bb174",
	//borderColor: "#0c6ab1",
	textColorPlain: "#ffffff",  //DEEEFB
	textColorBold: "#ffffff",
	buttonColorPlain: "#626575",
	buttonColorBold: "#32568A",
};

export default colors;

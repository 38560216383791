import React, { Component } from 'react';
//import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import colors from './../../../ColorScheme/Colors';


const useStyles = theme => ({

  radioGroup: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },
  textfieldAccordion: {
    color: colors.textColorBold,
  },
  textfield: {
    color: colors.textColorPlain,
  },
});

class RadioGroupVision extends Component {
	constructor(props) {
		super(props);
		this.state = {value: this.props.defaultValue};
	}

    handleChange = event =>{
        this.setState({ value: event.target.value });
		this.props.handleChange(event);
    }

	render() {
		if (this.props.editable === true) {
		  const { classes } = this.props;

		  return (
			<FormControl className = {classes.radioGroup} component="fieldset">
			  <FormLabel className = {classes.radioGroup} component="legend"> {this.props.fieldLabel} </FormLabel>
			  <RadioGroup 
		        className = {classes.radioGroup}
		        aria-label={this.props.fieldName} 
		        name={this.props.fieldName} 
		        value={this.state.value} 
		        onChange={this.handleChange}
		      >
				<div>
					<FormControlLabel value="never(0)" control={<Radio />} label="Never (0)" />
		            <FormControlLabel value="seldom(1)" control={<Radio />} label="Seldom (1)" />
					<FormControlLabel value="occasionally(2)" control={<Radio />} label="Occasionally (2)" />
					<FormControlLabel value="frequently(3)" control={<Radio />} label="Frequently (3)" />
					<FormControlLabel value="always(4)" control={<Radio />} label="Always (4)" />
					<FormControlLabel value="notAnswered" control={<Radio />} label="Not Answered" />
				</div>
			  </RadioGroup>
			</FormControl>
		  );
		} else {
		  const { classes } = this.props;

		  return (
			<FormControl className = {classes.radioGroup} component="fieldset">
			  <FormLabel className = {classes.radioGroup} component="legend"> {this.props.fieldLabel} </FormLabel>
			  <RadioGroup 
		        className = {classes.radioGroup}
		        aria-label={this.props.fieldName} 
		        name={this.props.fieldName} 
		        value={this.state.value} 
		      >
				<div>
					<FormControlLabel value="never(0)" control={<Radio />} label="Never (0)" />
		            <FormControlLabel value="seldom(1)" control={<Radio />} label="Seldom (1)" />
					<FormControlLabel value="occasionally(2)" control={<Radio />} label="Occasionally (2)" />
					<FormControlLabel value="frequently(3)" control={<Radio />} label="Frequently (3)" />
					<FormControlLabel value="always(4)" control={<Radio />} label="Always (4)" />
					<FormControlLabel value="notAnswered" control={<Radio />} label="Not Answered" />
				</div>
			  </RadioGroup>
			</FormControl>
		  );
		}
	}
}

export default withStyles(useStyles)(RadioGroupVision);



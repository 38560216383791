import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import DisplayOnlyTextField from './CustomFields/DisplayOnlyTextField';
import RadioGroupYesNo from './CustomFields/RadioGroupYesNo';
import RadioGroupSymptoms from './CustomFields/RadioGroupSymptoms';
import DateTextField from './CustomFields/DateTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

const physicalList = ['HeadachePostThreeMonth',
                      'NauseaPostThreeMonth',
                      'VomitingPostThreeMonth',
                      'BalanceProblemsPostThreeMonth',
                      'DizzinessPostThreeMonth',
                      'VisualProblemsPostThreeMonth',
                      'FatiguePostThreeMonth',
                      'SensitivityToLightPostThreeMonth',
                      'SensitivityToNoisePostThreeMonth',
                      'NumbnessTinglingPostThreeMonth',
                   ]

const thinkingList = ['MentalFogPostThreeMonth',
                      'FeelingSlowedDownPostThreeMonth',
                      'DifficultyConcentratingPostThreeMonth',
                      'DifficultyRememberingPostThreeMonth',
					 ]

const sleepList = ['DrowsinessPostThreeMonth',
                   'SleepingLessThanUsualPostThreeMonth',
                   'SleepingMoreThanUsualPostThreeMonth',
                   'TroubleFallingAsleepPostThreeMonth',
			      ]

const emotionalList = ['IrritabilityPostThreeMonth',
                       'SadnessPostThreeMonth',
                       'NervousnessPostThreeMonth',
                       'FeelingMoreEmotionalPostThreeMonth',
			           ]

var totalList = [...physicalList];
totalList.push(...thinkingList);
totalList.push(...sleepList);
totalList.push(...emotionalList);




class PCSSPostThreeMonth extends Component {
	constructor(props) {
		super(props);

		this.handleChangeNone = this.handleChangeNone.bind(this);
	}

	computeNumberOfSymptoms(list, event) {
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if ((value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else {
				total += 1
			}
		}

		return total
	}

	computePercentOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}

			if ((value !== '0') && (value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else if (value === '0') {
				total += 1
			}
			else {
				totalYes += 1
				total += 1
			}
		}

		const percent = totalYes / Math.max(total, 1) * 100

		return percent
	}

	computeAverageOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if (value === '0') {
				total += 1
			}
			else if (value === '1') {
				totalYes += 1
				total += 1
			}
			else if (value === '2') {
				totalYes += 2
				total += 1
			}
			else if (value === '3') {
				totalYes += 3
				total += 1
			}
			else if (value === '4') {
				totalYes += 4
				total += 1
			}
			else if (value === '5') {
				totalYes += 5
				total += 1
			}
			else if (value === '6') {
				totalYes += 6
				total += 1
			}
		}

		const average = totalYes / Math.max(total, 1)

		return average
	}

    handleChangeNone = event =>{
		// A placeholder to avoid an error.  Does nothing
    }

    handleChange = event =>{
		this.props.handleChange(event);
    }

    handleChangePhysical = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(physicalList, event)
		const percent = this.computePercentOfSymptoms(physicalList, event)
		const average = this.computeAverageOfSymptoms(physicalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfPhysicalSymptomsEndorsedPostThreeMonth', 
            'PercentOfPhysicalSymptomsEndorsedPostThreeMonth', 
            'AverageScoreOfPhysicalSymptomsPostThreeMonth',
            'NumberOfTotalSymptomsEndorsedPostThreeMonth',
            'PercentOfTotalSymptomsEndorsedPostThreeMonth', 
            'AverageScoreOfTotalSymptomsPostThreeMonth',
            'PCSSIndexScorePostThreeMonth'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeThinking = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(thinkingList, event)
		const percent = this.computePercentOfSymptoms(thinkingList, event)
		const average = this.computeAverageOfSymptoms(thinkingList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfThinkingSymptomsEndorsedPostThreeMonth', 
            'PercentOfThinkingSymptomsEndorsedPostThreeMonth', 
            'AverageScoreOfThinkingSymptomsPostThreeMonth',
            'NumberOfTotalSymptomsEndorsedPostThreeMonth',
            'PercentOfTotalSymptomsEndorsedPostThreeMonth', 
            'AverageScoreOfTotalSymptomsPostThreeMonth',
            'PCSSIndexScorePostThreeMonth'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeSleep = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(sleepList, event)
		const percent = this.computePercentOfSymptoms(sleepList, event)
		const average = this.computeAverageOfSymptoms(sleepList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfSleepSymptomsEndorsedPostThreeMonth', 
            'PercentOfSleepSymptomsEndorsedPostThreeMonth', 
            'AverageScoreOfSleepSymptomsPostThreeMonth',
            'NumberOfTotalSymptomsEndorsedPostThreeMonth',
            'PercentOfTotalSymptomsEndorsedPostThreeMonth', 
            'AverageScoreOfTotalSymptomsPostThreeMonth',
            'PCSSIndexScorePostThreeMonth'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeEmotional = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(emotionalList, event)
		const percent = this.computePercentOfSymptoms(emotionalList, event)
		const average = this.computeAverageOfSymptoms(emotionalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfEmotionalSymptomsEndorsedPostThreeMonth', 
            'PercentOfEmotionalSymptomsEndorsedPostThreeMonth', 
            'AverageScoreOfEmotionalSymptomsPostThreeMonth',
            'NumberOfTotalSymptomsEndorsedPostThreeMonth',
            'PercentOfTotalSymptomsEndorsedPostThreeMonth', 
            'AverageScoreOfTotalSymptomsPostThreeMonth',
            'PCSSIndexScorePostThreeMonth'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

	getNewest(eventName) {
		var value = 0
		if (this.props.patientJSON === '') {
			if (this.props.patientJSONDefault === '') {
			}
			else if (eventName in this.props.patientJSONDefault) {
				value = this.props.patientJSONDefault[eventName];
			}
		}
		else {
			if (eventName in this.props.patientJSON) {
				value = this.props.patientJSON[eventName];
			}
			else {
				if (this.props.patientJSONDefault === '') {
				}
				else if (eventName in this.props.patientJSONDefault) {
					value = this.props.patientJSONDefault[eventName];
				}
			}		
		}

		return value
	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <DateTextField 
            fieldName = "DateThreeMonthFollowUpSurvey"
            fieldLabel = "Date of 3 Month Follow Up Survey"
            defaultValue={this.props.patientJSONDefault.DateThreeMonthFollowUpSurvey} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "HeadachePostThreeMonth"
            fieldLabel = "Headache - Post Three Month"
            defaultValue={this.props.patientJSONDefault.HeadachePostThreeMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NauseaPostThreeMonth"
            fieldLabel = "Nausea - Post Three Month"
            defaultValue={this.props.patientJSONDefault.NauseaPostThreeMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VomitingPostThreeMonth"
            fieldLabel = "Vomiting - Post Three Month"
            defaultValue={this.props.patientJSONDefault.VomitingPostThreeMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "BalanceProblemsPostThreeMonth"
            fieldLabel = "Balance Problems - Post Three Month"
            defaultValue={this.props.patientJSONDefault.BalanceProblemsPostThreeMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DizzinessPostThreeMonth"
            fieldLabel = "Dizziness - Post Three Month"
            defaultValue={this.props.patientJSONDefault.DizzinessPostThreeMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VisualProblemsPostThreeMonth"
            fieldLabel = "Visual Problems - Post Three Month"
            defaultValue={this.props.patientJSONDefault.VisualProblemsPostThreeMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FatiguePostThreeMonth"
            fieldLabel = "Fatigue - Post Three Month"
            defaultValue={this.props.patientJSONDefault.FatiguePostThreeMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToLightPostThreeMonth"
            fieldLabel = "Sensitivity to Light - Post Three Month"
            defaultValue={this.props.patientJSONDefault.SensitivityToLightPostThreeMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToNoisePostThreeMonth"
            fieldLabel = "Sensitivity to Noise - Post Three Month"
            defaultValue={this.props.patientJSONDefault.SensitivityToNoisePostThreeMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NumbnessTinglingPostThreeMonth"
            fieldLabel = "Numbness or Tingling - Post Three Month"
            defaultValue={this.props.patientJSONDefault.NumbnessTinglingPostThreeMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "MentalFogPostThreeMonth"
            fieldLabel = "Mental Fog - Post Three Month"
            defaultValue={this.props.patientJSONDefault.MentalFogPostThreeMonth} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingSlowedDownPostThreeMonth"
            fieldLabel = "Feeling Slowed Down - Post Three Month"
            defaultValue={this.props.patientJSONDefault.FeelingSlowedDownPostThreeMonth} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyConcentratingPostThreeMonth"
            fieldLabel = "Difficulty Concentrating - Post Three Month"
            defaultValue={this.props.patientJSONDefault.DifficultyConcentratingPostThreeMonth} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyRememberingPostThreeMonth"
            fieldLabel = "Difficulty Remembering - Post Three Month"
            defaultValue={this.props.patientJSONDefault.DifficultyRememberingPostThreeMonth} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DrowsinessPostThreeMonth"
            fieldLabel = "Drowsiness - Post Three Month"
            defaultValue={this.props.patientJSONDefault.DrowsinessPostThreeMonth} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingLessThanUsualPostThreeMonth"
            fieldLabel = "Sleeping Less Than Usual - Post Three Month"
            defaultValue={this.props.patientJSONDefault.SleepingLessThanUsualPostThreeMonth} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingMoreThanUsualPostThreeMonth"
            fieldLabel = "Sleeping More Than Usual - Post Three Month"
            defaultValue={this.props.patientJSONDefault.SleepingMoreThanUsualPostThreeMonth} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "TroubleFallingAsleepPostThreeMonth"
            fieldLabel = "Trouble Falling Asleep - Post Three Month"
            defaultValue={this.props.patientJSONDefault.TroubleFallingAsleepPostThreeMonth} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "IrritabilityPostThreeMonth"
            fieldLabel = "Irritability - Post Three Month"
            defaultValue={this.props.patientJSONDefault.IrritabilityPostThreeMonth} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SadnessPostThreeMonth"
            fieldLabel = "Sadness - Post Three Month"
            defaultValue={this.props.patientJSONDefault.SadnessPostThreeMonth} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NervousnessPostThreeMonth"
            fieldLabel = "Nervousness - Post Three Month"
            defaultValue={this.props.patientJSONDefault.NervousnessPostThreeMonth} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingMoreEmotionalPostThreeMonth"
            fieldLabel = "Feeling More Emotional - Post Three Month"
            defaultValue={this.props.patientJSONDefault.FeelingMoreEmotionalPostThreeMonth} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "PainOtherThanHeadachePostThreeMonth"
            fieldLabel = "Pain Other Than Headache - Post Three Month"
            defaultValue={this.props.patientJSONDefault.PainOtherThanHeadachePostThreeMonth} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithPhysicalActivityPostThreeMonth"
	        fieldLabel = "Worsen with Physical Activity - Post Three Month"
	        defaultValue={this.props.patientJSONDefault.WorsenWithPhysicalActivityPostThreeMonth}
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithCognitiveActivityPostThreeMonth"
	        fieldLabel = "Worsen with Cognitive Activity - Post Three Month"
	        defaultValue={this.props.patientJSONDefault.WorsenWithCognitiveActivityPostThreeMonth} 
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <DisplayOnlyTextField 
	        fieldName = "PCSSIndexScorePostThreeMonth"
	        fieldLabel = "PCSS Index Score - Post Three Month (Calculated)"
	        value = {this.getNewest("PCSSIndexScorePostThreeMonth").toFixed(2)}
	        handleChange={this.handleChange}
	      />

        </div>
	  );
	}
}

export default withStyles(useStyles)(PCSSPostThreeMonth);



//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfPhysicalSymptomsEndorsedPostThreeMonth"
//	        fieldLabel = "Number of Physical Symptoms Endorsed - Post Three Month (Calculated)"
//	        value = {this.getNewest("NumberOfPhysicalSymptomsEndorsedPostThreeMonth")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfPhysicalSymptomsEndorsedPostThreeMonth"
//	        fieldLabel = "Percent of Physical Symptoms Endorsed - Post Three Month (Calculated)"
//	        value = {this.getNewest("PercentOfPhysicalSymptomsEndorsedPostThreeMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfPhysicalSymptomsPostThreeMonth"
//	        fieldLabel = "Average Score of Physical Symptoms - Post Three Month (Calculated)"
//	        value = {this.getNewest("AverageScoreOfPhysicalSymptomsPostThreeMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfThinkingSymptomsEndorsedPostThreeMonth"
//	        fieldLabel = "Number of Thinking Symptoms Endorsed - Post Three Month (Calculated)"
//	        value = {this.getNewest("NumberOfThinkingSymptomsEndorsedPostThreeMonth")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfThinkingSymptomsEndorsedPostThreeMonth"
//	        fieldLabel = "Percent of Thinking Symptoms Endorsed - Post Three Month (Calculated)"
//	        value = {this.getNewest("PercentOfThinkingSymptomsEndorsedPostThreeMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfThinkingSymptomsPostThreeMonth"
//	        fieldLabel = "Average Score of Thinking Symptoms - Post Three Month (Calculated)"
//	        value = {this.getNewest("AverageScoreOfThinkingSymptomsPostThreeMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfSleepSymptomsEndorsedPostThreeMonth"
//	        fieldLabel = "Number of Sleep Symptoms Endorsed - Post Three Month (Calculated)"
//	        value = {this.getNewest("NumberOfSleepSymptomsEndorsedPostThreeMonth")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfSleepSymptomsEndorsedPostThreeMonth"
//	        fieldLabel = "Percent of Sleep Symptoms Endorsed - Post Three Month (Calculated)"
//	        value = {this.getNewest("PercentOfSleepSymptomsEndorsedPostThreeMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfSleepSymptomsPostThreeMonth"
//	        fieldLabel = "Average Score of Sleep Symptoms - Post Three Month (Calculated)"
//	        value = {this.getNewest("AverageScoreOfSleepSymptomsPostThreeMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfEmotionalSymptomsEndorsedPostThreeMonth"
//	        fieldLabel = "Number of Emotional Symptoms Endorsed - Post Three Month (Calculated)"
//	        value = {this.getNewest("NumberOfEmotionalSymptomsEndorsedPostThreeMonth")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfEmotionalSymptomsEndorsedPostThreeMonth"
//	        fieldLabel = "Percent of Emotional Symptoms Endorsed - Post Three Month (Calculated)"
//	        value = {this.getNewest("PercentOfEmotionalSymptomsEndorsedPostThreeMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfEmotionalSymptomsPostThreeMonth"
//	        fieldLabel = "Average Score of Emotional Symptoms - Post Three Month (Calculated)"
//	        value = {this.getNewest("AverageScoreOfEmotionalSymptomsPostThreeMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfTotalSymptomsEndorsedPostThreeMonth"
//	        fieldLabel = "Number of Total Symptoms Endorsed - Post Three Month (Calculated)"
//	        value = {this.getNewest("NumberOfTotalSymptomsEndorsedPostThreeMonth")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfTotalSymptomsEndorsedPostThreeMonth"
//	        fieldLabel = "Percent of Total Symptoms Endorsed - Post Three Month (Calculated)"
//	        value = {this.getNewest("PercentOfTotalSymptomsEndorsedPostThreeMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfTotalSymptomsPostThreeMonth"
//	        fieldLabel = "Average Score of All Symptoms - Post Three Month (Calculated)"
//	        value = {this.getNewest("AverageScoreOfTotalSymptomsPostThreeMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />






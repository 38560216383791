import React, { Component } from 'react';
//import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button';

import Copyright from './../BoilerPlate/Copyright';
import colors from './../ColorScheme/Colors';


const useStyles = theme => ({
  container: {
    background: colors.backgroundColor,
	flexGrow: 1,
//    minheight : '100vh',
  },
  paper: {
    paddingTop: theme.spacing(4),
    //display: 'flex',
    width: '100%',
    //flexDirection: 'column',
    alignItems: 'center',
    background: colors.backgroundColor,
  },
  textfield: {
    color: colors.textColorPlain,
  },
  done: {
	background: colors.buttonColorPlain,
    color: colors.backgroundColor,
  },
});

//function deletePatientData(patientJSONID) {
//	var patientsApiUrl = '/api/patients';
//    const patientData = {
//        method: 'DELETE',
//        headers: { 'Content-Type': 'application/json' },
//    };

//	var params = {searchID: patientJSONID, 
//                 }

//	let query = Object.keys(params)
//	             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
//	             .join('&');

//	patientsApiUrl = patientsApiUrl + '?' + query

//	console.log(patientsApiUrl, 'patientsApiUrl in delete request');

//    fetch(patientsApiUrl, patientData)
//        .then(response => response.json())
//}


class UploadJotformPatients extends Component {

	constructor(props) {
		super(props);

		this.state = {
			uploadingStatus: 'incomplete',  // options are 'incomplete', 'intakeUp', 'pcsConsultUp', 'covidConsultUp', and 'error'
			numberOfIntakePatientsUploaded: 0,
			numberOfPCSConsultPatientsUpdated: 0,
			numberOfPCSConsultPatientsThatAreNew: 0, 
			numberOfCovidConsultPatientsUpdated: 0,
			numberOfCovidConsultPatientsThatAreNew: 0, 
		};
	}

	handleDone = event => {
		this.props.setPage("Home")
	}

	updateUploadState(response, whichFormUploaded) {
		if (((response.numberUploaded) || (response.numberUploaded === 0)) && whichFormUploaded === 'intake') {
			this.setState({ uploadingStatus: 'intakeUp',  numberOfIntakePatientsUploaded: response.numberUploaded })
		} else if (((response.numberUploaded) || (response.numberUploaded === 0)) && whichFormUploaded === 'pcsConsult') {
			this.setState({ uploadingStatus: 'pcsConsultUp',  
			                numberOfPCSConsultPatientsUpdated: response.numberUploaded - response.numberNew,
			                numberOfPCSConsultPatientsThatAreNew: response.numberNew,
			                })
		} else if (((response.numberUploaded) || (response.numberUploaded === 0)) && whichFormUploaded === 'covidConsult') {
			this.setState({ uploadingStatus: 'covidConsultUp',  
			                numberOfCovidConsultPatientsUpdated: response.numberUploaded - response.numberNew,
			                numberOfCovidConsultPatientsThatAreNew: response.numberNew,
			                })
		} else {
			this.setState({ uploadingStatus: 'error'});
		}
	}

  	async uploadNewJotformPatientIntakes() {
  		// Upload the intakes
		var fetchJotformApiUrlForIntake = '/api/loadNewPatientFromJotformIntakeForm';
		const intakeData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		};
		
		await fetch(fetchJotformApiUrlForIntake, intakeData).then(response => response.json())
            .then(response => (this.updateUploadState(response, 'intake')))
	}
	
	async uploadNewJotformPatientPCSConsults() { 
        // Upload the PCS Consults
		var fetchJotformApiUrlForPCSPreConsult = '/api/loadNewPatientFromJotformPCSConsultForm';
		const pcsPreConsultData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		};
		
		await fetch(fetchJotformApiUrlForPCSPreConsult, pcsPreConsultData).then(response => response.json())
            .then(response => (this.updateUploadState(response, 'pcsConsult')))      
	}
	
	async uploadNewJotformPatientCovidConsults() {   
        // Upload the Covid Consults
		var fetchJotformApiUrlForCovidConsult = '/api/loadNewPatientFromJotformCovidConsultForm';
		const covidConsultData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		};
		
		await fetch(fetchJotformApiUrlForCovidConsult, covidConsultData).then(response => response.json())
            .then(response => (this.updateUploadState(response, 'covidConsult')))     
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
		
		// Include the if statements so that if it comes back as an error, we stop running.
		if (this.state.uploadingStatus === 'incomplete') {
			await this.uploadNewJotformPatientIntakes()
		}
		
		if (this.state.uploadingStatus === 'intakeUp') {
			await this.uploadNewJotformPatientPCSConsults()
		}
		
		if (this.state.uploadingStatus === 'pcsConsultUp') {
			await this.uploadNewJotformPatientCovidConsults()
		}
	}

	render() {
      const { classes } = this.props;

	  if (this.state.uploadingStatus === 'incomplete') {
		  return (
			<Container component="main" className={classes.container}>
				<Container maxWidth="md" className={classes.container}>
				  <CssBaseline />
				  <div className={classes.paper}>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Syncing with patient data from Jotform. 
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading Intake Forms... processing, please wait...
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading PCS Consult Forms... processing, please wait...
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading Covid Consult Forms... processing, please wait...
					</Typography>
					<Button
						fullWidth
						variant="contained"
						color="default"
						className={classes.done}
						disabled = {true}
					>
						Back to Home
					</Button>
		          </div>
				  <Box mt={80}>
					<Copyright />
				  </Box>
				</Container>
			</Container>
		  );

	  } else if (this.state.uploadingStatus === 'intakeUp') {
		  return (
			<Container component="main" className={classes.container}>
				<Container maxWidth="md" className={classes.container}>
				  <CssBaseline />
				  <div className={classes.paper}>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Syncing with patient data from Jotform.
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading Intake Forms... {this.state.numberOfIntakePatientsUploaded} patients uploaded.
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading PCS Consult Forms... processing, please wait...
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading Covid Consult Forms... processing, please wait...
					</Typography>
					<Button
						fullWidth
						variant="contained"
						color="default"
						className={classes.done}
						disabled = {true}
					>
						Back to Home
					</Button>
		          </div>
				  <Box mt={80}>
					<Copyright />
				  </Box>
				</Container>
			</Container>
		  );

	  } else if (this.state.uploadingStatus === 'pcsConsultUp') {
		  return (
			<Container component="main" className={classes.container}>
				<Container maxWidth="md" className={classes.container}>
				  <CssBaseline />
				  <div className={classes.paper}>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Syncing with patient data from Jotform.
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading Intake Forms... {this.state.numberOfIntakePatientsUploaded} patients uploaded.
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading PCS Consult Forms... {this.state.numberOfPCSConsultPatientsUpdated} patients updated. {this.state.numberOfPCSConsultPatientsThatAreNew} new patients created.
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading Covid Consult Forms... processing, please wait...
					</Typography>
					<Button
						fullWidth
						variant="contained"
						color="default"
						className={classes.done}
						disabled = {true}
					>
						Back to Home
					</Button>
		          </div>
				  <Box mt={80}>
					<Copyright />
				  </Box>
				</Container>
			</Container>
		  );

	  } else if (this.state.uploadingStatus === 'covidConsultUp') {
		  return (
			<Container component="main" className={classes.container}>
				<Container maxWidth="md" className={classes.container}>
				  <CssBaseline />
				  <div className={classes.paper}>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Syncing with patient data from Jotform.
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading Intake Forms... {this.state.numberOfIntakePatientsUploaded} patients uploaded.
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading PCS Consult Forms... {this.state.numberOfPCSConsultPatientsUpdated} patients updated. {this.state.numberOfPCSConsultPatientsThatAreNew} new patients created.
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Uploading Covid Consult Forms... {this.state.numberOfCovidConsultPatientsUpdated} patients updated. {this.state.numberOfCovidConsultPatientsThatAreNew} new patients created.
					</Typography>
					<Button
						fullWidth
						variant="contained"
						color="default"
						className={classes.done}
						onClick={this.handleDone}
					>
						Back to Home
					</Button>
		          </div>
				  <Box mt={80}>
					<Copyright />
				  </Box>
				</Container>
			</Container>
		  );
		  		  		  
      } else {
		  return (
			<Container component="main" className={classes.container}>
				<Container maxWidth="md" className={classes.container}>
				  <CssBaseline />
				  <div className={classes.paper}>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Syncing with patient data from Jotform.  
					</Typography>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Error uploading.  Please try again, check if Jotform is down, or contact Heath@cognitivefxusa.com
					</Typography>
					<Button
						fullWidth
						variant="contained"
						color="default"
						className={classes.done}
						onClick={this.handleDone}
					>
						Back to Home
					</Button>
		          </div>
				  <Box mt={80}>
					<Copyright />
				  </Box>
				</Container>
			</Container>
		  );
      }
	}
}

export default withStyles(useStyles)(UploadJotformPatients);





import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles"

import Copyright from './../BoilerPlate/Copyright';
import PatientBoxes from './PatientBoxes/PatientBoxes';
import colors from './../ColorScheme/Colors';


const useStyles = theme => ({
  container: {
    background: colors.backgroundColor,
	flexGrow: 1,
//    minheight : '100vh',
  },
  paper: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: colors.backgroundColor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
//    marginTop: theme.spacing(1),
    background: colors.backgroundColor,
  },
  downloadYellowSheet: {
	background: colors.buttonColorBold,
    color: colors.backgroundColor,
	margin: '1px',
  },
  downloadPatientForms: {
	background: colors.buttonColorBold,
    color: colors.backgroundColor,
	margin: '1px',
  },
  extractCPTCodes: {
	background: colors.buttonColorBold,
    color: colors.backgroundColor,
	margin: '1px',
  },
  submit: {
//    margin: theme.spacing(3, 0, 2),
	margin: '1px',
  },
  cancel: {
	background: colors.buttonColorPlain,
    color: colors.backgroundColor,
	margin: '1px',
  },
  textfield: {
    color: colors.textColorPlain,
  },
});


class ViewPatient extends Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleYellowSheetDownload = this.handleYellowSheetDownload.bind(this)
		this.handlePatientFormsDownload = this.handlePatientFormsDownload.bind(this)
		this.handleCPTCodeExtraction = this.handleCPTCodeExtraction.bind(this)
		this.state = { patientJSON:'',
                       patientJSONDefault:'',
			           isLoaded: false,
			           //cptCodeExtracting: false,
        };
	}

    handleChange = event =>{
		// A placeholder to avoid an error.  Does nothing
    }

	handlePatientJSONUpdate = (keys, values) => {
	}

	handleYellowSheetDownload() {
	 	const patientsApiUrl = '/api/downloadYellowSheet';
		const patientData = {
		    method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(this.state.patientJSONDefault),
		};

		fetch(patientsApiUrl, patientData)
			.then(response => response.blob())
			.then(blob => URL.createObjectURL(blob))
			.then(uril => {
				var link = document.createElement("a");
				link.href = uril;
				link.download = `YellowSheet__${this.state.patientJSONDefault.firstName}_${this.state.patientJSONDefault.lastName}.docx`;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
	}
	
	handlePatientFormsDownload() {
	 	const patientsApiUrl = '/api/downloadNewPatientForms';
		const patientData = {
		    method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(this.state.patientJSONDefault),
		};

		fetch(patientsApiUrl, patientData)
			.then(response => response.blob())
			.then(blob => URL.createObjectURL(blob))
			.then(uril => {
				var link = document.createElement("a");
				link.href = uril;
				link.download = `${this.state.patientJSONDefault.lastName}_${this.state.patientJSONDefault.firstName}_NewPatientForms.docx`;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
	}

	handleCPTCodeExtraction() {
	 	const patientsApiUrl = '/api/extractCPTCodes';
		const patientData = {
		    method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(this.state.patientJSONDefault),
		};

//		this.setState({ cptCodeExtracting: true })
		
		fetch(patientsApiUrl, patientData)
			.then(response => response.blob())
			.then(blob => URL.createObjectURL(blob))
			.then(uril => {
				var link = document.createElement("a");
				link.href = uril;
				link.download = `CPTCodes__${this.state.patientJSONDefault.firstName}_${this.state.patientJSONDefault.lastName}.csv`;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
//			.then(this.setState({ cptCodeExtracting: false }));
			
//		fetch(patientsApiUrl, patientData)
//			.then(response => response.blob())
//			.then(blob => URL.createObjectURL(blob))
//			.then(uril => {
//				var link = document.createElement("a");
//				link.href = uril;
//				link.download = `CPTCodes__${this.state.patientJSONDefault.firstName}_${this.state.patientJSONDefault.lastName}.csv`;
//				document.body.appendChild(link);
//				link.click();
//				document.body.removeChild(link);
//			});
			
	}
	
	handleCancel = event => {
//		console.log('Done Viewing');
		this.props.setPage("Home")
	}

	componentDidMount() {
		var patientsApiUrl = '/api/patients';

		var params = {searchID: this.props.patientJSONID, 
                     }

		let query = Object.keys(params)
		             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		             .join('&');

		patientsApiUrl = patientsApiUrl + '?' + query

		fetch(patientsApiUrl)
		  .then(response => response.json())
          .then(data => this.setState({ patientJSON: '', 
                                        patientJSONDefault: data[0], 
                                        isLoaded: true }, () => {
//		  console.log(this.state.patientJSON, 'patientsJSON');
//		  console.log(this.state.isLoaded, 'isLoaded');
		})); 
	}

	render() {
        const { classes } = this.props;
		//const cptCodeExtracting = this.state.cptCodeExtracting;
		
		if (!this.state.isLoaded) {
		  return <div>Loading ... </div>;
		} else {
		  return (
			<Container component="main" className={classes.container}>
				<Container maxWidth="md" className={classes.container}>
				  <CssBaseline />
				  <div className={classes.paper}>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Viewing Patient Data
					</Typography>
					<form onSubmit = {this.handleSubmit} className={classes.form} noValidate>
					  <div>
		                  <PatientBoxes 
                            patientJSON={this.state.patientJSON} 
                            patientJSONDefault={this.state.patientJSONDefault} 
                            handleChange={this.handleChange}
							handlePatientJSONUpdate={this.handlePatientJSONUpdate}
							editable={false}
                          />

						  <Button
							fullWidth
							variant="contained"
							color="default"
							className={classes.downloadYellowSheet}
							onClick={this.handleYellowSheetDownload}
						  >
							Download Yellow Sheet Template
						  </Button>

						  <Button
							fullWidth
							variant="contained"
							color="default"
							className={classes.downloadPatientForms}
							onClick={this.handlePatientFormsDownload}
						  >
							Download Patient Forms (Run Time ~ 10 seconds)
						  </Button>
						  
						  <Button
							fullWidth
							variant="contained"
							color="default"
							className={classes.extractCPTCodes}
							onClick={this.handleCPTCodeExtraction}
						  >
							Extract CPT Codes (Run Time ~ 3 minutes)
						  </Button>
      					  
						  <Button
							fullWidth
							variant="contained"
							color="default"
							className={classes.cancel}
							onClick={this.handleCancel}
						  >
							Done
						  </Button>

					  </div>
					</form>
				  </div>
				  <Box mt={8}>
					<Copyright />
				  </Box>
				</Container>
			</Container>
		  );
        }
	}
}

export default withStyles(useStyles)(ViewPatient);





//						  {cptCodeExtracting ? (
//						    <Button
//							  fullWidth
//							  variant="contained"
//							  color="default"
//							  className={classes.extractCPTCodes}
//							  onClick={this.handleCPTCodeExtraction}
//						    >
//							  Extract CPT Codes.  Downloading, please wait... (Run Time ~ 2 minutes)
//							</Button>
//						  ) : (
//						    <Button
//							  fullWidth
//							  variant="contained"
//							  color="default"
//							  className={classes.extractCPTCodes}
//							  onClick={this.handleCPTCodeExtraction}
//						    >
//							  Extract CPT Codes.
//							</Button>
//						  )}


import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import StandardTextField from './CustomFields/StandardTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

class FNCIOne extends Component {
//	constructor(props) {
//		super(props);
//	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <StandardTextField 
            fieldName = "reasonForScan3"
            fieldLabel = "Reason for Scan 3"
            defaultValue={this.props.patientJSONDefault.reasonForScan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "SISScoreScan3"
            fieldLabel = "SIS Score - Scan 3"
            defaultValue={this.props.patientJSONDefault.SISScoreScan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "attentionalScoreScan3"
            fieldLabel = "Attentional Score - Scan3"
            defaultValue={this.props.patientJSONDefault.attentionalScoreScan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "subcorticalScoreScan3"
            fieldLabel = "Subcortical Score - Scan 3"
            defaultValue={this.props.patientJSONDefault.subcorticalScoreScan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "visualScoreScan3"
            fieldLabel = "Visual Score - Scan 3"
            defaultValue={this.props.patientJSONDefault.visualScoreScan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "languageScoreScan3"
            fieldLabel = "Language Score - Scan 3"
            defaultValue={this.props.patientJSONDefault.languageScoreScan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "executiveScoreScan3"
            fieldLabel = "Executive Score - Scan 3"
            defaultValue={this.props.patientJSONDefault.executiveScoreScan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_SMFG_Executive_Scan3"
            fieldLabel = "1MRT - SMFG - Executive - Scan 3"
            defaultValue={this.props.patientJSONDefault.mrt_SMFG_Executive_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_IFG_Executive_Scan3"
            fieldLabel = "1MRT - IFG - Executive - Scan 3"
            defaultValue={this.props.patientJSONDefault.mrt_IFG_Executive_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_PMFC_Attentional_Scan3"
            fieldLabel = "1MRT - PMFC - Attentional - Scan 3"
            defaultValue={this.props.patientJSONDefault.mrt_PMFC_Attentional_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_AI_Attentional_Scan3"
            fieldLabel = "1MRT - AI - Attentional - Scan 3"
            defaultValue={this.props.patientJSONDefault.mrt_AI_Attentional_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_SPL_Scan3"
            fieldLabel = "1MRT - SPL - Scan 3"
            defaultValue={this.props.patientJSONDefault.mrt_SPL_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_FIOC_Visual_Scan3"
            fieldLabel = "1MRT - FIOC - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.mrt_FIOC_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_MOG_Visual_Scan3"
            fieldLabel = "1MRT - MOG - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.mrt_MOG_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_T_Subcortical_Scan3"
            fieldLabel = "1MRT - T - Subcortical - Scan 3"
            defaultValue={this.props.patientJSONDefault.mrt_T_Subcortical_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_BG_Subcortical_Scan3"
            fieldLabel = "1MRT - BG - Subcortical - Scan 3"
            defaultValue={this.props.patientJSONDefault.mrt_BG_Subcortical_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_SMFG_Executive_Scan3"
            fieldLabel = "2TMT - SMGF - Executive - Scan 3"
            defaultValue={this.props.patientJSONDefault.tmt_SMFG_Executive_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_IFG_Executive_Scan3"
            fieldLabel = "2TMT - IFG - Executive - Scan 3"
            defaultValue={this.props.patientJSONDefault.tmt_IFG_Executive_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_PMFC_Attentional_Scan3"
            fieldLabel = "2TMT - PMFC - Attentional - Scan 3"
            defaultValue={this.props.patientJSONDefault.tmt_PMFC_Attentional_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_SPL_Scan3"
            fieldLabel = "2TMT - SPL - Scan 3"
            defaultValue={this.props.patientJSONDefault.tmt_SPL_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_FIOC_Visual_Scan3"
            fieldLabel = "2TMT - FIOC - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.tmt_FIOC_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_MOG_Visual_Scan3"
            fieldLabel = "2TMT - MOG - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.tmt_MOG_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_SC_Subcortical_Scan3"
            fieldLabel = "2TMT - SC - Subcortical - Scan 3"
            defaultValue={this.props.patientJSONDefault.tmt_SC_Subcortical_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_RFG_Visual_Scan3"
            fieldLabel = "3PNT - RFG - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.pnt_RFG_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_LFG_Visual_Scan3"
            fieldLabel = "3PNT - LFG - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.pnt_LFG_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_RIOG_Visual_Scan3"
            fieldLabel = "3PNT - RIOG - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.pnt_RIOG_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_LIOG_Visual_Scan3"
            fieldLabel = "3PNT - LIOG - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.pnt_LIOG_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_RMOG_Visual_Scan3"
            fieldLabel = "3PNT - RMOG - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.pnt_RMOG_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_LMOG_Visual_Scan3"
            fieldLabel = "3PNT - LMOG - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.pnt_LMOG_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_PMFC_Attentional_Scan3"
            fieldLabel = "3PNT - PMFC - Attentional - Scan 3"
            defaultValue={this.props.patientJSONDefault.pnt_PMFC_Attentional_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_RH_Subcortical_Scan3"
            fieldLabel = "4FMT - RH - Subcortical - Scan 3"
            defaultValue={this.props.patientJSONDefault.fmt_RH_Subcortical_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_LF_Subcortical_Scan3"
            fieldLabel = "4FMT - LF - Subcortical - Scan 3"
            defaultValue={this.props.patientJSONDefault.fmt_LF_Subcortical_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_RMFG_Executive_Scan3"
            fieldLabel = "4FMT - RMGF - Executive - Scan 3"
            defaultValue={this.props.patientJSONDefault.fmt_RMFG_Executive_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_LMFG_Executive_Scan3"
            fieldLabel = "4FMT - LMFG - Executive - Scan 3"
            defaultValue={this.props.patientJSONDefault.fmt_LMFG_Executive_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_PMFC_Attentional_Scan3"
            fieldLabel = "4FMT - PMFC - Attentional - Scan 3"
            defaultValue={this.props.patientJSONDefault.fmt_PMFC_Attentional_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_RIOG_Visual_Scan3"
            fieldLabel = "4FMT - RIOG - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.fmt_RIOG_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_LIOC_Visual_Scan3"
            fieldLabel = "4FMT - LIOC - Visual - Scan 3"
            defaultValue={this.props.patientJSONDefault.fmt_LIOC_Visual_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_LIPG_Language_Scan3"
            fieldLabel = "5VMT - LIPG - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vmt_LIPG_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_LPA_Language_Scan3"
            fieldLabel = "5VMT - LPA - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vmt_LPA_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_LIFGPO_Language_Scan3"
            fieldLabel = "5VMT - LIFGPO - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vmt_LIFGPO_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_SMFG_Language_Scan3"
            fieldLabel = "5VMT - SMFG - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vmt_SMFG_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_RIFC_Language_Scan3"
            fieldLabel = "5vmt - RIFC - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vmt_RIFC_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_PMFG_Attentional_Scan3"
            fieldLabel = "5vmt - PMFG - Attentional - Scan 3"
            defaultValue={this.props.patientJSONDefault.vmt_PMFG_Attentional_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_LPTL_Language_Scan3"
            fieldLabel = "5vmt - LPTL - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vmt_LPTL_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LIPG_Language_Scan3"
            fieldLabel = "6vft - LIPG - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vft_LIPG_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LIFGPO_Language_Scan3"
            fieldLabel = "6vft - LIFGPO - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vft_LIFGPO_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LBG_Subcortical_Scan3"
            fieldLabel = "6vft - LBG - Subcortical - Scan 3"
            defaultValue={this.props.patientJSONDefault.vft_LBG_Subcortical_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LIFGPT_Language_Scan3"
            fieldLabel = "6vft - LIFGPT - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vft_LIFGPT_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_MPSMA_Attentional_Scan3"
            fieldLabel = "6vrt - MPSMA - Attentional - Scan 3"
            defaultValue={this.props.patientJSONDefault.vft_MPSMA_Attentional_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_DAC_Attentional_Scan3"
            fieldLabel = "6vft - DAC - Attentional - Scan 3"
            defaultValue={this.props.patientJSONDefault.vft_DAC_Attentional_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LIP_Language_Scan3"
            fieldLabel = "6vft - LIP - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vft_LIP_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LPTL_Language_Scan3"
            fieldLabel = "6vft - LPTL - Language - Scan 3"
            defaultValue={this.props.patientJSONDefault.vft_LPTL_Language_Scan3} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

        </div>
	  );
	}
}

export default withStyles(useStyles)(FNCIOne);




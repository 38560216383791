import React, { Component } from 'react';
//import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button';

import Copyright from './../BoilerPlate/Copyright';
import colors from './../ColorScheme/Colors';


const useStyles = theme => ({
  container: {
    background: colors.backgroundColor,
	flexGrow: 1,
//    minheight : '100vh',
  },
  paper: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: colors.backgroundColor,
  },
  textfield: {
    color: colors.textColorPlain,
  },
  cancel: {
	background: colors.buttonColorPlain,
    color: colors.backgroundColor,
  },
  deletebutton: {
	background: 'red',
    color: colors.backgroundColor,
  },
});

function deletePatientData(patientJSONID) {
	var patientsApiUrl = '/api/patients';
    const patientData = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    };

	var params = {searchID: patientJSONID, 
                 }

	let query = Object.keys(params)
	             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
	             .join('&');

	patientsApiUrl = patientsApiUrl + '?' + query

	console.log(patientsApiUrl, 'patientsApiUrl in delete request');

    fetch(patientsApiUrl, patientData)
        .then(response => response.json())
}

class DeletePatient extends Component {
//	constructor(props) {
//		super(props);
//	}

	handleCancel = event => {
		console.log('Did not delete.');
		this.props.setPage("EditPatient", this.props.patientJSONID)
	}

	handleDelete = event => {
		console.log('Deleting patient data');
		deletePatientData(this.props.patientJSONID)
		this.props.setPage("Home")
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
      const { classes } = this.props;
	  return (
		<Container component="main" className={classes.container}>
			<Container maxWidth="xs" className={classes.container}>
			  <CssBaseline />
			  <div className={classes.paper}>
				<Typography component="h1" variant="h5" className={classes.textfield}>
				  This will permanently delete all info for this patient.  Proceed?
				</Typography>
				<Button
					fullWidth
					variant="contained"
					color="default"
					className={classes.cancel}
					onClick={this.handleCancel}
				>
					Cancel
				</Button>
				<Button
					fullWidth
					variant="contained"
					color="secondary"
					className={classes.deletebutton}
					onClick={this.handleDelete}
				>
					Delete
				</Button>
              </div>
			  <Box mt={80}>
				<Copyright />
			  </Box>
			</Container>
		</Container>
	  );
	}
}

export default withStyles(useStyles)(DeletePatient);





import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import DisplayOnlyTextField from './CustomFields/DisplayOnlyTextField';
import RadioGroupYesNo from './CustomFields/RadioGroupYesNo';
import RadioGroupSymptoms from './CustomFields/RadioGroupSymptoms';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

const physicalList = ['HeadachePre',
                      'NauseaPre',
                      'VomitingPre',
                      'BalanceProblemsPre',
                      'DizzinessPre',
                      'VisualProblemsPre',
                      'FatiguePre',
                      'SensitivityToLightPre',
                      'SensitivityToNoisePre',
                      'NumbnessTinglingPre',
                   ]

const thinkingList = ['MentalFogPre',
                      'FeelingSlowedDownPre',
                      'DifficultyConcentratingPre',
                      'DifficultyRememberingPre',
					 ]

const sleepList = ['DrowsinessPre',
                   'SleepingLessThanUsualPre',
                   'SleepingMoreThanUsualPre',
                   'TroubleFallingAsleepPre',
			      ]

const emotionalList = ['IrritabilityPre',
                       'SadnessPre',
                       'NervousnessPre',
                       'FeelingMoreEmotionalPre',
			           ]

var totalList = [...physicalList];
totalList.push(...thinkingList);
totalList.push(...sleepList);
totalList.push(...emotionalList);



class PCSSPre extends Component {
	constructor(props) {
		super(props);

		this.handleChangeNone = this.handleChangeNone.bind(this);
	}

	computeNumberOfSymptoms(list, event) {
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if ((value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else {
				total += 1
			}
		}

		return total
	}

	computePercentOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}

			if ((value !== '0') && (value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else if (value === '0') {
				total += 1
			}
			else {
				totalYes += 1
				total += 1
			}
		}

		const percent = totalYes / Math.max(total, 1) * 100

		return percent
	}

	computeAverageOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if (value === '0') {
				total += 1
			}
			else if (value === '1') {
				totalYes += 1
				total += 1
			}
			else if (value === '2') {
				totalYes += 2
				total += 1
			}
			else if (value === '3') {
				totalYes += 3
				total += 1
			}
			else if (value === '4') {
				totalYes += 4
				total += 1
			}
			else if (value === '5') {
				totalYes += 5
				total += 1
			}
			else if (value === '6') {
				totalYes += 6
				total += 1
			}
		}

		const average = totalYes / Math.max(total, 1)

		return average
	}

    handleChangeNone = event =>{
		// A placeholder to avoid an error.  Does nothing
    }

    handleChange = event =>{
		this.props.handleChange(event);
    }

    handleChangePhysical = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(physicalList, event)
		const percent = this.computePercentOfSymptoms(physicalList, event)
		const average = this.computeAverageOfSymptoms(physicalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		const post = this.getSISPost()
		const improve = (post - pcssScore) / Math.max(pcssScore, 0.01) * 100.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfPhysicalSymptomsEndorsedPre', 
            'PercentOfPhysicalSymptomsEndorsedPre', 
            'AverageScoreOfPhysicalSymptomsPre',
            'NumberOfTotalSymptomsEndorsedPre',
            'PercentOfTotalSymptomsEndorsedPre', 
            'AverageScoreOfTotalSymptomsPre',
            'PCSSIndexScorePre',
			'PercentImprovementPCSS'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore,
			improve
          ]
        );
    }

    handleChangeThinking = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(thinkingList, event)
		const percent = this.computePercentOfSymptoms(thinkingList, event)
		const average = this.computeAverageOfSymptoms(thinkingList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		const post = this.getSISPost()
		const improve = (post - pcssScore) / Math.max(pcssScore, 0.01) * 100.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfThinkingSymptomsEndorsedPre', 
            'PercentOfThinkingSymptomsEndorsedPre', 
            'AverageScoreOfThinkingSymptomsPre',
            'NumberOfTotalSymptomsEndorsedPre',
            'PercentOfTotalSymptomsEndorsedPre', 
            'AverageScoreOfTotalSymptomsPre',
            'PCSSIndexScorePre',
			'PercentImprovementPCSS'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore,
			improve
          ]
        );
    }

    handleChangeSleep = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(sleepList, event)
		const percent = this.computePercentOfSymptoms(sleepList, event)
		const average = this.computeAverageOfSymptoms(sleepList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		const post = this.getSISPost()
		const improve = (post - pcssScore) / Math.max(pcssScore, 0.01) * 100.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfSleepSymptomsEndorsedPre', 
            'PercentOfSleepSymptomsEndorsedPre', 
            'AverageScoreOfSleepSymptomsPre',
            'NumberOfTotalSymptomsEndorsedPre',
            'PercentOfTotalSymptomsEndorsedPre', 
            'AverageScoreOfTotalSymptomsPre',
            'PCSSIndexScorePre',
			'PercentImprovementPCSS'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore,
			improve
          ]
        );
    }

    handleChangeEmotional = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(emotionalList, event)
		const percent = this.computePercentOfSymptoms(emotionalList, event)
		const average = this.computeAverageOfSymptoms(emotionalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		const post = this.getSISPost()
		const improve = (post - pcssScore) / Math.max(pcssScore, 0.01) * 100.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfEmotionalSymptomsEndorsedPre', 
            'PercentOfEmotionalSymptomsEndorsedPre', 
            'AverageScoreOfEmotionalSymptomsPre',
            'NumberOfTotalSymptomsEndorsedPre',
            'PercentOfTotalSymptomsEndorsedPre', 
            'AverageScoreOfTotalSymptomsPre',
            'PCSSIndexScorePre',
			'PercentImprovementPCSS'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore,
			improve
          ]
        );
    }

	getSISPost = () => {
		var value = ''

		// Get the value from the right source
		if (this.props.patientJSON === '') {
			value = this.props.patientJSONDefault['PCSSIndexScorePost'];
		}			
		else if ('PCSSIndexScorePost' in this.props.patientJSON) {
			value = this.props.patientJSON['PCSSIndexScorePost'];
		}
		else {
			value = this.props.patientJSONDefault['PCSSIndexScorePost'];
		}

		return value
	}

	getNewest(eventName) {
		var value = 0
		if (this.props.patientJSON === '') {
			if (this.props.patientJSONDefault === '') {
			}
			else if (eventName in this.props.patientJSONDefault) {
				value = this.props.patientJSONDefault[eventName];
			}
		}
		else {
			if (eventName in this.props.patientJSON) {
				value = this.props.patientJSON[eventName];
			}
			else {
				if (this.props.patientJSONDefault === '') {
				}
				else if (eventName in this.props.patientJSONDefault) {
					value = this.props.patientJSONDefault[eventName];
				}
			}		
		}

		return value
	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <RadioGroupSymptoms 
            fieldName = "HeadachePre"
            fieldLabel = "Headache - Pre"
            defaultValue={this.props.patientJSONDefault.HeadachePre} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NauseaPre"
            fieldLabel = "Nausea - Pre"
            defaultValue={this.props.patientJSONDefault.NauseaPre} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VomitingPre"
            fieldLabel = "Vomiting - Pre"
            defaultValue={this.props.patientJSONDefault.VomitingPre} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "BalanceProblemsPre"
            fieldLabel = "Balance Problems - Pre"
            defaultValue={this.props.patientJSONDefault.BalanceProblemsPre} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DizzinessPre"
            fieldLabel = "Dizziness - Pre"
            defaultValue={this.props.patientJSONDefault.DizzinessPre} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VisualProblemsPre"
            fieldLabel = "Visual Problems - Pre"
            defaultValue={this.props.patientJSONDefault.VisualProblemsPre} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FatiguePre"
            fieldLabel = "Fatigue - Pre"
            defaultValue={this.props.patientJSONDefault.FatiguePre} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToLightPre"
            fieldLabel = "Sensitivity to Light - Pre"
            defaultValue={this.props.patientJSONDefault.SensitivityToLightPre} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToNoisePre"
            fieldLabel = "Sensitivity to Noise - Pre"
            defaultValue={this.props.patientJSONDefault.SensitivityToNoisePre} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NumbnessTinglingPre"
            fieldLabel = "Numbness or Tingling - Pre"
            defaultValue={this.props.patientJSONDefault.NumbnessTinglingPre} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "MentalFogPre"
            fieldLabel = "Mental Fog - Pre"
            defaultValue={this.props.patientJSONDefault.MentalFogPre} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingSlowedDownPre"
            fieldLabel = "Feeling Slowed Down - Pre"
            defaultValue={this.props.patientJSONDefault.FeelingSlowedDownPre} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyConcentratingPre"
            fieldLabel = "Difficulty Concentrating - Pre"
            defaultValue={this.props.patientJSONDefault.DifficultyConcentratingPre} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyRememberingPre"
            fieldLabel = "Difficulty Remembering - Pre"
            defaultValue={this.props.patientJSONDefault.DifficultyRememberingPre} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DrowsinessPre"
            fieldLabel = "Drowsiness - Pre"
            defaultValue={this.props.patientJSONDefault.DrowsinessPre} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingLessThanUsualPre"
            fieldLabel = "Sleeping Less Than Usual - Pre"
            defaultValue={this.props.patientJSONDefault.SleepingLessThanUsualPre} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingMoreThanUsualPre"
            fieldLabel = "Sleeping More Than Usual - Pre"
            defaultValue={this.props.patientJSONDefault.SleepingMoreThanUsualPre} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "TroubleFallingAsleepPre"
            fieldLabel = "Trouble Falling Asleep - Pre"
            defaultValue={this.props.patientJSONDefault.TroubleFallingAsleepPre} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "IrritabilityPre"
            fieldLabel = "Irritability - Pre"
            defaultValue={this.props.patientJSONDefault.IrritabilityPre} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SadnessPre"
            fieldLabel = "Sadness - Pre"
            defaultValue={this.props.patientJSONDefault.SadnessPre} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NervousnessPre"
            fieldLabel = "Nervousness - Pre"
            defaultValue={this.props.patientJSONDefault.NervousnessPre} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingMoreEmotionalPre"
            fieldLabel = "Feeling More Emotional - Pre"
            defaultValue={this.props.patientJSONDefault.FeelingMoreEmotionalPre} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "PainOtherThanHeadachePre"
            fieldLabel = "Pain Other Than Headache - Pre"
            defaultValue={this.props.patientJSONDefault.PainOtherThanHeadachePre} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithPhysicalActivityPre"
	        fieldLabel = "Worsen with Physical Activity - Pre"
	        defaultValue={this.props.patientJSONDefault.WorsenWithPhysicalActivityPre}
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithCognitiveActivityPre"
	        fieldLabel = "Worsen with Cognitive Activity - Pre"
	        defaultValue={this.props.patientJSONDefault.WorsenWithCognitiveActivityPre} 
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <DisplayOnlyTextField 
	        fieldName = "PCSSIndexScorePre"
	        fieldLabel = "PCSS Index Score - Pre (Calculated)"
	        value = {this.getNewest("PCSSIndexScorePre").toFixed(2)}
	        handleChange={this.handleChange}
	      />

        </div>
	  );
	}
}

export default withStyles(useStyles)(PCSSPre);




//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfPhysicalSymptomsEndorsedPre"
//	        fieldLabel = "Number of Physical Symptoms Endorsed - Pre (Calculated)"
//	        value = {this.getNewest("NumberOfPhysicalSymptomsEndorsedPre")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfPhysicalSymptomsEndorsedPre"
//	        fieldLabel = "Percent of Physical Symptoms Endorsed - Pre (Calculated)"
//	        value = {this.getNewest("PercentOfPhysicalSymptomsEndorsedPre").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfPhysicalSymptomsPre"
//	        fieldLabel = "Average Score of Physical Symptoms - Pre (Calculated)"
//	        value = {this.getNewest("AverageScoreOfPhysicalSymptomsPre").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfThinkingSymptomsEndorsedPre"
//	        fieldLabel = "Number of Thinking Symptoms Endorsed - Pre (Calculated)"
//	        value = {this.getNewest("NumberOfThinkingSymptomsEndorsedPre")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfThinkingSymptomsEndorsedPre"
//	        fieldLabel = "Percent of Thinking Symptoms Endorsed - Pre (Calculated)"
//	        value = {this.getNewest("PercentOfThinkingSymptomsEndorsedPre").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfThinkingSymptomsPre"
//	        fieldLabel = "Average Score of Thinking Symptoms - Pre (Calculated)"
//	        value = {this.getNewest("AverageScoreOfThinkingSymptomsPre").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfSleepSymptomsEndorsedPre"
//	        fieldLabel = "Number of Sleep Symptoms Endorsed - Pre (Calculated)"
//	        value = {this.getNewest("NumberOfSleepSymptomsEndorsedPre")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfSleepSymptomsEndorsedPre"
//	        fieldLabel = "Percent of Sleep Symptoms Endorsed - Pre (Calculated)"
//	        value = {this.getNewest("PercentOfSleepSymptomsEndorsedPre").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfSleepSymptomsPre"
//	        fieldLabel = "Average Score of Sleep Symptoms - Pre (Calculated)"
//	        value = {this.getNewest("AverageScoreOfSleepSymptomsPre").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfEmotionalSymptomsEndorsedPre"
//	        fieldLabel = "Number of Emotional Symptoms Endorsed - Pre (Calculated)"
//	        value = {this.getNewest("NumberOfEmotionalSymptomsEndorsedPre")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfEmotionalSymptomsEndorsedPre"
//	        fieldLabel = "Percent of Emotional Symptoms Endorsed - Pre (Calculated)"
//	        value = {this.getNewest("PercentOfEmotionalSymptomsEndorsedPre").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfEmotionalSymptomsPre"
//	        fieldLabel = "Average Score of Emotional Symptoms - Pre (Calculated)"
//	        value = {this.getNewest("AverageScoreOfEmotionalSymptomsPre").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfTotalSymptomsEndorsedPre"
//	        fieldLabel = "Number of Total Symptoms Endorsed - Pre (Calculated)"
//	        value = {this.getNewest("NumberOfTotalSymptomsEndorsedPre")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfTotalSymptomsEndorsedPre"
//	        fieldLabel = "Percent of Total Symptoms Endorsed - Pre (Calculated)"
//	        value = {this.getNewest("PercentOfTotalSymptomsEndorsedPre").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfTotalSymptomsPre"
//	        fieldLabel = "Average Score of All Symptoms - Pre (Calculated)"
//	        value = {this.getNewest("AverageScoreOfTotalSymptomsPre").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />






import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import StandardTextField from './CustomFields/StandardTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

class TreatmentInfo extends Component {
//	constructor(props) {
//		super(props);
//	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <StandardTextField 
            fieldName = "HowManyfNCIsHaveTheyReceived2"
            fieldLabel = "How many fNCIs have they received?"
            defaultValue={this.props.patientJSONDefault.HowManyfNCIsHaveTheyReceived2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
		  <StandardTextField 
            fieldName = "HowManyWeeksOfTreatment"
            fieldLabel = "How many weeks of treatment?"
            defaultValue={this.props.patientJSONDefault.HowManyWweeksOfTreatment} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
		  <StandardTextField 
            fieldName = "HaveTheyExperiecedAnyOfTheFollowing"
            fieldLabel = "Have they experieced any of the following?"
            defaultValue={this.props.patientJSONDefault.HaveTheyExperiecedAnyOfTheFollowing} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
		  <StandardTextField 
            fieldName = "NumberOfBoosterDays"
            fieldLabel = "Number of booster days"
            defaultValue={this.props.patientJSONDefault.NumberOfBoosterDays} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
        </div>
	  );
	}
}

export default withStyles(useStyles)(TreatmentInfo);




import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import StandardTextField from './CustomFields/StandardTextField';
import RadioGroupHabits from './CustomFields/RadioGroupHabits';
import DateTextField from './CustomFields/DateTextField';

import colors from './../../ColorScheme/Colors';

const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

class MedicalHistory extends Component {
//	constructor(props) {
//		super(props);
//	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <StandardTextField 
            fieldName = "priorScans"
            fieldLabel = "Prior Scans (MRI, etc..)"
            defaultValue={this.props.patientJSONDefault.priorScans} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <DateTextField 
            fieldName = "priorScanDate"
            fieldLabel = "Prior Scan Date"
            defaultValue={this.props.patientJSONDefault.priorScanDate} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "significantMedicalDiagnosis"
            fieldLabel = "Significant Medical Diagnosis"
            defaultValue={this.props.patientJSONDefault.significantMedicalDiagnosis} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "allergies"
            fieldLabel = "Allergies"
            defaultValue={this.props.patientJSONDefault.allergies} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXAlzheimers"
            fieldLabel = "DODX Alzheimers"
            defaultValue={this.props.patientJSONDefault.DODXAlzheimers} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXAnxiety"
            fieldLabel = "DODX Anxiety"
            defaultValue={this.props.patientJSONDefault.DODXAnxiety} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXArthritis"
            fieldLabel = "DODX Arthritis"
            defaultValue={this.props.patientJSONDefault.DODXArthritis} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXAsthma"
            fieldLabel = "DODX Asthma"
            defaultValue={this.props.patientJSONDefault.DODXAsthma} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXBipolarDisorder"
            fieldLabel = "DODX Bipolar Disorder"
            defaultValue={this.props.patientJSONDefault.DODXBipolarDisorder} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXDementia"
            fieldLabel = "DODX Dementia"
            defaultValue={this.props.patientJSONDefault.DODXDementia} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXDepression"
            fieldLabel = "DODX Depression"
            defaultValue={this.props.patientJSONDefault.DODXDepression} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXDiabetes"
            fieldLabel = "DODX Diabetes"
            defaultValue={this.props.patientJSONDefault.DODXDiabetes} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXEpilepsy"
            fieldLabel = "DODX Epilepsy"
            defaultValue={this.props.patientJSONDefault.DODXEpilepsy} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXHeartDisease"
            fieldLabel = "DODX Heart Disease"
            defaultValue={this.props.patientJSONDefault.DODXHeartDisease} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXHypertension"
            fieldLabel = "DODX Hypertension"
            defaultValue={this.props.patientJSONDefault.DODXHypertension} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXMultipleScleroris"
            fieldLabel = "DODX Multiple Scleroris"
            defaultValue={this.props.patientJSONDefault.DODXMultipleScleroris} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXOsteoporosis"
            fieldLabel = "DODX Osteoporosis"
            defaultValue={this.props.patientJSONDefault.DODXOsteoporosis} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXParkinsons"
            fieldLabel = "DODX Parkinsons"
            defaultValue={this.props.patientJSONDefault.DODXParkinsons} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXPTSD"
            fieldLabel = "DODX PTSD"
            defaultValue={this.props.patientJSONDefault.DODXPTSD} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXStroke"
            fieldLabel = "DODX Stroke"
            defaultValue={this.props.patientJSONDefault.DODXStroke} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXThyroidProblems"
            fieldLabel = "DODX Thyroid Problems"
            defaultValue={this.props.patientJSONDefault.DODXThyroidProblems} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "LearningDisabilities"
            fieldLabel = "Learning Disabilities"
            defaultValue={this.props.patientJSONDefault.LearningDisabilities} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXADDADHD"
            fieldLabel = "DODX ADD or ADHD"
            defaultValue={this.props.patientJSONDefault.DODXADDADHD} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXAspergers"
            fieldLabel = "DODX Aspergers"
            defaultValue={this.props.patientJSONDefault.DODXAspergers} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXAuditoryProcessing"
            fieldLabel = "DODX Auditory Processing"
            defaultValue={this.props.patientJSONDefault.DODXAuditoryProcessing} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXAutism"
            fieldLabel = "DODX Autism"
            defaultValue={this.props.patientJSONDefault.DODXAutism} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXBPPV"
            fieldLabel = "DODX BPPV"
            defaultValue={this.props.patientJSONDefault.DODXBPPV} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXDysautonomia"
            fieldLabel = "DODX Dysautonomia"
            defaultValue={this.props.patientJSONDefault.DODXDysautonomia} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXCeliacDisease"
            fieldLabel = "DODX Celiac Disease"
            defaultValue={this.props.patientJSONDefault.DODXCeliacDisease} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXLabyrinthitisOrVestibularNeuritis"
            fieldLabel = "DODX Labyrinthitis Or Vestibular Neuritis"
            defaultValue={this.props.patientJSONDefault.DODXLabyrinthitisOrVestibularNeuritis} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXLupus"
            fieldLabel = "DODX Lupus"
            defaultValue={this.props.patientJSONDefault.DODXLupus} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXMenieresDisease"
            fieldLabel = "DODX Menieres Disease"
            defaultValue={this.props.patientJSONDefault.DODXMenieresDisease} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXMigraines"
            fieldLabel = "DODX Migraines"
            defaultValue={this.props.patientJSONDefault.DODXMigraines} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXOrthostaticHypotension"
            fieldLabel = "DODX Orthostatic Hypotension"
            defaultValue={this.props.patientJSONDefault.DODXOrthostaticHypotension} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXPANDAS"
            fieldLabel = "DODX PANDAS"
            defaultValue={this.props.patientJSONDefault.DODXPANDAS} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXPOTS"
            fieldLabel = "DODX POTS"
            defaultValue={this.props.patientJSONDefault.DODXPOTS} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXSEH"
            fieldLabel = "DODX SEH"
            defaultValue={this.props.patientJSONDefault.DODXSEH} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXSjogrensSyndrome"
            fieldLabel = "DODX Sjogrens Syndrome"
            defaultValue={this.props.patientJSONDefault.DODXSjogrensSyndrome} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXRheumatoidArthritis"
            fieldLabel = "DODX Rheumatoid Arthritis"
            defaultValue={this.props.patientJSONDefault.DODXRheumatoidArthritis} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "DODXOther"
            fieldLabel = "DODX Other"
            defaultValue={this.props.patientJSONDefault.DODXOther} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "PhysicalLimitationsInhibittingExercise"
            fieldLabel = "Physical Limitations Inhibiting Exercise"
            defaultValue={this.props.patientJSONDefault.PhysicalLimitationsInhibittingExercise} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "RateYourActivityLevelNow"
            fieldLabel = "Rate Your Activity Level Now"
            defaultValue={this.props.patientJSONDefault.RateYourActivityLevelNow} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "RateYourActivityLevelPriorToInjury"
            fieldLabel = "Rate Your Activity Level Prior To Injury"
            defaultValue={this.props.patientJSONDefault.RateYourActivityLevelPriorToInjury} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "patientMedications"
            fieldLabel = "Patient Medications"
            defaultValue={this.props.patientJSONDefault.patientMedications} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupHabits 
            fieldName = "habitualAlcohol"
            fieldLabel = "Habitual Alcohol"
            defaultValue={this.props.patientJSONDefault.habitualAlcohol} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupHabits 
            fieldName = "habitualCaffeine"
            fieldLabel = "Habitual Caffeine"
            defaultValue={this.props.patientJSONDefault.habitualCaffeine} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupHabits 
            fieldName = "habitualCigarettes"
            fieldLabel = "Habitual Cigarettes"
            defaultValue={this.props.patientJSONDefault.habitualCigarettes} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupHabits 
            fieldName = "habitualECigarettes"
            fieldLabel = "Habitual E-Cigarettes"
            defaultValue={this.props.patientJSONDefault.habitualECigarettes} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupHabits 
            fieldName = "habitualRecreationalDrugs"
            fieldLabel = "Habitual Recreational Drugs"
            defaultValue={this.props.patientJSONDefault.habitualRecreationalDrugs} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

        </div>
	  );
	}
}

export default withStyles(useStyles)(MedicalHistory);




import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import timeConverterForTable from './../BoilerPlate/TimeConvertersForTable';

import Copyright from './../BoilerPlate/Copyright';
import colors from './../ColorScheme/Colors';


const useStyles = theme => ({
  paper: {
    background: colors.backgroundColor,
  },
  table: {
    minWidth: 700,
  },
  searchrow: {
    background: colors.searchRowColor,
  },
  upperAndLowerSearch: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  searchcell: {
    width: "180px",
//    height: "100px",
//    border: 'solid 1px ' + colors.textColorPlain,
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
  },
  searchcellHalf: {
    width: "180px",
//    height: "100px",
//    border: 'solid 1px ' + colors.textColorPlain,
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    marginLeft: '1px',
//    marginRight: '1px',
  },
  headrow: {
    background: colors.headRowColor,
  },
  tablerow: {
    height: 30
  },
  tablecell: {
	padding: "2px 16px",
	overflow: 'hidden',
	color: colors.textColorPlain,
  },
  textfield: {
    color: colors.textColorPlain,
  },
  editButton: {
	background: colors.buttonColorBold,
    color: colors.textColorBold,
  },
  addButton: {
	background: colors.buttonColorBold,
    color: colors.textColorBold,
    whiteSpace: "nowrap"
  },
  viewButton: {
	background: colors.buttonColorPlain,
    color: colors.textColorPlain,
  },
  pageButton: {
	background: colors.buttonColorPlain,
    color: colors.textColorPlain,
    margin: '5px',
  },
});



function createData(patientJSON) {
	const id = patientJSON._id.$oid
	const lastName = patientJSON.lastName
	const firstName = patientJSON.firstName
	const patientType = patientJSON.patientType
	const dobDisplay = timeConverterForTable(patientJSON.dob)
    const email = patientJSON.email
	const gender = patientJSON.gender
	const state = patientJSON.state
	const country = patientJSON.country
	const dateOfInjuryDisplay = timeConverterForTable(patientJSON.dateOfInjury)
	const causeOfInjury = patientJSON.causeOfInjury
	const SISScoreScan1 = patientJSON.SISScoreScan1
	const TotalVisionScore = patientJSON.TotalVisionScore
	const paperWorkSubDateDisplay = timeConverterForTable(patientJSON.paperworkSubDate)
	const firstDayOfTreatmentDisplay = timeConverterForTable(patientJSON.DateOfFirstDayOfTreatment)

	//console.log('paperworkSubDate', patientJSON.paperworkSubDate);
	//console.log('paperWorkSubDateDisplay', paperWorkSubDateDisplay);

	return { id, 
             lastName, 
             firstName, 
             patientType,
             dobDisplay, 
             email,
             gender, 
             state, 
             country,
             dateOfInjuryDisplay, 
             causeOfInjury, 
             SISScoreScan1, 
             TotalVisionScore, 
			 paperWorkSubDateDisplay,
             firstDayOfTreatmentDisplay,
           };
}

class PatientTable extends Component {

	constructor(props) {
		super(props);

		this.state = {
			patientsJSON: [],
			isLoaded: false,
            pageNumber: 1,
			searchLastName:'',
			searchFirstName:'',
            searchDobLower:'',
			searchDobUpper:'',
            searchPatientType:'',
            searchEmail:'',
			searchGender:'',
			searchState:'',
			searchCountry:'',
			searchDateOfInjuryLower:'',
            searchDateOfInjuryUpper:'',
			searchCauseOfInjury:'',
			searchSis1ScoreLower:'',
			searchSis1ScoreUpper:'',
			searchTotalVisionScoreLower:'',
			searchTotalVisionScoreUpper:'',
			searchPaperWorkSubDateLower:'',
			searchPaperWorkSubDateUpper:'',
			searchFirstDayOfTreatmentLower:'',
			searchFirstDayOfTreatmentUpper:'',
		};
	}

	componentDidMount() {
		var patientsApiUrl = '/api/patients';

		var params = {searchLastName: this.state.searchLastName, 
                      searchFirstName: this.state.searchFirstName,
					  searchDobLower: this.state.searchDobLower,
                      searchDobUpper: this.state.searchDobUpper,
                      searchPatientType: this.state.searchPatientType,
                      searchEmail: this.state.searchEmail,
                      searchGender: this.state.searchGender,
					  searchState: this.state.searchState,
					  searchCountry: this.state.searchCountry,
					  searchDateOfInjuryLower: this.state.searchDateOfInjuryLower,
					  searchDateOfInjuryUpper: this.state.searchDateOfInjuryUpper,
					  searchCauseOfInjury: this.state.searchCauseOfInjury,
					  searchSis1ScoreLower: this.state.searchSis1ScoreLower,
					  searchSis1ScoreUpper: this.state.searchSis1ScoreUpper,
					  searchTotalVisionScoreLower: this.state.searchTotalVisionScoreLower,
					  searchTotalVisionScoreUpper: this.state.searchTotalVisionScoreUpper,
					  searchPaperWorkSubDateLower: this.state.searchPaperWorkSubDateLower,
					  searchPaperWorkSubDateUpper: this.state.searchPaperWorkSubDateUpper,
					  searchFirstDayOfTreatmentLower: this.state.searchFirstDayOfTreatmentLower,
					  searchFirstDayOfTreatmentUpper: this.state.searchFirstDayOfTreatmentUpper,
					  pageNumber: this.state.pageNumber,
                     }

		let query = Object.keys(params)
		             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		             .join('&');

		patientsApiUrl = patientsApiUrl + '?' + query

//		patientsApiUrl.search = new URLSearchParams(params).toString();

		fetch(patientsApiUrl)
		  .then(response => response.json())
          .then(data => this.setState({ patientsJSON: data, isLoaded: true }, () => {
//		  console.log(this.state.patientsJSON, 'patientsJSON');
//		  console.log(this.state.isLoaded, 'isLoaded');
		})); 

		this.setState({ pageNumber: 1 })
	}

	componentDidUpdate(prevProps, prevState) {
		if ((prevState.searchLastName !== this.state.searchLastName) ||
		    (prevState.searchFirstName !== this.state.searchFirstName) ||
            (prevState.searchDobLower !== this.state.searchDobLower) ||
		    (prevState.searchDobUpper !== this.state.searchDobUpper) ||
            (prevState.searchPatientType !== this.state.searchPatientType) ||
            (prevState.searchEmail !== this.state.searchEmail) ||
            (prevState.searchGender !== this.state.searchGender) ||
         	(prevState.searchState !== this.state.searchState) ||
			(prevState.searchCountry !== this.state.searchCountry) ||
	  	    (prevState.searchDateOfInjuryLower !== this.state.searchDateOfInjuryLower) ||
	  	    (prevState.searchDateOfInjuryUpper !== this.state.searchDateOfInjuryUpper) ||
		    (prevState.searchCauseOfInjury !== this.state.searchCauseOfInjury) ||
		    (prevState.searchSis1ScoreLower !== this.state.searchSis1ScoreLower) ||
		    (prevState.searchSis1ScoreUpper !== this.state.searchSis1ScoreUpper) ||
		    (prevState.searchTotalVisionScoreLower !== this.state.searchTotalVisionScoreLower) ||
		    (prevState.searchTotalVisionScoreUpper !== this.state.searchTotalVisionScoreUpper) ||
		    (prevState.searchPaperWorkSubDateLower !== this.state.searchPaperWorkSubDateLower) ||
		    (prevState.searchPaperWorkSubDateUpper !== this.state.searchPaperWorkSubDateUpper) ||
 	        (prevState.searchFirstDayOfTreatmentLower !== this.state.searchFirstDayOfTreatmentLower) ||
 	        (prevState.searchFirstDayOfTreatmentUpper !== this.state.searchFirstDayOfTreatmentUpper) ||
			(prevState.pageNumber !== this.state.pageNumber)
           )    {

			var patientsApiUrl = '/api/patients';

			var params = {searchLastName: this.state.searchLastName, 
		                  searchFirstName: this.state.searchFirstName,
						  searchDobLower: this.state.searchDobLower,
		                  searchDobUpper: this.state.searchDobUpper,
                          searchPatientType: this.state.searchPatientType,
                          searchEmail: this.state.searchEmail,
		                  searchGender: this.state.searchGender,
						  searchState: this.state.searchState,
						  searchCountry: this.state.searchCountry,
					  	  searchDateOfInjuryLower: this.state.searchDateOfInjuryLower,
					  	  searchDateOfInjuryUpper: this.state.searchDateOfInjuryUpper,
					  	  searchCauseOfInjury: this.state.searchCauseOfInjury,
					 	  searchSis1ScoreLower: this.state.searchSis1ScoreLower,
						  searchSis1ScoreUpper: this.state.searchSis1ScoreUpper,
						  searchTotalVisionScoreLower: this.state.searchTotalVisionScoreLower,
						  searchTotalVisionScoreUpper: this.state.searchTotalVisionScoreUpper,
						  searchPaperWorkSubDateLower: this.state.searchPaperWorkSubDateLower,
						  searchPaperWorkSubDateUpper: this.state.searchPaperWorkSubDateUpper,
						  searchFirstDayOfTreatmentLower: this.state.searchFirstDayOfTreatmentLower,
						  searchFirstDayOfTreatmentUpper: this.state.searchFirstDayOfTreatmentUpper,
						  pageNumber: this.state.pageNumber,
		                 }

			let query = Object.keys(params)
				         .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
				         .join('&');

			patientsApiUrl = patientsApiUrl + '?' + query

	//		patientsApiUrl.search = new URLSearchParams(params).toString();

			fetch(patientsApiUrl)
			  .then(response => response.json())
		      .then(data => this.setState({ patientsJSON: data, isLoaded: true }, () => {
//			  console.log(this.state.patientsJSON, 'patientsJSON');
//			  console.log(this.state.isLoaded, 'isLoaded');
			})); 

			// Reset the pageNumber if it's something else that has changed
			if (prevState.pageNumber === this.state.pageNumber) {
				this.setState({ pageNumber: 1 })
			}
		}
	}

    handleChange = event =>{
        this.setState({ [event.target.name]:event.target.value })
    }

	viewOnClick = (id) => {
//        console.log('viewClicked with id', id);

		this.props.setPage("ViewPatient", id)
    }

	editOnClick = (id) => {
//        console.log('viewClicked with id', id);

		this.props.setPage("EditPatient", id)
    }

	prevPageClick = () => {
//        console.log('PrevPageClicked');
		const prevPage = Math.max(this.state.pageNumber - 1, 1)
		this.setState({ pageNumber: prevPage})
    }

	nextPageClick = () => {
//        console.log('NextPageClicked');
		const nextPage = this.state.pageNumber + 1
		this.setState({ pageNumber: nextPage})
    }

    render() {

        const { classes } = this.props;

		if (!this.state.isLoaded) {
		  return <div>Loading ... </div>;
		} else {
//			const numberPerPage = 10
//			const lowerlim = Math.min(numberPerPage*(this.state.pageNumber-1), this.state.patientsJSON.length)
//            const upperlim = Math.min(lowerlim + numberPerPage, this.state.patientsJSON.length)
//			const data = []
//			for(let i = lowerlim; i < upperlim; i++){ 
//				data.push(createData(this.state.patientsJSON[i]));
//			}
			const data = []
			for(let i = 0; i < this.state.patientsJSON.length; i++){ 
				data.push(createData(this.state.patientsJSON[i]));
			}

			//console.log('data', data);

			return (
            <div>
			<TableContainer className={classes.paper}>
			  <Table className={classes.table} size="small">
				<TableHead>
				  <TableRow className = {classes.searchrow}>
					<TableCell className = {classes.tablecell}>
                      <Typography component="h1" variant="h5" className={classes.textfield}>
				        Search:
			          </Typography>
                    </TableCell>
					<TableCell>
					  <TextField
						className = {classes.searchcell}
						variant="outlined"
						margin="normal"
						fullWidth
						id="searchLastName"
						label="Last Name"
					    inputProps={{
						   autoComplete: 'off',
                           className: classes.textfield,
					    }}
                        InputLabelProps={{
                          className: classes.textfield,
                        }}
						name="searchLastName"
                        size="small"
                        onChange={this.handleChange}
					  /> 
					</TableCell>
					<TableCell>
					  <TextField
						className = {classes.searchcell}
						variant="outlined"
						margin="normal"
						fullWidth
						id="searchFirstName"
						label="First Name"
					    inputProps={{
						   autoComplete: 'off',
                           className: classes.textfield,
					    }}
                        InputLabelProps={{
                          className: classes.textfield,
                        }}
						name="searchFirstName"
                        size="small"
                        onChange={this.handleChange}
					  /> 
					</TableCell>
                    <TableCell>
					  <div className={classes.upperAndLowerSearch}>
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchDobLower"
							label="DOB (L)"
                            type="date"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
                              shrink: true,
				              className: classes.textfield,
				            }}
							name="searchDobLower"
							size="small"
				            onChange={this.handleChange}
						  /> 
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchDobUpper"
                            type="date"
							label="DOB (U)"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
                              shrink: true,
				              className: classes.textfield,
				            }}
							name="searchDobUpper"
							size="small"
				            onChange={this.handleChange}
						  /> 
					  </div>
                    </TableCell>
					<TableCell>
					  <TextField
						className = {classes.searchcell}
						variant="outlined"
						margin="normal"
						fullWidth
						id="searchPatientType"
						label="Patient Type"
					    inputProps={{
						   autoComplete: 'off',
                           className: classes.textfield,
					    }}
                        InputLabelProps={{
                          className: classes.textfield,
                        }}
						name="searchPatientType"
                        size="small"
                        onChange={this.handleChange}
					  /> 
					</TableCell>
					<TableCell>
					  <TextField
						className = {classes.searchcell}
						variant="outlined"
						margin="normal"
						fullWidth
						id="searchEmail"
						label="Email"
					    inputProps={{
						   autoComplete: 'off',
                           className: classes.textfield,
					    }}
                        InputLabelProps={{
                          className: classes.textfield,
                        }}
						name="searchEmail"
                        size="small"
                        onChange={this.handleChange}
					  /> 
					</TableCell>
					<TableCell>
					  <TextField
                        className = {classes.searchcell}
						variant="outlined"
						margin="normal"
						fullWidth
						id="searchGender"
						label="Gender"
					    inputProps={{
						   autoComplete: 'off',
                           className: classes.textfield,
					    }}
                        InputLabelProps={{
                          className: classes.textfield,
                        }}
						name="searchGender"
						size="small"
                        onChange={this.handleChange}
					  /> 
					</TableCell>
					<TableCell>
					  <TextField
                        className = {classes.searchcell}
						variant="outlined"
						margin="normal"
						fullWidth
						id="searchState"
						label="State"
					    inputProps={{
						   autoComplete: 'off',
                           className: classes.textfield,
					    }}
                        InputLabelProps={{
                          className: classes.textfield,
                        }}
						name="searchState"
						size="small"
                        onChange={this.handleChange}
					  /> 
					</TableCell>
					<TableCell>
					  <TextField
                        className = {classes.searchcell}
						variant="outlined"
						margin="normal"
						fullWidth
						id="searchCountry"
						label="Country"
					    inputProps={{
						   autoComplete: 'off',
                           className: classes.textfield,
					    }}
                        InputLabelProps={{
                          className: classes.textfield,
                        }}
						name="searchCountry"
						size="small"
                        onChange={this.handleChange}
					  /> 
					</TableCell>
                    <TableCell>
					  <div className={classes.upperAndLowerSearch}>
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchDateOfInjuryLower"
							label="DOI (L)"
                            type="date"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
                              shrink: true,
				              className: classes.textfield,
				            }}
							name="searchDateOfInjuryLower"
							size="small"
				            onChange={this.handleChange}
						  /> 
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchDateOfInjuryUpper"
							label="DOI (U)"
                            type="date"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
                              shrink: true,
				              className: classes.textfield,
				            }}
							name="searchDateOfInjuryUpper"
							size="small"
				            onChange={this.handleChange}
						  /> 
					  </div>
                    </TableCell>
					<TableCell>
					  <TextField
                        className = {classes.searchcell}
						variant="outlined"
						margin="normal"
						fullWidth
						id="searchCauseOfInjury"
						label="Cause Of Injury"
					    inputProps={{
						   autoComplete: 'off',
                           className: classes.textfield,
					    }}
                        InputLabelProps={{
                          className: classes.textfield,
                        }}
						name="searchCauseOfInjury"
						size="small"
                        onChange={this.handleChange}
					  /> 
					</TableCell>
                    <TableCell>
					  <div className={classes.upperAndLowerSearch}>
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchSis1ScoreLower"
							label="SIS1 (L)"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
				              className: classes.textfield,
				            }}
							name="searchSis1ScoreLower"
							size="small"
				            onChange={this.handleChange}
						  /> 
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchSis1ScoreUpper"
							label="SIS1 (U)"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
				              className: classes.textfield,
				            }}
							name="searchSis1ScoreUpper"
							size="small"
				            onChange={this.handleChange}
						  /> 
					  </div>
                    </TableCell>
                    <TableCell>
					  <div className={classes.upperAndLowerSearch}>
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchTotalVisionScoreLower"
							label="VScore (L)"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
				              className: classes.textfield,
				            }}
							name="searchTotalVisionScoreLower"
							size="small"
				            onChange={this.handleChange}
						  /> 
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchTotalVisionScoreUpper"
							label="VScore (U)"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
				              className: classes.textfield,
				            }}
							name="searchTotalVisionScoreUpper"
							size="small"
				            onChange={this.handleChange}
						  /> 
					  </div>
                    </TableCell>
                    <TableCell>
					  <div className={classes.upperAndLowerSearch}>
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchPaperWorkSubDateLower"
							label="Sub Date (L)"
							type="date"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
							  shrink: true,
				              className: classes.textfield,
				            }}
							name="searchPaperWorkSubDateLower"
							size="small"
				            onChange={this.handleChange}
						  /> 
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchPaperWorkSubDateUpper"
							label="Sub Date (U)"
							type="date"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
							  shrink: true,
				              className: classes.textfield,
				            }}
							name="searchPaperWorkSubDateUpper"
							size="small"
				            onChange={this.handleChange}
						  /> 
					  </div>
                    </TableCell>
                    <TableCell>
					  <div className={classes.upperAndLowerSearch}>
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchFirstDayOfTreatmentLower"
							label="FDOT (L)"
                            type="date"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
                              shrink: true,
				              className: classes.textfield,
				            }}
							name="searchFirstDayOfTreatmentLower"
							size="small"
				            onChange={this.handleChange}
						  /> 
						  <TextField
				            className = {classes.searchcellHalf}
							variant="outlined"
							margin="normal"
							fullWidth
							id="searchFirstDayOfTreatmentUpper"
							label="FDOT (U)"
                            type="date"
							inputProps={{
							   autoComplete: 'off',
				               className: classes.textfield,
							}}
				            InputLabelProps={{
                              shrink: true,
				              className: classes.textfield,
				            }}
							name="searchFirstDayOfTreatmentUpper"
							size="small"
				            onChange={this.handleChange}
						  /> 
					  </div>
                    </TableCell>
				  </TableRow>
				</TableHead>

				<TableHead>
				  <TableRow className = {classes.headrow}>
					<TableCell>
						<Button 
						  onClick={() => this.props.setPage("NewPatient", "")} 
						  variant="contained" 
                          size="small"
						  className={classes.addButton}
						>
						  +Add Patient
						</Button>
					</TableCell>
					<TableCell className = {classes.tablecell}>Last Name</TableCell>
					<TableCell className = {classes.tablecell}>First Name</TableCell>
					<TableCell className = {classes.tablecell}>DOB</TableCell>
					<TableCell className = {classes.tablecell}>Patient Type</TableCell>
                    <TableCell className = {classes.tablecell}>Email</TableCell>
					<TableCell className = {classes.tablecell}>Gender</TableCell>
					<TableCell className = {classes.tablecell}>State</TableCell>
					<TableCell className = {classes.tablecell}>Country</TableCell>
					<TableCell className = {classes.tablecell}>Date of Injury</TableCell>
					<TableCell className = {classes.tablecell}>Cause of Injury</TableCell>
					<TableCell className = {classes.tablecell}>Sis 1 Score</TableCell>
					<TableCell className = {classes.tablecell}>Total Vision Score</TableCell>
					<TableCell className = {classes.tablecell}>Paperwork Sub Date</TableCell>
					<TableCell className = {classes.tablecell}>First Day of Treatment</TableCell>
				  </TableRow>
				</TableHead>

				<TableBody>
				  {data.map(n => {
					return (
					  <TableRow key={n.id} className={classes.tablerow} component="th" scope="row">
						<TableCell className = {classes.tablecell}>{
						  <Grid container spacing={1} direction="row">
                            <Grid item xs={12} sm={6}>
								<Button 
		                          variant="contained" 
		                          onClick={() => this.viewOnClick(n.id)} 
		                          className={classes.viewButton}
								  size="small"
		                        >
								  View
								</Button>
							</Grid>
                            <Grid item xs={12} sm={6}>
								<Button 
		                          variant="contained" 
		                          onClick={() => this.editOnClick(n.id)} 
		                          className={classes.editButton}
		                          size="small"
		                        >
								  Edit
								</Button>
							</Grid>
						  </Grid>
						  }
						</TableCell>
						<TableCell className = {classes.tablecell}>{n.lastName}</TableCell>
						<TableCell className = {classes.tablecell}>{n.firstName}</TableCell>
						<TableCell className = {classes.tablecell}>{n.dobDisplay}</TableCell>
                        <TableCell className = {classes.tablecell}>{n.patientType}</TableCell>
                        <TableCell className = {classes.tablecell}>{n.email}</TableCell>
						<TableCell className = {classes.tablecell}>{n.gender}</TableCell>
						<TableCell className = {classes.tablecell}>{n.state}</TableCell>
						<TableCell className = {classes.tablecell}>{n.country}</TableCell>
						<TableCell className = {classes.tablecell}>{n.dateOfInjuryDisplay}</TableCell>
						<TableCell className = {classes.tablecell}>{n.causeOfInjury}</TableCell>
						<TableCell className = {classes.tablecell}>{n.SISScoreScan1}</TableCell>
						<TableCell className = {classes.tablecell}>{n.TotalVisionScore}</TableCell>
						<TableCell className = {classes.tablecell}>{n.paperWorkSubDateDisplay}</TableCell>
						<TableCell className = {classes.tablecell}>{n.firstDayOfTreatmentDisplay}</TableCell>
					  </TableRow>
					);
				  })}
				</TableBody>
			  </Table>
            </TableContainer>
		    <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={6} align="right">
					<Button 
					  onClick={() => this.prevPageClick()} 
					  variant="contained" 
				      size="small"
					  className={classes.pageButton}
					>
					  Prev Page
					</Button>
				</Grid>
                <Grid item xs={12} sm={6} align="left">
					<Button 
					  onClick={() => this.nextPageClick()} 
					  variant="contained" 
				      size="small"
					  className={classes.pageButton}
					>
					  Next Page
					</Button>
				</Grid>
			</Grid>

		    <Box mt={8}>
		  	    <Copyright />
		    </Box>
            </div>
			);
		}
    }
}

export default withStyles(useStyles)(PatientTable);










import React, { Component } from 'react';
//import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles"

import Copyright from './Copyright';
import colors from './../ColorScheme/Colors';


const useStyles = theme => ({
  container: {
    background: colors.backgroundColor,
	flexGrow: 1,
//    minheight : '100vh',
  },
  paper: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: colors.backgroundColor,
  },
  textfield: {
    color: colors.textColorPlain,
  },
});

class LoggedOut extends Component {
//	constructor(props) {
//		super(props);
//	}

	render() {
      const { classes } = this.props;
	  return (
		<Container component="main" className={classes.container}>
			<Container maxWidth="xs" className={classes.container}>
			  <CssBaseline />
			  <div className={classes.paper}>
				<Typography component="h1" variant="h5" className={classes.textfield}>
				  Logged Out.
				</Typography>
              </div>
			  <Box mt={80}>
				<Copyright />
			  </Box>
			</Container>
		</Container>
	  );
	}
}

export default withStyles(useStyles)(LoggedOut);





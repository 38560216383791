//import React from 'react';


export default function timeConverterForTable(timeObject){
  // If the key exists:
  if (typeof timeObject !== 'undefined') {
	  if ("$date" in timeObject) {
		  const a = new Date(timeObject.$date);
          const localDate = new Date(a.getTime() + a.getTimezoneOffset() * 60000)
		  const year = localDate.getFullYear();
		  const month = localDate.getMonth() + 1;
		  const day = localDate.getDate();
          const time =  month + '-' + day + '-' + year
		  return time;
	  } else {
	  	return ''
		}
    } else {
      return ''
      }
}


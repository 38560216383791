import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import DisplayOnlyTextField from './CustomFields/DisplayOnlyTextField';
import RadioGroupYesNo from './CustomFields/RadioGroupYesNo';
import RadioGroupSymptoms from './CustomFields/RadioGroupSymptoms';
import DateTextField from './CustomFields/DateTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

const physicalList = ['HeadachePostOneYear',
                      'NauseaPostOneYear',
                      'VomitingPostOneYear',
                      'BalanceProblemsPostOneYear',
                      'DizzinessPostOneYear',
                      'VisualProblemsPostOneYear',
                      'FatiguePostOneYear',
                      'SensitivityToLightPostOneYear',
                      'SensitivityToNoisePostOneYear',
                      'NumbnessTinglingPostOneYear',
                   ]

const thinkingList = ['MentalFogPostOneYear',
                      'FeelingSlowedDownPostOneYear',
                      'DifficultyConcentratingPostOneYear',
                      'DifficultyRememberingPostOneYear',
					 ]

const sleepList = ['DrowsinessPostOneYear',
                   'SleepingLessThanUsualPostOneYear',
                   'SleepingMoreThanUsualPostOneYear',
                   'TroubleFallingAsleepPostOneYear',
			      ]

const emotionalList = ['IrritabilityPostOneYear',
                       'SadnessPostOneYear',
                       'NervousnessPostOneYear',
                       'FeelingMoreEmotionalPostOneYear',
			           ]

var totalList = [...physicalList];
totalList.push(...thinkingList);
totalList.push(...sleepList);
totalList.push(...emotionalList);





class PCSSPostOneYear extends Component {
	constructor(props) {
		super(props);

		this.handleChangeNone = this.handleChangeNone.bind(this);
	}

	computeNumberOfSymptoms(list, event) {
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if ((value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else {
				total += 1
			}
		}

		return total
	}

	computePercentOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}

			if ((value !== '0') && (value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else if (value === '0') {
				total += 1
			}
			else {
				totalYes += 1
				total += 1
			}
		}

		const percent = totalYes / Math.max(total, 1) * 100

		return percent
	}

	computeAverageOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if (value === '0') {
				total += 1
			}
			else if (value === '1') {
				totalYes += 1
				total += 1
			}
			else if (value === '2') {
				totalYes += 2
				total += 1
			}
			else if (value === '3') {
				totalYes += 3
				total += 1
			}
			else if (value === '4') {
				totalYes += 4
				total += 1
			}
			else if (value === '5') {
				totalYes += 5
				total += 1
			}
			else if (value === '6') {
				totalYes += 6
				total += 1
			}
		}

		const average = totalYes / Math.max(total, 1)

		return average
	}

    handleChangeNone = event =>{
		// A placeholder to avoid an error.  Does nothing
    }

    handleChange = event =>{
		this.props.handleChange(event);
    }

    handleChangePhysical = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(physicalList, event)
		const percent = this.computePercentOfSymptoms(physicalList, event)
		const average = this.computeAverageOfSymptoms(physicalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfPhysicalSymptomsEndorsedPostOneYear', 
            'PercentOfPhysicalSymptomsEndorsedPostOneYear', 
            'AverageScoreOfPhysicalSymptomsPostOneYear',
            'NumberOfTotalSymptomsEndorsedPostOneYear',
            'PercentOfTotalSymptomsEndorsedPostOneYear', 
            'AverageScoreOfTotalSymptomsPostOneYear',
            'PCSSIndexScorePostOneYear'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeThinking = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(thinkingList, event)
		const percent = this.computePercentOfSymptoms(thinkingList, event)
		const average = this.computeAverageOfSymptoms(thinkingList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfThinkingSymptomsEndorsedPostOneYear', 
            'PercentOfThinkingSymptomsEndorsedPostOneYear', 
            'AverageScoreOfThinkingSymptomsPostOneYear',
            'NumberOfTotalSymptomsEndorsedPostOneYear',
            'PercentOfTotalSymptomsEndorsedPostOneYear', 
            'AverageScoreOfTotalSymptomsPostOneYear',
            'PCSSIndexScorePostOneYear'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeSleep = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(sleepList, event)
		const percent = this.computePercentOfSymptoms(sleepList, event)
		const average = this.computeAverageOfSymptoms(sleepList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfSleepSymptomsEndorsedPostOneYear', 
            'PercentOfSleepSymptomsEndorsedPostOneYear', 
            'AverageScoreOfSleepSymptomsPostOneYear',
            'NumberOfTotalSymptomsEndorsedPostOneYear',
            'PercentOfTotalSymptomsEndorsedPostOneYear', 
            'AverageScoreOfTotalSymptomsPostOneYear',
            'PCSSIndexScorePostOneYear'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeEmotional = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(emotionalList, event)
		const percent = this.computePercentOfSymptoms(emotionalList, event)
		const average = this.computeAverageOfSymptoms(emotionalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfEmotionalSymptomsEndorsedPostOneYear', 
            'PercentOfEmotionalSymptomsEndorsedPostOneYear', 
            'AverageScoreOfEmotionalSymptomsPostOneYear',
            'NumberOfTotalSymptomsEndorsedPostOneYear',
            'PercentOfTotalSymptomsEndorsedPostOneYear', 
            'AverageScoreOfTotalSymptomsPostOneYear',
            'PCSSIndexScorePostOneYear'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

	getNewest(eventName) {
		var value = 0
		if (this.props.patientJSON === '') {
			if (this.props.patientJSONDefault === '') {
			}
			else if (eventName in this.props.patientJSONDefault) {
				value = this.props.patientJSONDefault[eventName];
			}
		}
		else {
			if (eventName in this.props.patientJSON) {
				value = this.props.patientJSON[eventName];
			}
			else {
				if (this.props.patientJSONDefault === '') {
				}
				else if (eventName in this.props.patientJSONDefault) {
					value = this.props.patientJSONDefault[eventName];
				}
			}		
		}

		return value
	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <DateTextField 
            fieldName = "DateOneYearFollowUpSurvey"
            fieldLabel = "Date of One Year Follow Up Survey"
            defaultValue={this.props.patientJSONDefault.DateOneYearFollowUpSurvey} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "HeadachePostOneYear"
            fieldLabel = "Headache - Post One Year"
            defaultValue={this.props.patientJSONDefault.HeadachePostOneYear} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NauseaPostOneYear"
            fieldLabel = "Nausea - Post One Year"
            defaultValue={this.props.patientJSONDefault.NauseaPostOneYear} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VomitingPostOneYear"
            fieldLabel = "Vomiting - Post One Year"
            defaultValue={this.props.patientJSONDefault.VomitingPostOneYear} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "BalanceProblemsPostOneYear"
            fieldLabel = "Balance Problems - Post One Year"
            defaultValue={this.props.patientJSONDefault.BalanceProblemsPostOneYear} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DizzinessPostOneYear"
            fieldLabel = "Dizziness - Post One Year"
            defaultValue={this.props.patientJSONDefault.DizzinessPostOneYear} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VisualProblemsPostOneYear"
            fieldLabel = "Visual Problems - Post One Year"
            defaultValue={this.props.patientJSONDefault.VisualProblemsPostOneYear} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FatiguePostOneYear"
            fieldLabel = "Fatigue - Post One Year"
            defaultValue={this.props.patientJSONDefault.FatiguePostOneYear} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToLightPostOneYear"
            fieldLabel = "Sensitivity to Light - Post One Year"
            defaultValue={this.props.patientJSONDefault.SensitivityToLightPostOneYear} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToNoisePostOneYear"
            fieldLabel = "Sensitivity to Noise - Post One Year"
            defaultValue={this.props.patientJSONDefault.SensitivityToNoisePostOneYear} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NumbnessTinglingPostOneYear"
            fieldLabel = "Numbness or Tingling - Post One Year"
            defaultValue={this.props.patientJSONDefault.NumbnessTinglingPostOneYear} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "MentalFogPostOneYear"
            fieldLabel = "Mental Fog - Post One Year"
            defaultValue={this.props.patientJSONDefault.MentalFogPostOneYear} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingSlowedDownPostOneYear"
            fieldLabel = "Feeling Slowed Down - Post One Year"
            defaultValue={this.props.patientJSONDefault.FeelingSlowedDownPostOneYear} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyConcentratingPostOneYear"
            fieldLabel = "Difficulty Concentrating - Post One Year"
            defaultValue={this.props.patientJSONDefault.DifficultyConcentratingPostOneYear} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyRememberingPostOneYear"
            fieldLabel = "Difficulty Remembering - Post One Year"
            defaultValue={this.props.patientJSONDefault.DifficultyRememberingPostOneYear} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DrowsinessPostOneYear"
            fieldLabel = "Drowsiness - Post One Year"
            defaultValue={this.props.patientJSONDefault.DrowsinessPostOneYear} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingLessThanUsualPostOneYear"
            fieldLabel = "Sleeping Less Than Usual - Post One Year"
            defaultValue={this.props.patientJSONDefault.SleepingLessThanUsualPostOneYear} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingMoreThanUsualPostOneYear"
            fieldLabel = "Sleeping More Than Usual - Post One Year"
            defaultValue={this.props.patientJSONDefault.SleepingMoreThanUsualPostOneYear} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "TroubleFallingAsleepPostOneYear"
            fieldLabel = "Trouble Falling Asleep - Post One Year"
            defaultValue={this.props.patientJSONDefault.TroubleFallingAsleepPostOneYear} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "IrritabilityPostOneYear"
            fieldLabel = "Irritability - Post One Year"
            defaultValue={this.props.patientJSONDefault.IrritabilityPostOneYear} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SadnessPostOneYear"
            fieldLabel = "Sadness - Post One Year"
            defaultValue={this.props.patientJSONDefault.SadnessPostOneYear} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NervousnessPostOneYear"
            fieldLabel = "Nervousness - Post One Year"
            defaultValue={this.props.patientJSONDefault.NervousnessPostOneYear} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingMoreEmotionalPostOneYear"
            fieldLabel = "Feeling More Emotional - Post One Year"
            defaultValue={this.props.patientJSONDefault.FeelingMoreEmotionalPostOneYear} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "PainOtherThanHeadachePostOneYear"
            fieldLabel = "Pain Other Than Headache - Post One Year"
            defaultValue={this.props.patientJSONDefault.PainOtherThanHeadachePostOneYear} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithPhysicalActivityPostOneYear"
	        fieldLabel = "Worsen with Physical Activity - Post One Year"
	        defaultValue={this.props.patientJSONDefault.WorsenWithPhysicalActivityPostOneYear}
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithCognitiveActivityPostOneYear"
	        fieldLabel = "Worsen with Cognitive Activity - Post One Year"
	        defaultValue={this.props.patientJSONDefault.WorsenWithCognitiveActivityPostOneYear} 
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <DisplayOnlyTextField 
	        fieldName = "PCSSIndexScorePostOneYear"
	        fieldLabel = "PCSS Index Score - Post One Year (Calculated)"
	        value = {this.getNewest("PCSSIndexScorePostOneYear").toFixed(2)}
	        handleChange={this.handleChange}
	      />

        </div>
	  );
	}
}

export default withStyles(useStyles)(PCSSPostOneYear);


//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfPhysicalSymptomsEndorsedPostOneYear"
//	        fieldLabel = "Number of Physical Symptoms Endorsed - Post One Year (Calculated)"
//	        value = {this.getNewest("NumberOfPhysicalSymptomsEndorsedPostOneYear")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfPhysicalSymptomsEndorsedPostOneYear"
//	        fieldLabel = "Percent of Physical Symptoms Endorsed - Post One Year (Calculated)"
//	        value = {this.getNewest("PercentOfPhysicalSymptomsEndorsedPostOneYear").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfPhysicalSymptomsPostOneYear"
//	        fieldLabel = "Average Score of Physical Symptoms - Post One Year (Calculated)"
//	        value = {this.getNewest("AverageScoreOfPhysicalSymptomsPostOneYear").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfThinkingSymptomsEndorsedPostOneYear"
//	        fieldLabel = "Number of Thinking Symptoms Endorsed - Post One Year (Calculated)"
//	        value = {this.getNewest("NumberOfThinkingSymptomsEndorsedPostOneYear")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfThinkingSymptomsEndorsedPostOneYear"
//	        fieldLabel = "Percent of Thinking Symptoms Endorsed - Post One Year (Calculated)"
//	        value = {this.getNewest("PercentOfThinkingSymptomsEndorsedPostOneYear").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfThinkingSymptomsPostOneYear"
//	        fieldLabel = "Average Score of Thinking Symptoms - Post One Year (Calculated)"
//	        value = {this.getNewest("AverageScoreOfThinkingSymptomsPostOneYear").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfSleepSymptomsEndorsedPostOneYear"
//	        fieldLabel = "Number of Sleep Symptoms Endorsed - Post One Year (Calculated)"
//	        value = {this.getNewest("NumberOfSleepSymptomsEndorsedPostOneYear")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfSleepSymptomsEndorsedPostOneYear"
//	        fieldLabel = "Percent of Sleep Symptoms Endorsed - Post One Year (Calculated)"
//	        value = {this.getNewest("PercentOfSleepSymptomsEndorsedPostOneYear").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfSleepSymptomsPostOneYear"
//	        fieldLabel = "Average Score of Sleep Symptoms - Post One Year (Calculated)"
//	        value = {this.getNewest("AverageScoreOfSleepSymptomsPostOneYear").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfEmotionalSymptomsEndorsedPostOneYear"
//	        fieldLabel = "Number of Emotional Symptoms Endorsed - Post One Year (Calculated)"
//	        value = {this.getNewest("NumberOfEmotionalSymptomsEndorsedPostOneYear")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfEmotionalSymptomsEndorsedPostOneYear"
//	        fieldLabel = "Percent of Emotional Symptoms Endorsed - Post One Year (Calculated)"
//	        value = {this.getNewest("PercentOfEmotionalSymptomsEndorsedPostOneYear").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfEmotionalSymptomsPostOneYear"
//	        fieldLabel = "Average Score of Emotional Symptoms - Post One Year (Calculated)"
//	        value = {this.getNewest("AverageScoreOfEmotionalSymptomsPostOneYear").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfTotalSymptomsEndorsedPostOneYear"
//	        fieldLabel = "Number of Total Symptoms Endorsed - Post One Year (Calculated)"
//	        value = {this.getNewest("NumberOfTotalSymptomsEndorsedPostOneYear")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfTotalSymptomsEndorsedPostOneYear"
//	        fieldLabel = "Percent of Total Symptoms Endorsed - Post One Year (Calculated)"
//	        value = {this.getNewest("PercentOfTotalSymptomsEndorsedPostOneYear").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfTotalSymptomsPostOneYear"
//	        fieldLabel = "Average Score of All Symptoms - Post One Year (Calculated)"
//	        value = {this.getNewest("AverageScoreOfTotalSymptomsPostOneYear").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />







import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import StandardTextField from './CustomFields/StandardTextField';
import DateTextField from './CustomFields/DateTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

class InjuryInfo extends Component {
//	constructor(props) {
//		super(props);
//	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>

		  <DateTextField 
            fieldName = "dateOfInjury"
            fieldLabel = "Date of Injury"
            defaultValue={this.props.patientJSONDefault.dateOfInjury} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "causeOfInjury"
            fieldLabel = "Cause of Injury"
            defaultValue={this.props.patientJSONDefault.causeOfInjury} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "lossOfConsciousness"
            fieldLabel = "Loss Of Consciousness"
            defaultValue={this.props.patientJSONDefault.lossOfConsciousness} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "injuryDetails1"
            fieldLabel = "Injury Details"
            defaultValue={this.props.patientJSONDefault.injuryDetails1} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "injuryDetails2"
            fieldLabel = "Injury Details - Additional Notes"
            defaultValue={this.props.patientJSONDefault.injuryDetails2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "numberOfInjuries"
            fieldLabel = "Number Of Injuries"
            defaultValue={this.props.patientJSONDefault.numberOfInjuries} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "postInjuryDiagnosisIfAny"
            fieldLabel = "Post Injury Diagnosis, if any"
            defaultValue={this.props.patientJSONDefault.postInjuryDiagnosisIfAny} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "postInjuryTreatmentIfAny"
            fieldLabel = "Post Injury Treatment, if any"
            defaultValue={this.props.patientJSONDefault.postInjuryTreatmentIfAny} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "postInjuryTreatmentDetails"
            fieldLabel = "Post Injury Treatment - Details"
            defaultValue={this.props.patientJSONDefault.postInjuryTreatmentDetails} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "haveYouExpAnyOfTheseInjuries"
            fieldLabel = "Have You Experienced Any of These Injuries?"
            defaultValue={this.props.patientJSONDefault.haveYouExpAnyOfTheseInjuries} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "listSymptoms"
            fieldLabel = "List Symptoms"
            defaultValue={this.props.patientJSONDefault.listSymptoms} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
		  <StandardTextField 
            fieldName = "totalNumberOfConcussions"
            fieldLabel = "Total Number of Concussions"
            defaultValue={this.props.patientJSONDefault.totalNumberOfConcussions} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "exerciseIntoleranceGotWorse"
            fieldLabel = "Exercise Intolerance Got Worse"
            defaultValue={this.props.patientJSONDefault.exerciseIntoleranceGotWorse} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "bloodPressureSymptomsGotWorse"
            fieldLabel = "Blood Pressure, Heart Rate, etc.. Symptoms Got Worse"
            defaultValue={this.props.patientJSONDefault.bloodPressureSymptomsGotWorse} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "gastroIntestionalSymptomsGotWorse"
            fieldLabel = "Gastro-intestional Symptoms Got Worse"
            defaultValue={this.props.patientJSONDefault.gastroIntestionalSymptomsGotWorse} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "hormoneSymptomsGotWorse"
            fieldLabel = "Hormone Symptoms Got Worse"
            defaultValue={this.props.patientJSONDefault.hormoneSymptomsGotWorse} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "primaryPhysicianDuringConcussion"
            fieldLabel = "Primary Physician During Concussion Treatment"
            defaultValue={this.props.patientJSONDefault.primaryPhysicianDuringConcussion} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "primaryPhysicianDuringConcussionClinicName"
            fieldLabel = "Primary Physician During Concussion Treatment - Clinic Name"
            defaultValue={this.props.patientJSONDefault.primaryPhysicianDuringConcussionClinicName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "primaryPhysicianDuringConcussionContactInfo"
            fieldLabel = "Primary Physician During Concussion Treatment - Contact Info"
            defaultValue={this.props.patientJSONDefault.primaryPhysicianDuringConcussionContactInfo} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />         

		  <StandardTextField 
            fieldName = "primaryPhysicianDuringCovid"
            fieldLabel = "Primary Physician During Covid Treatment"
            defaultValue={this.props.patientJSONDefault.primaryPhysicianDuringCovid} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "primaryPhysicianDuringCovidClinicName"
            fieldLabel = "Primary Physician During Covid Treatment - Clinic Name"
            defaultValue={this.props.patientJSONDefault.primaryPhysicianDuringCovidClinicName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
          <StandardTextField 
            fieldName = "primaryPhysicianDuringCovidContactInfo"
            fieldLabel = "Primary Physician During Covid Treatment - Contact Info"
            defaultValue={this.props.patientJSONDefault.primaryPhysicianDuringCovidContactInfo} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />    
        </div>
	  );
	}
}

export default withStyles(useStyles)(InjuryInfo);




import React, { Component} from 'react';
import PatientTable from './../PatientTable/PatientTable';
//import Edit from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import colors from './../ColorScheme/Colors';

import { Auth } from 'aws-amplify';


const useStyles = theme => ({
  root: {
    background: colors.backgroundColor,
  },
  textfield: {
    color: colors.textColorPlain,
  },
  logoutButton: {
	background: colors.buttonColorPlain,
    color: colors.textColorPlain,
    margin: '5px',
  },
  jotformButton: {
	background: colors.buttonColorBold,
    color: colors.textColorBold,
    margin: '5px',
  },
  jotformCovidButton: {
	background: colors.buttonColorBold,
    color: colors.textColorBold,
    margin: '5px',
  },
});

class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentTime: 2,
      isLoaded: true,
    };
  }

  async onSignOutClick() {
	await Auth.currentAuthenticatedUser().then(user => user.signOut());
	this.props.setPage('LoggedOut');
  }

  async uploadNewJotformPatientsOnClick() {
    console.log('uploadNewPatientsFromJotformClicked');
	this.props.setPage("JotformUpload");
  }

//  async uploadNewJotformCovidPatientsOnClick() {
//    console.log('uploadNewCovidPatientsFromJotformClicked');
//	this.props.setPage("JotformCovidUpload");
//  }

  render() {

    const { classes } = this.props;

    if (!this.state.isLoaded) {
      console.log('running for !isLoaded');
      return <div>Loading ... </div>;
    } else {
		return (
		  <div className={classes.root}>
			  <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={6}>
			      <Typography component="h1" variant="h3" className={classes.textfield}>
			        S.P.A.N.O
			      </Typography>
				</Grid>
                <Grid item xs={12} sm={6} align="right">
					<Button 
					  onClick={() => this.onSignOutClick()} 
					  variant="contained" 
				      size="small"
					  className={classes.logoutButton}
                      mr={20}
					>
					  Log out
					</Button>
				</Grid>
			  </Grid>
			  <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={6}>
		          <Typography component="h1" variant="h5" className={classes.textfield}>
				    Searchable Patient Atlas-N-Organizer
			      </Typography>
				</Grid>
                <Grid container spacing={1} direction="column">
		            <Grid item xs={12} sm={6} align="left">
					  <Button 
						onClick={() => this.uploadNewJotformPatientsOnClick()}
						disabled = {false}
						variant="contained" 
						size="small"
						className={classes.jotformButton}
		                mr={20}
					  >
						+Upload Patients from Jotform
					  </Button>
					</Grid>
                </Grid>
			  </Grid>
		      <PatientTable setPage={this.props.setPage} />
		  </div>
		);
    }
  }
}


export default withStyles(useStyles)(Home);
 



//		            <Grid item xs={12} sm={6} align="left">
//					  <Button 
//						onClick={() => this.uploadNewJotformCovidPatientsOnClick()}
//						disabled = {false}
//						variant="contained" 
//						size="small"
//						className={classes.jotformCovidButton}
//		                mr={20}
//					  >
//						+Upload Covid Patients from Jotform
//					  </Button>
//					</Grid>
					
					
					
//  componentDidMount() {
//    const apiUrl = '/time';
//    fetch(apiUrl)
//      .then(response => response.json())
//      .then(data => this.setState({ currentTime: data.time, isLoaded: true }, () => {
//      console.log(this.state.currentTime, 'currentTime');
//      console.log(this.state.isLoaded, 'isLoaded');
//    })); 
//  }



import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import StandardTextField from './CustomFields/StandardTextField';
import DisplayOnlyTextField from './CustomFields/DisplayOnlyTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

class FNCIOne extends Component {
//	constructor(props) {
//		super(props);
//	}

	computeImprovement(event) {
		var pre = 0.0
		var post = 0.0

		// Score 1
		if (event.target.name === 'SISScoreScan1') {
			pre = event.target.value;
		}
		else if (this.props.patientJSON === '') {
			pre = this.props.patientJSONDefault['SISScoreScan1'];
		}			
		else if ('SISScoreScan1' in this.props.patientJSON) {
			pre = this.props.patientJSON['SISScoreScan1'];
		}
		else {
			pre = this.props.patientJSONDefault['SISScoreScan1'];
		}

		// Score 2
		if (event.target.name === 'SISScoreScan2') {
			post = event.target.value;
		}
		else if (this.props.patientJSON === '') {
			post = this.props.patientJSONDefault['SISScoreScan2'];
		}			
		else if ('SISScoreScan1' in this.props.patientJSON) {
			post = this.props.patientJSON['SISScoreScan2'];
		}
		else {
			post = this.props.patientJSONDefault['SISScoreScan2'];
		}		
		
		const improve = (pre - post) / Math.max(pre, 0.01) * 100.0

		return improve
	}

    handleSISScoreChange = async (event) =>{
		event.persist()
		const improve = await this.computeImprovement(event);
		this.props.handlePatientJSONUpdate([event.target.name, 'SISScorePercentImprovement'], [event.target.value, improve]);
    }

    handleChangeNone = event =>{
		// A placeholder to avoid an error.  Does nothing
    }

	getNewest(eventName) {
		var value = 0
		if (this.props.patientJSON === '') {
			if (this.props.patientJSONDefault === '') {
			}
			else if (eventName in this.props.patientJSONDefault) {
				value = this.props.patientJSONDefault[eventName];
			}
		}
		else {
			if (eventName in this.props.patientJSON) {
				value = this.props.patientJSON[eventName];
			}
			else {
				if (this.props.patientJSONDefault === '') {
				}
				else if (eventName in this.props.patientJSONDefault) {
					value = this.props.patientJSONDefault[eventName];
				}
			}		
		}

		return value
	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <StandardTextField 
            fieldName = "SISScoreScan2"
            fieldLabel = "SIS Score - Scan 2"
            defaultValue={this.props.patientJSONDefault.SISScoreScan2} 
            handleChange={this.handleSISScoreChange}
			editable={this.props.editable}
          />

		  <DisplayOnlyTextField 
            fieldName = "SISScorePercentImprovement"
            fieldLabel = "Percent SIS Score Improvement (Calculated)"
            value={this.getNewest("SISScorePercentImprovement")} 
            handleChange={this.handleChangeNone}
          />

		  <StandardTextField 
            fieldName = "attentionalScoreScan2"
            fieldLabel = "Attentional Score - Scan 2"
            defaultValue={this.props.patientJSONDefault.attentionalScoreScan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "subcorticalScoreScan2"
            fieldLabel = "Subcortical Score - Scan 2"
            defaultValue={this.props.patientJSONDefault.subcorticalScoreScan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "visualScoreScan2"
            fieldLabel = "Visual Score - Scan 2"
            defaultValue={this.props.patientJSONDefault.visualScoreScan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "languageScoreScan2"
            fieldLabel = "Language Score - Scan 2"
            defaultValue={this.props.patientJSONDefault.languageScoreScan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "executiveScoreScan2"
            fieldLabel = "Executive Score - Scan 2"
            defaultValue={this.props.patientJSONDefault.executiveScoreScan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_SMFG_Executive_Scan2"
            fieldLabel = "1MRT - SMFG - Executive - Scan 2"
            defaultValue={this.props.patientJSONDefault.mrt_SMFG_Executive_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_IFG_Executive_Scan2"
            fieldLabel = "1MRT - IFG - Executive - Scan 2"
            defaultValue={this.props.patientJSONDefault.mrt_IFG_Executive_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_PMFC_Attentional_Scan2"
            fieldLabel = "1MRT - PMFC - Attentional - Scan 2"
            defaultValue={this.props.patientJSONDefault.mrt_PMFC_Attentional_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_AI_Attentional_Scan2"
            fieldLabel = "1MRT - AI - Attentional - Scan 2"
            defaultValue={this.props.patientJSONDefault.mrt_AI_Attentional_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_SPL_Scan2"
            fieldLabel = "1MRT - SPL - Scan 2"
            defaultValue={this.props.patientJSONDefault.mrt_SPL_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_FIOC_Visual_Scan2"
            fieldLabel = "1MRT - FIOC - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.mrt_FIOC_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_MOG_Visual_Scan2"
            fieldLabel = "1MRT - MOG - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.mrt_MOG_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_T_Subcortical_Scan2"
            fieldLabel = "1MRT - T - Subcortical - Scan 2"
            defaultValue={this.props.patientJSONDefault.mrt_T_Subcortical_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "mrt_BG_Subcortical_Scan2"
            fieldLabel = "1MRT - BG - Subcortical - Scan 2"
            defaultValue={this.props.patientJSONDefault.mrt_BG_Subcortical_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_SMFG_Executive_Scan2"
            fieldLabel = "2TMT - SMGF - Executive - Scan 2"
            defaultValue={this.props.patientJSONDefault.tmt_SMFG_Executive_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_IFG_Executive_Scan2"
            fieldLabel = "2TMT - IFG - Executive - Scan 2"
            defaultValue={this.props.patientJSONDefault.tmt_IFG_Executive_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_PMFC_Attentional_Scan2"
            fieldLabel = "2TMT - PMFC - Attentional - Scan 2"
            defaultValue={this.props.patientJSONDefault.tmt_PMFC_Attentional_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_SPL_Scan2"
            fieldLabel = "2TMT - SPL - Scan 2"
            defaultValue={this.props.patientJSONDefault.tmt_SPL_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_FIOC_Visual_Scan2"
            fieldLabel = "2TMT - FIOC - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.tmt_FIOC_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_MOG_Visual_Scan2"
            fieldLabel = "2TMT - MOG - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.tmt_MOG_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "tmt_SC_Subcortical_Scan2"
            fieldLabel = "2TMT - SC - Subcortical - Scan 2"
            defaultValue={this.props.patientJSONDefault.tmt_SC_Subcortical_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_RFG_Visual_Scan2"
            fieldLabel = "3PNT - RFG - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.pnt_RFG_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_LFG_Visual_Scan2"
            fieldLabel = "3PNT - LFG - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.pnt_LFG_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_RIOG_Visual_Scan2"
            fieldLabel = "3PNT - RIOG - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.pnt_RIOG_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_LIOG_Visual_Scan2"
            fieldLabel = "3PNT - LIOG - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.pnt_LIOG_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_RMOG_Visual_Scan2"
            fieldLabel = "3PNT - RMOG - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.pnt_RMOG_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_LMOG_Visual_Scan2"
            fieldLabel = "3PNT - LMOG - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.pnt_LMOG_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "pnt_PMFC_Attentional_Scan2"
            fieldLabel = "3PNT - PMFC - Attentional - Scan 2"
            defaultValue={this.props.patientJSONDefault.pnt_PMFC_Attentional_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_RH_Subcortical_Scan2"
            fieldLabel = "4FMT - RH - Subcortical - Scan 2"
            defaultValue={this.props.patientJSONDefault.fmt_RH_Subcortical_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_LF_Subcortical_Scan2"
            fieldLabel = "4FMT - LF - Subcortical - Scan 2"
            defaultValue={this.props.patientJSONDefault.fmt_LF_Subcortical_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_RMFG_Executive_Scan2"
            fieldLabel = "4FMT - RMGF - Executive - Scan 2"
            defaultValue={this.props.patientJSONDefault.fmt_RMFG_Executive_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_LMFG_Executive_Scan2"
            fieldLabel = "4FMT - LMFG - Executive - Scan 2"
            defaultValue={this.props.patientJSONDefault.fmt_LMFG_Executive_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_PMFC_Attentional_Scan2"
            fieldLabel = "4FMT - PMFC - Attentional - Scan 2"
            defaultValue={this.props.patientJSONDefault.fmt_PMFC_Attentional_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_RIOG_Visual_Scan2"
            fieldLabel = "4FMT - RIOG - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.fmt_RIOG_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "fmt_LIOC_Visual_Scan2"
            fieldLabel = "4FMT - LIOC - Visual - Scan 2"
            defaultValue={this.props.patientJSONDefault.fmt_LIOC_Visual_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_LIPG_Language_Scan2"
            fieldLabel = "5VMT - LIPG - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vmt_LIPG_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_LPA_Language_Scan2"
            fieldLabel = "5VMT - LPA - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vmt_LPA_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_LIFGPO_Language_Scan2"
            fieldLabel = "5VMT - LIFGPO - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vmt_LIFGPO_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_SMFG_Language_Scan2"
            fieldLabel = "5VMT - SMFG - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vmt_SMFG_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_RIFC_Language_Scan2"
            fieldLabel = "5vmt - RIFC - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vmt_RIFC_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_PMFG_Attentional_Scan2"
            fieldLabel = "5vmt - PMFG - Attentional - Scan 2"
            defaultValue={this.props.patientJSONDefault.vmt_PMFG_Attentional_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vmt_LPTL_Language_Scan2"
            fieldLabel = "5vmt - LPTL - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vmt_LPTL_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LIPG_Language_Scan2"
            fieldLabel = "6vft - LIPG - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vft_LIPG_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LIFGPO_Language_Scan2"
            fieldLabel = "6vft - LIFGPO - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vft_LIFGPO_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LBG_Subcortical_Scan2"
            fieldLabel = "6vft - LBG - Subcortical - Scan 2"
            defaultValue={this.props.patientJSONDefault.vft_LBG_Subcortical_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LIFGPT_Language_Scan2"
            fieldLabel = "6vft - LIFGPT - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vft_LIFGPT_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_MPSMA_Attentional_Scan2"
            fieldLabel = "6vrt - MPSMA - Attentional - Scan 2"
            defaultValue={this.props.patientJSONDefault.vft_MPSMA_Attentional_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_DAC_Attentional_Scan2"
            fieldLabel = "6vft - DAC - Attentional - Scan 2"
            defaultValue={this.props.patientJSONDefault.vft_DAC_Attentional_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LIP_Language_Scan2"
            fieldLabel = "6vft - LIP - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vft_LIP_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "vft_LPTL_Language_Scan2"
            fieldLabel = "6vft - LPTL - Language - Scan 2"
            defaultValue={this.props.patientJSONDefault.vft_LPTL_Language_Scan2} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

        </div>
	  );
	}
}

export default withStyles(useStyles)(FNCIOne);




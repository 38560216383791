import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import RadioGroupVision from './CustomFields/RadioGroupVision';
import DisplayOnlyTextField from './CustomFields/DisplayOnlyTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

const visionList = ['EyesightClarity',
                    'EyeDiscomfort',
                    'HeadachesOrDizzinessFromUsingEyes',
                    'EyeFatigue',
                    'PullingAroundTheEyes',
                    'DoublingMovesInAndOutOfFocus',
                    'LightSensitvityNormalIndoorLighting',
                    'LightSensitivityIndoorFluorescentLighting',
                    'DepthPerceptionClumsiness',
                    'DepthPerceptionLackOfConfidenceWalking',
                    'SideVisionDistorted',
                    'WhatLooksStraightAheadIsnt',
                    'AvoidCrowds',
                    'ShortAttentionSpan',
                    'DifficultyReading',
                    'PoorReadingComprehension',
                    'ConfusionOfWords',
                    'LosePlaceReading',
                   ]

class VisionScreen extends Component {
	constructor(props) {
		super(props);

		this.handleChangeNone = this.handleChangeNone.bind(this);
	}

	computeVisionTotal(list, event) {
		var total = 0
		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}

			if (value === 'seldom(1)') {
				total += 1;
			}
			else if (value === 'occasionally(2)') {
				total += 2;
			}
			else if (value === 'frequently(3)') {
				total += 3;
			}
			else if (value === 'always(4)') {
				total += 4;
			}
			else {
				total += 0;
			}
		}

		return total
	}


    handleChange = async (event) =>{
		event.persist(); // need this and the next because of the synthetic event issue
		const total = await this.computeVisionTotal(visionList, event);
		this.props.handlePatientJSONUpdate([event.target.name, 'TotalVisionScore'], [event.target.value, total]);
    }

    handleChangeNone = event =>{
		// A placeholder to avoid an error.  Does nothing
    }

	getNewest(eventName) {
		var value = 0
		if (this.props.patientJSON === '') {
			if (this.props.patientJSONDefault === '') {
			}
			else if (eventName in this.props.patientJSONDefault) {
				value = this.props.patientJSONDefault[eventName];
			}
		}
		else {
			if (eventName in this.props.patientJSON) {
				value = this.props.patientJSON[eventName];
			}
			else {
				if (this.props.patientJSONDefault === '') {
				}
				else if (eventName in this.props.patientJSONDefault) {
					value = this.props.patientJSONDefault[eventName];
				}
			}		
		}

		return value
	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <RadioGroupVision 
            fieldName = "EyesightClarity"
            fieldLabel = "Eyesight Clarity"
            defaultValue={this.props.patientJSONDefault.EyesightClarity} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "EyeDiscomfort"
            fieldLabel = "Eye Discomfort"
            defaultValue={this.props.patientJSONDefault.EyeDiscomfort} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "HeadachesOrDizzinessFromUsingEyes"
            fieldLabel = "Headaches or Dizziness From Using Eyes"
            defaultValue={this.props.patientJSONDefault.HeadachesOrDizzinessFromUsingEyes} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "EyeFatigue"
            fieldLabel = "Eye Fatigue"
            defaultValue={this.props.patientJSONDefault.EyeFatigue} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "PullingAroundTheEyes"
            fieldLabel = "Pulling Around the Eyes"
            defaultValue={this.props.patientJSONDefault.PullingAroundTheEyes} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "DoublingMovesInAndOutOfFocus"
            fieldLabel = "Object Moves in and out of Focus"
            defaultValue={this.props.patientJSONDefault.DoublingMovesInAndOutOfFocus} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "LightSensitvityNormalIndoorLighting"
            fieldLabel = "Light Sensitivity in Regular Indoor Lighting "
            defaultValue={this.props.patientJSONDefault.LightSensitvityNormalIndoorLighting} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "LightSensitivityIndoorFluorescentLighting"
            fieldLabel = "Light Sensitivity in Fluorescent Indoor Lighting"
            defaultValue={this.props.patientJSONDefault.LightSensitivityIndoorFluorescentLighting} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "DepthPerceptionClumsiness"
            fieldLabel = "Clumsiness"
            defaultValue={this.props.patientJSONDefault.DepthPerceptionClumsiness} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "DepthPerceptionLackOfConfidenceWalking"
            fieldLabel = "Lack of Confidence Walking"
            defaultValue={this.props.patientJSONDefault.DepthPerceptionLackOfConfidenceWalking} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "SideVisionDistorted"
            fieldLabel = "Peripherial Vision is Distorted"
            defaultValue={this.props.patientJSONDefault.SideVisionDistorted} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "WhatLooksStraightAheadIsnt"
            fieldLabel = "What Looks Like It Is Straight Ahead Is Not"
            defaultValue={this.props.patientJSONDefault.WhatLooksStraightAheadIsnt} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "AvoidCrowds"
            fieldLabel = "Try to Avoid Crowds"
            defaultValue={this.props.patientJSONDefault.AvoidCrowds} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "ShortAttentionSpan"
            fieldLabel = "Short Attention Span"
            defaultValue={this.props.patientJSONDefault.ShortAttentionSpan} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "DifficultyReading"
            fieldLabel = "Difficulty Reading "
            defaultValue={this.props.patientJSONDefault.DifficultyReading} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "PoorReadingComprehension"
            fieldLabel = "Poor Reading Comprehension"
            defaultValue={this.props.patientJSONDefault.PoorReadingComprehension} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "ConfusionOfWords"
            fieldLabel = "Confuse Words"
            defaultValue={this.props.patientJSONDefault.ConfusionOfWords} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupVision 
            fieldName = "LosePlaceReading"
            fieldLabel = "Lose Place When Reading"
            defaultValue={this.props.patientJSONDefault.LosePlaceReading} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <DisplayOnlyTextField 
            fieldName = "TotalVisionScore"
            fieldLabel = "Vision Total Score (Calculated)"
            value={this.getNewest("TotalVisionScore")}
			handleChange={this.handleChangeNone}
          />

        </div>
	  );
	}
}

export default withStyles(useStyles)(VisionScreen);




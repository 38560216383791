import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import StandardTextField from './CustomFields/StandardTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

class LegalInfo extends Component {
//	constructor(props) {
//		super(props);
//	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <StandardTextField 
            fieldName = "litigationStatus"
            fieldLabel = "Litigation Status"
            defaultValue={this.props.patientJSONDefault.litigationStatus} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "attorneyFirstName"
            fieldLabel = "Attorney - First Name"
            defaultValue={this.props.patientJSONDefault.attorneyFirstName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "attorneyLastName"
            fieldLabel = "Attorney - Last Name"
            defaultValue={this.props.patientJSONDefault.attorneyLastName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "attorneyPhone"
            fieldLabel = "Attorney - Phone"
            defaultValue={this.props.patientJSONDefault.attorneyPhone} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "lawFirmContactFirst"
            fieldLabel = "Law Firm Contract - First Name"
            defaultValue={this.props.patientJSONDefault.lawFirmContactFirst} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "lawFirmContactLast"
            fieldLabel = "Law Firm Contact - Last Name"
            defaultValue={this.props.patientJSONDefault.lawFirmContactLast} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "lawFirmContactPhone"
            fieldLabel = "Law Firm Contact - Phone"
            defaultValue={this.props.patientJSONDefault.lawFirmContactPhone} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "lawFirmContactEmail"
            fieldLabel = "Law Firm Contact - Email"
            defaultValue={this.props.patientJSONDefault.lawFirmContactEmail} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "paralegalNameFirst"
            fieldLabel = "Paralegal - First Name"
            defaultValue={this.props.patientJSONDefault.paralegalNameFirst} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "paralegalNameLast"
            fieldLabel = "Paralegal - Last Name"
            defaultValue={this.props.patientJSONDefault.paralegalNameLast} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "paralegalPhoneNumber"
            fieldLabel = "Paralegal - Phone Number"
            defaultValue={this.props.patientJSONDefault.paralegalPhoneNumber} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "paralegalEmail"
            fieldLabel = "Paralegal - Email"
            defaultValue={this.props.patientJSONDefault.paralegalEmail} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
        </div>
	  );
	}
}

export default withStyles(useStyles)(LegalInfo);




import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import DisplayOnlyTextField from './CustomFields/DisplayOnlyTextField';
import RadioGroupYesNo from './CustomFields/RadioGroupYesNo';
import RadioGroupSymptoms from './CustomFields/RadioGroupSymptoms';
import DateTextField from './CustomFields/DateTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

const physicalList = ['HeadachePostSixMonth',
                      'NauseaPostSixMonth',
                      'VomitingPostSixMonth',
                      'BalanceProblemsPostSixMonth',
                      'DizzinessPostSixMonth',
                      'VisualProblemsPostSixMonth',
                      'FatiguePostSixMonth',
                      'SensitivityToLightPostSixMonth',
                      'SensitivityToNoisePostSixMonth',
                      'NumbnessTinglingPostSixMonth',
                   ]

const thinkingList = ['MentalFogPostSixMonth',
                      'FeelingSlowedDownPostSixMonth',
                      'DifficultyConcentratingPostSixMonth',
                      'DifficultyRememberingPostSixMonth',
					 ]

const sleepList = ['DrowsinessPostSixMonth',
                   'SleepingLessThanUsualPostSixMonth',
                   'SleepingMoreThanUsualPostSixMonth',
                   'TroubleFallingAsleepPostSixMonth',
			      ]

const emotionalList = ['IrritabilityPostSixMonth',
                       'SadnessPostSixMonth',
                       'NervousnessPostSixMonth',
                       'FeelingMoreEmotionalPostSixMonth',
			           ]

var totalList = [...physicalList];
totalList.push(...thinkingList);
totalList.push(...sleepList);
totalList.push(...emotionalList);





class PCSSPostSixMonth extends Component {
	constructor(props) {
		super(props);

		this.handleChangeNone = this.handleChangeNone.bind(this);
	}

	computeNumberOfSymptoms(list, event) {
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if ((value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else {
				total += 1
			}
		}

		return total
	}

	computePercentOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}

			if ((value !== '0') && (value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else if (value === '0') {
				total += 1
			}
			else {
				totalYes += 1
				total += 1
			}
		}

		const percent = totalYes / Math.max(total, 1) * 100

		return percent
	}

	computeAverageOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if (value === '0') {
				total += 1
			}
			else if (value === '1') {
				totalYes += 1
				total += 1
			}
			else if (value === '2') {
				totalYes += 2
				total += 1
			}
			else if (value === '3') {
				totalYes += 3
				total += 1
			}
			else if (value === '4') {
				totalYes += 4
				total += 1
			}
			else if (value === '5') {
				totalYes += 5
				total += 1
			}
			else if (value === '6') {
				totalYes += 6
				total += 1
			}
		}

		const average = totalYes / Math.max(total, 1)

		return average
	}

    handleChangeNone = event =>{
		// A placeholder to avoid an error.  Does nothing
    }

    handleChange = event =>{
		this.props.handleChange(event);
    }

    handleChangePhysical = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(physicalList, event)
		const percent = this.computePercentOfSymptoms(physicalList, event)
		const average = this.computeAverageOfSymptoms(physicalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfPhysicalSymptomsEndorsedPostSixMonth', 
            'PercentOfPhysicalSymptomsEndorsedPostSixMonth', 
            'AverageScoreOfPhysicalSymptomsPostSixMonth',
            'NumberOfTotalSymptomsEndorsedPostSixMonth',
            'PercentOfTotalSymptomsEndorsedPostSixMonth', 
            'AverageScoreOfTotalSymptomsPostSixMonth',
            'PCSSIndexScorePostSixMonth'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeThinking = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(thinkingList, event)
		const percent = this.computePercentOfSymptoms(thinkingList, event)
		const average = this.computeAverageOfSymptoms(thinkingList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfThinkingSymptomsEndorsedPostSixMonth', 
            'PercentOfThinkingSymptomsEndorsedPostSixMonth', 
            'AverageScoreOfThinkingSymptomsPostSixMonth',
            'NumberOfTotalSymptomsEndorsedPostSixMonth',
            'PercentOfTotalSymptomsEndorsedPostSixMonth', 
            'AverageScoreOfTotalSymptomsPostSixMonth',
            'PCSSIndexScorePostSixMonth'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeSleep = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(sleepList, event)
		const percent = this.computePercentOfSymptoms(sleepList, event)
		const average = this.computeAverageOfSymptoms(sleepList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfSleepSymptomsEndorsedPostSixMonth', 
            'PercentOfSleepSymptomsEndorsedPostSixMonth', 
            'AverageScoreOfSleepSymptomsPostSixMonth',
            'NumberOfTotalSymptomsEndorsedPostSixMonth',
            'PercentOfTotalSymptomsEndorsedPostSixMonth', 
            'AverageScoreOfTotalSymptomsPostSixMonth',
            'PCSSIndexScorePostSixMonth'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeEmotional = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(emotionalList, event)
		const percent = this.computePercentOfSymptoms(emotionalList, event)
		const average = this.computeAverageOfSymptoms(emotionalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfEmotionalSymptomsEndorsedPostSixMonth', 
            'PercentOfEmotionalSymptomsEndorsedPostSixMonth', 
            'AverageScoreOfEmotionalSymptomsPostSixMonth',
            'NumberOfTotalSymptomsEndorsedPostSixMonth',
            'PercentOfTotalSymptomsEndorsedPostSixMonth', 
            'AverageScoreOfTotalSymptomsPostSixMonth',
            'PCSSIndexScorePostSixMonth'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

	getNewest(eventName) {
		var value = 0
		if (this.props.patientJSON === '') {
			if (this.props.patientJSONDefault === '') {
			}
			else if (eventName in this.props.patientJSONDefault) {
				value = this.props.patientJSONDefault[eventName];
			}
		}
		else {
			if (eventName in this.props.patientJSON) {
				value = this.props.patientJSON[eventName];
			}
			else {
				if (this.props.patientJSONDefault === '') {
				}
				else if (eventName in this.props.patientJSONDefault) {
					value = this.props.patientJSONDefault[eventName];
				}
			}		
		}

		return value
	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <DateTextField 
            fieldName = "DateSixMonthFollowUpSurvey"
            fieldLabel = "Date of 6 Month Follow Up Survey"
            defaultValue={this.props.patientJSONDefault.DateSixMonthFollowUpSurvey} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "HeadachePostSixMonth"
            fieldLabel = "Headache - Post Six Month"
            defaultValue={this.props.patientJSONDefault.HeadachePostSixMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NauseaPostSixMonth"
            fieldLabel = "Nausea - Post Six Month"
            defaultValue={this.props.patientJSONDefault.NauseaPostSixMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VomitingPostSixMonth"
            fieldLabel = "Vomiting - Post Six Month"
            defaultValue={this.props.patientJSONDefault.VomitingPostSixMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "BalanceProblemsPostSixMonth"
            fieldLabel = "Balance Problems - Post Six Month"
            defaultValue={this.props.patientJSONDefault.BalanceProblemsPostSixMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DizzinessPostSixMonth"
            fieldLabel = "Dizziness - Post Six Month"
            defaultValue={this.props.patientJSONDefault.DizzinessPostSixMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VisualProblemsPostSixMonth"
            fieldLabel = "Visual Problems - Post Six Month"
            defaultValue={this.props.patientJSONDefault.VisualProblemsPostSixMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FatiguePostSixMonth"
            fieldLabel = "Fatigue - Post Six Month"
            defaultValue={this.props.patientJSONDefault.FatiguePostSixMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToLightPostSixMonth"
            fieldLabel = "Sensitivity to Light - Post Six Month"
            defaultValue={this.props.patientJSONDefault.SensitivityToLightPostSixMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToNoisePostSixMonth"
            fieldLabel = "Sensitivity to Noise - Post Six Month"
            defaultValue={this.props.patientJSONDefault.SensitivityToNoisePostSixMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NumbnessTinglingPostSixMonth"
            fieldLabel = "Numbness or Tingling - Post Six Month"
            defaultValue={this.props.patientJSONDefault.NumbnessTinglingPostSixMonth} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "MentalFogPostSixMonth"
            fieldLabel = "Mental Fog - Post Six Month"
            defaultValue={this.props.patientJSONDefault.MentalFogPostSixMonth} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingSlowedDownPostSixMonth"
            fieldLabel = "Feeling Slowed Down - Post Six Month"
            defaultValue={this.props.patientJSONDefault.FeelingSlowedDownPostSixMonth} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyConcentratingPostSixMonth"
            fieldLabel = "Difficulty Concentrating - Post Six Month"
            defaultValue={this.props.patientJSONDefault.DifficultyConcentratingPostSixMonth} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyRememberingPostSixMonth"
            fieldLabel = "Difficulty Remembering - Post Six Month"
            defaultValue={this.props.patientJSONDefault.DifficultyRememberingPostSixMonth} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DrowsinessPostSixMonth"
            fieldLabel = "Drowsiness - Post Six Month"
            defaultValue={this.props.patientJSONDefault.DrowsinessPostSixMonth} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingLessThanUsualPostSixMonth"
            fieldLabel = "Sleeping Less Than Usual - Post Six Month"
            defaultValue={this.props.patientJSONDefault.SleepingLessThanUsualPostSixMonth} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingMoreThanUsualPostSixMonth"
            fieldLabel = "Sleeping More Than Usual - Post Six Month"
            defaultValue={this.props.patientJSONDefault.SleepingMoreThanUsualPostSixMonth} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "TroubleFallingAsleepPostSixMonth"
            fieldLabel = "Trouble Falling Asleep - Post Six Month"
            defaultValue={this.props.patientJSONDefault.TroubleFallingAsleepPostSixMonth} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "IrritabilityPostSixMonth"
            fieldLabel = "Irritability - Post Six Month"
            defaultValue={this.props.patientJSONDefault.IrritabilityPostSixMonth} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SadnessPostSixMonth"
            fieldLabel = "Sadness - Post Six Month"
            defaultValue={this.props.patientJSONDefault.SadnessPostSixMonth} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NervousnessPostSixMonth"
            fieldLabel = "Nervousness - Post Six Month"
            defaultValue={this.props.patientJSONDefault.NervousnessPostSixMonth} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingMoreEmotionalPostSixMonth"
            fieldLabel = "Feeling More Emotional - Post Six Month"
            defaultValue={this.props.patientJSONDefault.FeelingMoreEmotionalPostSixMonth} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "PainOtherThanHeadachePostSixMonth"
            fieldLabel = "Pain Other Than Headache - Post Six Month"
            defaultValue={this.props.patientJSONDefault.PainOtherThanHeadachePostSixMonth} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithPhysicalActivityPostSixMonth"
	        fieldLabel = "Worsen with Physical Activity - Post Six Month"
	        defaultValue={this.props.patientJSONDefault.WorsenWithPhysicalActivityPostSixMonth}
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithCognitiveActivityPostSixMonth"
	        fieldLabel = "Worsen with Cognitive Activity - Post Six Month"
	        defaultValue={this.props.patientJSONDefault.WorsenWithCognitiveActivityPostSixMonth} 
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <DisplayOnlyTextField 
	        fieldName = "PCSSIndexScorePostSixMonth"
	        fieldLabel = "PCSS Index Score - Post Six Month (Calculated)"
	        value = {this.getNewest("PCSSIndexScorePostSixMonth").toFixed(2)}
	        handleChange={this.handleChange}
	      />

        </div>
	  );
	}
}

export default withStyles(useStyles)(PCSSPostSixMonth);




//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfPhysicalSymptomsEndorsedPostSixMonth"
//	        fieldLabel = "Number of Physical Symptoms Endorsed - Post Six Month (Calculated)"
//	        value = {this.getNewest("NumberOfPhysicalSymptomsEndorsedPostSixMonth")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfPhysicalSymptomsEndorsedPostSixMonth"
//	        fieldLabel = "Percent of Physical Symptoms Endorsed - Post Six Month (Calculated)"
//	        value = {this.getNewest("PercentOfPhysicalSymptomsEndorsedPostSixMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfPhysicalSymptomsPostSixMonth"
//	        fieldLabel = "Average Score of Physical Symptoms - Post Six Month (Calculated)"
//	        value = {this.getNewest("AverageScoreOfPhysicalSymptomsPostSixMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfThinkingSymptomsEndorsedPostSixMonth"
//	        fieldLabel = "Number of Thinking Symptoms Endorsed - Post Six Month (Calculated)"
//	        value = {this.getNewest("NumberOfThinkingSymptomsEndorsedPostSixMonth")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfThinkingSymptomsEndorsedPostSixMonth"
//	        fieldLabel = "Percent of Thinking Symptoms Endorsed - Post Six Month (Calculated)"
//	        value = {this.getNewest("PercentOfThinkingSymptomsEndorsedPostSixMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfThinkingSymptomsPostSixMonth"
//	        fieldLabel = "Average Score of Thinking Symptoms - Post Six Month (Calculated)"
//	        value = {this.getNewest("AverageScoreOfThinkingSymptomsPostSixMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfSleepSymptomsEndorsedPostSixMonth"
//	        fieldLabel = "Number of Sleep Symptoms Endorsed - Post Six Month (Calculated)"
//	        value = {this.getNewest("NumberOfSleepSymptomsEndorsedPostSixMonth")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfSleepSymptomsEndorsedPostSixMonth"
//	        fieldLabel = "Percent of Sleep Symptoms Endorsed - Post Six Month (Calculated)"
//	        value = {this.getNewest("PercentOfSleepSymptomsEndorsedPostSixMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfSleepSymptomsPostSixMonth"
//	        fieldLabel = "Average Score of Sleep Symptoms - Post Six Month (Calculated)"
//	        value = {this.getNewest("AverageScoreOfSleepSymptomsPostSixMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfEmotionalSymptomsEndorsedPostSixMonth"
//	        fieldLabel = "Number of Emotional Symptoms Endorsed - Post Six Month (Calculated)"
//	        value = {this.getNewest("NumberOfEmotionalSymptomsEndorsedPostSixMonth")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfEmotionalSymptomsEndorsedPostSixMonth"
//	        fieldLabel = "Percent of Emotional Symptoms Endorsed - Post Six Month (Calculated)"
//	        value = {this.getNewest("PercentOfEmotionalSymptomsEndorsedPostSixMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfEmotionalSymptomsPostSixMonth"
//	        fieldLabel = "Average Score of Emotional Symptoms - Post Six Month (Calculated)"
//	        value = {this.getNewest("AverageScoreOfEmotionalSymptomsPostSixMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfTotalSymptomsEndorsedPostSixMonth"
//	        fieldLabel = "Number of Total Symptoms Endorsed - Post Six Month (Calculated)"
//	        value = {this.getNewest("NumberOfTotalSymptomsEndorsedPostSixMonth")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfTotalSymptomsEndorsedPostSixMonth"
//	        fieldLabel = "Percent of Total Symptoms Endorsed - Post Six Month (Calculated)"
//	        value = {this.getNewest("PercentOfTotalSymptomsEndorsedPostSixMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfTotalSymptomsPostSixMonth"
//	        fieldLabel = "Average Score of All Symptoms - Post Six Month (Calculated)"
//	        value = {this.getNewest("AverageScoreOfTotalSymptomsPostSixMonth").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />





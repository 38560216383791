import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import StandardTextField from './CustomFields/StandardTextField';
import DateTextField from './CustomFields/DateTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

class PatientInfo extends Component {
//	constructor(props) {
//		super(props);
//	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <StandardTextField 
            fieldName = "firstName"
            fieldLabel = "First Name"
            defaultValue={this.props.patientJSONDefault.firstName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "lastName"
            fieldLabel = "Last Name"
            defaultValue={this.props.patientJSONDefault.lastName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "patientType"
            fieldLabel = "Patient Type"
            defaultValue={this.props.patientJSONDefault.patientType} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "email"
            fieldLabel = "Email"
            defaultValue={this.props.patientJSONDefault.email} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "phone"
            fieldLabel = "Phone"
            defaultValue={this.props.patientJSONDefault.phone} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <DateTextField 
            fieldName = "dob"
            fieldLabel = "Date of Birth"
            defaultValue={this.props.patientJSONDefault.dob} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "age"
            fieldLabel = "Age"
            defaultValue={this.props.patientJSONDefault.age} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
                    
		  <StandardTextField 
            fieldName = "gender"
            fieldLabel = "Gender"
            defaultValue={this.props.patientJSONDefault.gender} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "address"
            fieldLabel = "Address"
            defaultValue={this.props.patientJSONDefault.address} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "city"
            fieldLabel = "City"
            defaultValue={this.props.patientJSONDefault.city} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "state"
            fieldLabel = "State"
            defaultValue={this.props.patientJSONDefault.state} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "zipCode"
            fieldLabel = "Zip Code"
            defaultValue={this.props.patientJSONDefault.zipCode} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "country"
            fieldLabel = "Country"
            defaultValue={this.props.patientJSONDefault.country} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <DateTextField 
            fieldName = "paperworkSubDate"
            fieldLabel = "Paperwork Submission Date - Latest Forms"
            defaultValue={this.props.patientJSONDefault.paperworkSubDate} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <DateTextField 
            fieldName = "paperworkSubDate_Intake"
            fieldLabel = "Intake Paperwork Submission Date"
            defaultValue={this.props.patientJSONDefault.paperworkSubDate_Intake} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
		  <DateTextField 
            fieldName = "paperworkSubDate_PCSConsult"
            fieldLabel = "PCS Consult Paperwork Submission Date"
            defaultValue={this.props.patientJSONDefault.paperworkSubDate_PCSConsult} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <DateTextField 
            fieldName = "paperworkSubDate_CovidConsult"
            fieldLabel = "Covid Consult Paperwork Submission Date"
            defaultValue={this.props.patientJSONDefault.paperworkSubDate_CovidConsult} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <DateTextField 
            fieldName = "paperworkSubDate_PreTreatment"
            fieldLabel = "Pre Treatment Paperwork Submission Date"
            defaultValue={this.props.patientJSONDefault.paperworkSubDate_PreTreatment} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
                              
		  <StandardTextField 
            fieldName = "referralContact"
            fieldLabel = "Referral Contact"
            defaultValue={this.props.patientJSONDefault.referralContact} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "referralType"
            fieldLabel = "Referral Type"
            defaultValue={this.props.patientJSONDefault.referralType} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "referralSource"
            fieldLabel = "Referral Source"
            defaultValue={this.props.patientJSONDefault.referralSource} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "militaryService"
            fieldLabel = "Miliary Service or First Responder"
            defaultValue={this.props.patientJSONDefault.militaryService} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "parentFirstName"
            fieldLabel = "Parent First Name"
            defaultValue={this.props.patientJSONDefault.parentFirstName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "parentLastName"
            fieldLabel = "Parent Last Name"
            defaultValue={this.props.patientJSONDefault.parentLastName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "parentName"
            fieldLabel = "Parent Name"
            defaultValue={this.props.patientJSONDefault.parentName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
		  <StandardTextField 
            fieldName = "parentEmail"
            fieldLabel = "Parent Email"
            defaultValue={this.props.patientJSONDefault.parentEmail} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "parentPhone"
            fieldLabel = "Parent Phone"
            defaultValue={this.props.patientJSONDefault.parentPhone} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <DateTextField 
            fieldName = "DateOfFirstDayOfTreatment"
            fieldLabel = "Date of First Day of Treatment"
            defaultValue={this.props.patientJSONDefault.DateOfFirstDayOfTreatment} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "emergencyContactFirstName"
            fieldLabel = "Emergency Contact First Name"
            defaultValue={this.props.patientJSONDefault.emergencyContactFirstName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "emergencyContactLastName"
            fieldLabel = "Emergency Contact Last Name"
            defaultValue={this.props.patientJSONDefault.emergencyContactLastName} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "emergencyContactRelationship"
            fieldLabel = "Emergency Contact Relationship"
            defaultValue={this.props.patientJSONDefault.emergencyContactRelationship} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "emergencyContactPhone"
            fieldLabel = "Emergency Contact Phone"
            defaultValue={this.props.patientJSONDefault.emergencyContactPhone} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "emergencyContactEmail"
            fieldLabel = "Emergency Contact Email"
            defaultValue={this.props.patientJSONDefault.emergencyContactEmail} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <StandardTextField 
            fieldName = "levelOfInterestInCFX"
            fieldLabel = "What is your Interest in CFX?"
            defaultValue={this.props.patientJSONDefault.levelOfInterestInCFX} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />
          
        </div>
	  );
	}
}

export default withStyles(useStyles)(PatientInfo);




import React, { Component } from 'react';
//import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PatientInfo from './PatientInfo';
import InjuryInfo from './InjuryInfo';
import MedicalHistory from './MedicalHistory';
import LegalInfo from './LegalInfo';
import FNCISafety from './FNCISafety';
import VisionScreen from './VisionScreen';
import PCSSPre from './PCSSPre';
import PCSSPost from './PCSSPost';
import PCSSPostOneWeek from './PCSSPostOneWeek';
import PCSSPostThreeMonth from './PCSSPostThreeMonth';
import PCSSPostSixMonth from './PCSSPostSixMonth';
import PCSSPostOneYear from './PCSSPostOneYear';
import CovidPre from './CovidPre';
import FNCIOne from './FNCIOne';
import FNCITwo from './FNCITwo';
import FNCIThree from './FNCIThree';
import FNCIFour from './FNCIFour';
import TreatmentInfo from './TreatmentInfo';
import OldColumns from './OldColumns';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  accordion: {
//    width: "500px",
    background: colors.backgroundColor,
    border: '1px solid ' + colors.textColorBold,

//    minheight : '100vh',
  },
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },
  textfieldAccordion: {
    color: colors.textColorBold,
  },
  textfield: {
    color: colors.textColorPlain,
  },
});

class PatientBoxes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
		}
	}

	handleAccordionChange = (panel) => (event, isExpanded) => {
//		setExpanded(isExpanded ? panel : false);
		this.setState({ expanded: isExpanded ? panel : false })
	};

	render() {
      const { classes } = this.props;

	  return (
       <div>
        <Accordion 
           expanded={this.state.expanded === 'patientInfo'} 
           onChange={this.handleAccordionChange('patientInfo')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="patientInfobh-content"
            id="patientInfobh-header"
          >
            <Typography className={classes.textfieldAccordion}>Patient Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PatientInfo 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'injuryInfo'} 
           onChange={this.handleAccordionChange('injuryInfo')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="injuryInfobh-content"
            id="injuryInfobh-header"
          >
            <Typography className={classes.textfieldAccordion}>Injury Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InjuryInfo 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'medicalHistory'} 
           onChange={this.handleAccordionChange('medicalHistory')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="medicalHistorybh-content"
            id="medicalHistorybh-header"
          >
            <Typography className={classes.textfieldAccordion}>Medical History</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MedicalHistory 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'legalInfo'} 
           onChange={this.handleAccordionChange('legalInfo')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="legalInfobh-content"
            id="legalInfobh-header"
          >
            <Typography className={classes.textfieldAccordion}>Legal Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LegalInfo 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'fNCISafety'} 
           onChange={this.handleAccordionChange('fNCISafety')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="fNCISafetybh-content"
            id="fNCISafetybh-header"
          >
            <Typography className={classes.textfieldAccordion}>FNCI Safety</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FNCISafety 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'visionScreen'} 
           onChange={this.handleAccordionChange('visionScreen')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="visionScreenbh-content"
            id="visionScreenbh-header"
          >
            <Typography className={classes.textfieldAccordion}>Vision Screen</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <VisionScreen 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  handlePatientJSONUpdate={this.props.handlePatientJSONUpdate}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'PCSSPre'} 
           onChange={this.handleAccordionChange('PCSSPre')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="pcssprebh-content"
            id="pcssprebh-header"
          >
            <Typography className={classes.textfieldAccordion}>PCSS - Pre-Treatment</Typography>
          </AccordionSummary>
          <AccordionDetails>
	        <PCSSPre 
	          patientJSON={this.props.patientJSON} 
	          patientJSONDefault={this.props.patientJSONDefault} 
	          handleChange={this.props.handleChange}
			  handlePatientJSONUpdate={this.props.handlePatientJSONUpdate}
			  editable={this.props.editable}
	        /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'PCSSPost'} 
           onChange={this.handleAccordionChange('PCSSPost')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="pcsspostbh-content"
            id="pcsspostbh-header"
          >
            <Typography className={classes.textfieldAccordion}>PCSS - Post-Treatment</Typography>
          </AccordionSummary>
          <AccordionDetails>
	        <PCSSPost 
	          patientJSON={this.props.patientJSON} 
	          patientJSONDefault={this.props.patientJSONDefault} 
	          handleChange={this.props.handleChange}
			  handlePatientJSONUpdate={this.props.handlePatientJSONUpdate}
			  editable={this.props.editable}
	        /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'PCSSPostOneWeek'} 
           onChange={this.handleAccordionChange('PCSSPostOneWeek')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="pcsspostoneweekbh-content"
            id="pcsspostoneweekbh-header"
          >
            <Typography className={classes.textfieldAccordion}>PCSS - One Week Post-Treatment</Typography>
          </AccordionSummary>
          <AccordionDetails>
	        <PCSSPostOneWeek 
	          patientJSON={this.props.patientJSON} 
	          patientJSONDefault={this.props.patientJSONDefault} 
	          handleChange={this.props.handleChange}
			  handlePatientJSONUpdate={this.props.handlePatientJSONUpdate}
			  editable={this.props.editable}
	        /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'PCSSPostThreeMonth'} 
           onChange={this.handleAccordionChange('PCSSPostThreeMonth')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="pcsspostthreemonthbh-content"
            id="pcsspostthreemonthbh-header"
          >
            <Typography className={classes.textfieldAccordion}>PCSS - 3 Months Post-Treatment</Typography>
          </AccordionSummary>
          <AccordionDetails>
	        <PCSSPostThreeMonth
	          patientJSON={this.props.patientJSON} 
	          patientJSONDefault={this.props.patientJSONDefault} 
	          handleChange={this.props.handleChange}
			  handlePatientJSONUpdate={this.props.handlePatientJSONUpdate}
			  editable={this.props.editable}
	        /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'PCSSPostSixMonth'} 
           onChange={this.handleAccordionChange('PCSSPostSixMonth')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="pcsspostsixmonthbh-content"
            id="pcsspostsixmonthbh-header"
          >
            <Typography className={classes.textfieldAccordion}>PCSS - 6 Months Post-Treatment</Typography>
          </AccordionSummary>
          <AccordionDetails>
	        <PCSSPostSixMonth
	          patientJSON={this.props.patientJSON} 
	          patientJSONDefault={this.props.patientJSONDefault} 
	          handleChange={this.props.handleChange}
			  handlePatientJSONUpdate={this.props.handlePatientJSONUpdate}
			  editable={this.props.editable}
	        /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'PCSSPostOneYear'} 
           onChange={this.handleAccordionChange('PCSSPostOneYear')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="pcsspostoneyearbh-content"
            id="pcsspostoneyearbh-header"
          >
            <Typography className={classes.textfieldAccordion}>PCSS - 1 Year Post-Treatment</Typography>
          </AccordionSummary>
          <AccordionDetails>
	        <PCSSPostOneYear
	          patientJSON={this.props.patientJSON} 
	          patientJSONDefault={this.props.patientJSONDefault} 
	          handleChange={this.props.handleChange}
			  handlePatientJSONUpdate={this.props.handlePatientJSONUpdate}
			  editable={this.props.editable}
	        /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'CovidPre'} 
           onChange={this.handleAccordionChange('CovidPre')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="covidprebh-content"
            id="covidprebh-header"
          >
            <Typography className={classes.textfieldAccordion}>Covid - Pre-Treatment</Typography>
          </AccordionSummary>
          <AccordionDetails>
	        <CovidPre 
	          patientJSON={this.props.patientJSON} 
	          patientJSONDefault={this.props.patientJSONDefault} 
	          handleChange={this.props.handleChange}
			  handlePatientJSONUpdate={this.props.handlePatientJSONUpdate}
			  editable={this.props.editable}
	        /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'fncione'} 
           onChange={this.handleAccordionChange('fncione')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="fncionebh-content"
            id="fncionebh-header"
          >
            <Typography className={classes.textfieldAccordion}>fNCI 1</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FNCIOne 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  handlePatientJSONUpdate={this.props.handlePatientJSONUpdate}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'fnci2'} 
           onChange={this.handleAccordionChange('fnci2')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="fnci2bh-content"
            id="fnci2bh-header"
          >
            <Typography className={classes.textfieldAccordion}>fNCI 2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FNCITwo 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  handlePatientJSONUpdate={this.props.handlePatientJSONUpdate}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'fnci3'} 
           onChange={this.handleAccordionChange('fnci3')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="fnci3bh-content"
            id="fnci3bh-header"
          >
            <Typography className={classes.textfieldAccordion}>fNCI 3</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FNCIThree 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'fnci4'} 
           onChange={this.handleAccordionChange('fnci4')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="fnci4bh-content"
            id="fnci4bh-header"
          >
            <Typography className={classes.textfieldAccordion}>fNCI 4</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FNCIFour
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'treatmentInfo'} 
           onChange={this.handleAccordionChange('treatmentInfo')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="treatmentInfobh-content"
            id="treatmentInfobh-header"
          >
            <Typography className={classes.textfieldAccordion}>Treatment Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TreatmentInfo 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

        <Accordion 
           expanded={this.state.expanded === 'oldColumns'} 
           onChange={this.handleAccordionChange('oldColumns')}
           className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.textfieldAccordion} />}
            aria-controls="oldCategoriesbh-content"
            id="oldCategoriesbh-header"
          >
            <Typography className={classes.textfieldAccordion}>Old Columns</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OldColumns 
              patientJSON={this.props.patientJSON} 
              patientJSONDefault={this.props.patientJSONDefault} 
              handleChange={this.props.handleChange}
			  editable={this.props.editable}
            /> 
          </AccordionDetails>
        </Accordion>

       </div>
	  );
	}
}

export default withStyles(useStyles)(PatientBoxes);









import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles"

import Copyright from './../BoilerPlate/Copyright';
import PatientBoxes from './PatientBoxes/PatientBoxes';
import colors from './../ColorScheme/Colors';


//function updatePatientData(patientJSON, patientJSONID) {
//	
//	let patientJSONCopy = {...patientJSON};
//	delete patientJSONCopy._id;

//	var patientsApiUrl = '/api/patients';
//    const patientData = {
//        method: 'PUT',
//        headers: { 'Content-Type': 'application/json' },
//        body: JSON.stringify(patientJSONCopy
//                             ),
//    };

//	var params = {searchID: patientJSONID, 
//                 }

//	let query = Object.keys(params)
//	             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
//	             .join('&');

//	patientsApiUrl = patientsApiUrl + '?' + query

//	console.log(patientsApiUrl, 'patientsApiUrl in put request');


//    fetch(patientsApiUrl, patientData)
//        .then(response => response.json())
//}

//const useStyles = makeStyles((theme) => ({
//  paper: {
//    marginTop: theme.spacing(8),
//    display: 'flex',
//    flexDirection: 'column',
//    alignItems: 'center',
//  },
//  form: {
//    width: '100%', // Fix IE 11 issue.
//    marginTop: theme.spacing(1),
//  },
//  submit: {
//    margin: theme.spacing(3, 0, 2),
//  },
//  cancel: {
//    margin: theme.spacing(3, 0, 2),
//  },
//}));

const useStyles = theme => ({
  container: {
    background: colors.backgroundColor,
	flexGrow: 1,
//    minheight : '100vh',
  },
  paper: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: colors.backgroundColor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
//    marginTop: theme.spacing(1),
    background: colors.backgroundColor,
  },
  submit: {
	background: colors.buttonColorBold,
    color: colors.backgroundColor,
  },
  cancel: {
	background: colors.buttonColorPlain,
    color: colors.backgroundColor,
  },
  deletebutton: {
	background: 'red',
    color: colors.backgroundColor,
  },
  textfield: {
    color: colors.textColorPlain,
  },
});

class EditPatient extends Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handlePatientJSONUpdate = this.handlePatientJSONUpdate.bind(this);
		this.state = { patientJSON: '',
                       patientJSONDefault: '',
			           isLoaded: false,
					   submitStatus: '',
					   uploadErrorMessage: '',
        };
	}

    handleChange = (event) =>{
		var dummyProperty = {...this.state.patientJSON};  // a trick to set the state of nested jsons 
		dummyProperty[event.target.name] = event.target.value
        this.setState({ patientJSON:dummyProperty })

//		console.log('handleChange', this.state.patientJSON);
	}

    handlePatientJSONUpdate = (keys, values) => {
		var dummyProperty = {...this.state.patientJSON};  // a trick to set the state of nested jsons 
		for (var i = 0; i < keys.length; i++) {
			dummyProperty[keys[i]] = values[i]
		}

		this.setState({ patientJSON:dummyProperty })

//		// eslint-disable-next-line
//		this.state.patientJSON = dummyProperty  // mutate without reloading
////        this.setState({ patientJSON:dummyProperty })

//		console.log('patientJSON in handlePatientJSONUpdate', this.state.patientJSON);
    }

	async updatePatientData(patientJSONID) {
	
		let patientJSONCopy = {...this.state.patientJSON};
		delete patientJSONCopy._id;

		var patientsApiUrl = '/api/patients';
		const patientData = {
		    method: 'PUT',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify(patientJSONCopy
		                         ),
		};

		var params = {searchID: patientJSONID, 
		             }

		let query = Object.keys(params)
			         .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
			         .join('&');

		patientsApiUrl = patientsApiUrl + '?' + query

		console.log(patientsApiUrl, 'patientsApiUrl in put request');

		await fetch(patientsApiUrl, patientData).then((response) => {
		  if (response.ok) {
			this.setState({ submitStatus:'ok'});
		  } else {
			this.setState({ submitStatus:'error'});
			throw new Error('Something went wrong');
		  }
		})
		.catch((error) => {
		  console.log(error);
		});
	}

	handleSubmit = async event => {
		await this.updatePatientData(this.props.patientJSONID)

		console.log('Updating patient data');
		console.log('patientJSON', this.state.patientJSON);

		if (this.state.submitStatus === 'ok') {
			this.props.setPage("Home");
		}
		else {
			// Nada, stay here
			this.setState({ uploadErrorMessage:' (Upload Error! Check the dates and retry.)'});
		}
	}

	handleCancel = event => {
		console.log('Done Viewing');
		this.props.setPage("Home")
	}

	handleDelete = event => {
        console.log('deleteClicked with id', this.props.patientJSONID);

		this.props.setPage("DeletePatient", this.props.patientJSONID)

//		console.log('Deleting patient data');
//		deletePatientData(this.props.patientJSONID)
//		this.props.setPage("Home")
	}

	componentDidMount() {
		var patientsApiUrl = '/api/patients';

		var params = {searchID: this.props.patientJSONID, 
                     }

		let query = Object.keys(params)
		             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		             .join('&');

		patientsApiUrl = patientsApiUrl + '?' + query



		fetch(patientsApiUrl)
		  .then(response => response.json())
          .then(data => this.setState({ patientJSON: '', 
                                        patientJSONDefault: data[0], 
                                        isLoaded: true }, () => {
//		  console.log(this.state.patientJSON, 'patientJSON');
//		  console.log(this.state.patientJSONDefault, 'patientJSONDefault');
//		  console.log(this.state.isLoaded, 'isLoaded');
		})); 
	}

	render() {
		const { classes } = this.props;

		if (!this.state.isLoaded) {
		  return <div>Loading... </div>;
		} else {
		  return (
			<Container component="main" className={classes.container}>
				<Container maxWidth="md">
				  <CssBaseline />
				  <div className={classes.paper}>
					<Typography component="h1" variant="h5" className={classes.textfield}>
					  Update Patient Data
					</Typography>
					<form className={classes.form} noValidate>
		              <div>
						  <PatientBoxes 
                             patientJSON={this.state.patientJSON} 
                             patientJSONDefault={this.state.patientJSONDefault} 
                             handleChange={this.handleChange}
							 handlePatientJSONUpdate={this.handlePatientJSONUpdate}
							 editable={true}
                          />
						  <Button
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={this.handleSubmit}
						  >
							Update {this.state.uploadErrorMessage}
						  </Button>
						  <Button
							fullWidth
							variant="contained"
							color="default"
							className={classes.cancel}
							onClick={this.handleCancel}
						  >
							Cancel
						  </Button>
						  <Button
							fullWidth
							variant="contained"
							color="secondary"
							className={classes.deletebutton}
							onClick={this.handleDelete}
						  >
							Permanently Delete
						  </Button>
		              </div>
					</form>
				  </div>
				  <Box mt={8}>
					<Copyright />
				  </Box>
				</Container>
			</Container>
		  );
        }
	}
}

export default withStyles(useStyles)(EditPatient);





import React, { Component} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles"

import Copyright from './../BoilerPlate/Copyright';
import PatientBoxes from './PatientBoxes/PatientBoxes';
import colors from './../ColorScheme/Colors';


//async function submitPatientData(patientJSON, submitStatus) {

//	const patientsApiUrl = '/api/patients';
//    const patientData = {
//        method: 'POST',
//        headers: { 'Content-Type': 'application/json' },
//        body: JSON.stringify(patientJSON
//                             ),
//    };

////	var responseHold = '';
////	var errorHold = '';

////    fetch(patientsApiUrl, patientData)
////        .then(response => response.json())
////		.then(response => console.log('response', response))

//    await fetch(patientsApiUrl, patientData).then((response) => {
//	  if (response.ok) {
//		submitStatus = 'ok';
//	  } else {
//		throw new Error('Something went wrong');
//	  }
//	})
////	.then((responseJson) => {
////	  // Do something with the response
////	})
//	.catch((error) => {
//	  console.log(error);
//	  submitStatus = 'error';
//	});

//}


//const useStyles = makeStyles((theme) => ({
//  paper: {
//    marginTop: theme.spacing(8),
//    display: 'flex',
//    flexDirection: 'column',
//    alignItems: 'center',
//  },
//  avatar: {
//    margin: theme.spacing(1),
//    backgroundColor: theme.palette.secondary.main,
//  },
//  form: {
//    width: '100%', // Fix IE 11 issue.
//    marginTop: theme.spacing(1),
//  },
//  submit: {
//    margin: theme.spacing(3, 0, 2),
//  },
//  cancel: {
//    margin: theme.spacing(3, 0, 2),
//  },
//}));

const useStyles = theme => ({
  container: {
    background: colors.backgroundColor,
	flexGrow: 1,
//    minheight : '100vh',
  },
  paper: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: colors.backgroundColor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
//    marginTop: theme.spacing(1),
    background: colors.backgroundColor,
  },
  submit: {
	background: colors.buttonColorBold,
    color: colors.backgroundColor,
  },
  cancel: {
	background: colors.buttonColorPlain,
    color: colors.backgroundColor,
  },
  deletebutton: {
	background: 'red',
    color: colors.backgroundColor,
  },
  textfield: {
    color: colors.textColorPlain,
  },
});


class NewPatient extends Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.state = { patientJSON: '',
                       patientJSONDefault: '',
					   submitStatus: '',
					   uploadErrorMessage: '',
        };
	}

    handleChange = event =>{
		var dummyProperty = {...this.state.patientJSON};  // a trick to set the state of nested jsons 
		dummyProperty[event.target.name] = event.target.value;
        this.setState({ patientJSON:dummyProperty });
    }

    handlePatientJSONUpdate = (keys, values) => {
		var dummyProperty = {...this.state.patientJSON};  // a trick to set the state of nested jsons 
		for (var i = 0; i < keys.length; i++) {
			dummyProperty[keys[i]] = values[i]
		}

		this.setState({ patientJSON:dummyProperty })

//		// eslint-disable-next-line
//		this.state.patientJSON = dummyProperty  // mutate without reloading
////        this.setState({ patientJSON:dummyProperty })

//		console.log('patientJSON in handlePatientJSONUpdate', this.state.patientJSON);
    }

	async submitPatientData() {
		const patientsApiUrl = '/api/patients';
		const patientData = {
		    method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify(this.state.patientJSON
		                         ),
		};

		await fetch(patientsApiUrl, patientData).then((response) => {
		  if (response.ok) {
			this.setState({ submitStatus:'ok'});
		  } else {
			this.setState({ submitStatus:'error'});
			throw new Error('Something went wrong');
		  }
		})
		.catch((error) => {
		  console.log(error);
		});
	}

	handleSubmit = async event => {
		await this.submitPatientData()
		console.log('Submitting patient data');
		console.log('submitStatus', this.state.submitStatus);
		if (this.state.submitStatus === 'ok') {
			this.props.setPage("Home");
		}
		else {
			// Nada, stay here
			this.setState({ uploadErrorMessage:' (Upload Error! Check the dates and retry.)'});
		}
	}

	handleCancel = event => {
		console.log('Cancelling');
		this.props.setPage("Home");
	}

	render() {
      const { classes } = this.props;

	  return (
		<Container component="main" className={classes.container}>
			<Container maxWidth="md">
			  <CssBaseline />
			  <div className={classes.paper}>
				<Typography component="h1" variant="h5" className={classes.textfield}>
				  Enter New Patient
				</Typography>
				<form className={classes.form} noValidate>
				  <PatientBoxes 
                    patientJSON={this.state.patientJSON} 
                    patientJSONDefault={this.state.patientJSONDefault} 
                    handleChange={this.handleChange}
					handlePatientJSONUpdate={this.handlePatientJSONUpdate}
					editable={true}
                  />
				  <Button

					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={this.handleSubmit}
				  >
					Upload {this.state.uploadErrorMessage}
				  </Button>
				  <Button
					type="cancel"
					fullWidth
					variant="contained"
					color="default"
					className={classes.cancel}
					onClick={this.handleCancel}
				  >
					Cancel
				  </Button>
				</form>
			  </div>
			  <Box mt={8}>
				<Copyright />
			  </Box>
			</Container>
		</Container>
	  );
	}
}

export default withStyles(useStyles)(NewPatient);




import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import DisplayOnlyTextField from './CustomFields/DisplayOnlyTextField';
import RadioGroupYesNo from './CustomFields/RadioGroupYesNo';
import RadioGroupSymptoms from './CustomFields/RadioGroupSymptoms';
import DateTextField from './CustomFields/DateTextField';

import colors from './../../ColorScheme/Colors';


const useStyles = theme => ({
  searchcell: {
	color: colors.textColorPlain,

//    height: "40px",
//    width: "200px",
//    margin: "0 0 0 0",
//    float: "right",
    "& .MuiOutlinedInput-root": {
        "& fieldset": { 
//            borderRadius: "20px",
            borderColor: colors.textColorPlain,
        },
	},
//    "&.Mui-focused fieldset": {
//        borderColor: colors.textColorBold,
//        borderWidth: "2px",
//    },

//    border: 'solid 1px ' + colors.textColorPlain,
  },

  textfield: {
    color: colors.textColorPlain,
  },
});

const physicalList = ['HeadachePostOneWeek',
                      'NauseaPostOneWeek',
                      'VomitingPostOneWeek',
                      'BalanceProblemsPostOneWeek',
                      'DizzinessPostOneWeek',
                      'VisualProblemsPostOneWeek',
                      'FatiguePostOneWeek',
                      'SensitivityToLightPostOneWeek',
                      'SensitivityToNoisePostOneWeek',
                      'NumbnessTinglingPostOneWeek',
                   ]

const thinkingList = ['MentalFogPostOneWeek',
                      'FeelingSlowedDownPostOneWeek',
                      'DifficultyConcentratingPostOneWeek',
                      'DifficultyRememberingPostOneWeek',
					 ]

const sleepList = ['DrowsinessPostOneWeek',
                   'SleepingLessThanUsualPostOneWeek',
                   'SleepingMoreThanUsualPostOneWeek',
                   'TroubleFallingAsleepPostOneWeek',
			      ]

const emotionalList = ['IrritabilityPostOneWeek',
                       'SadnessPostOneWeek',
                       'NervousnessPostOneWeek',
                       'FeelingMoreEmotionalPostOneWeek',
			           ]

var totalList = [...physicalList];
totalList.push(...thinkingList);
totalList.push(...sleepList);
totalList.push(...emotionalList);



class PCSSPostOneWeek extends Component {
	constructor(props) {
		super(props);

		this.handleChangeNone = this.handleChangeNone.bind(this);
	}

	computeNumberOfSymptoms(list, event) {
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if ((value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else {
				total += 1
			}
		}

		return total
	}

	computePercentOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}

			if ((value !== '0') && (value !== '1') && (value !== '2') && (value !== '3') && (value !== '4') && (value !== '5') && (value !== '6')) {
			}
			else if (value === '0') {
				total += 1
			}
			else {
				totalYes += 1
				total += 1
			}
		}

		const percent = totalYes / Math.max(total, 1) * 100

		return percent
	}

	computeAverageOfSymptoms(list, event) {
		var totalYes = 0
		var total = 0

		for (var i = 0; i < list.length; i++) {
			var value = ''

			// Get the value from the right source
			if (event.target.name === list[i]) {
				value = event.target.value;
			}
			else if (this.props.patientJSON === '') {
				value = this.props.patientJSONDefault[list[i]];
			}			
			else if (list[i] in this.props.patientJSON) {
				value = this.props.patientJSON[list[i]];
			}
			else {
				value = this.props.patientJSONDefault[list[i]];
			}


			if (value === '0') {
				total += 1
			}
			else if (value === '1') {
				totalYes += 1
				total += 1
			}
			else if (value === '2') {
				totalYes += 2
				total += 1
			}
			else if (value === '3') {
				totalYes += 3
				total += 1
			}
			else if (value === '4') {
				totalYes += 4
				total += 1
			}
			else if (value === '5') {
				totalYes += 5
				total += 1
			}
			else if (value === '6') {
				totalYes += 6
				total += 1
			}
		}

		const average = totalYes / Math.max(total, 1)

		return average
	}

    handleChangeNone = event =>{
		// A placeholder to avoid an error.  Does nothing
    }

    handleChange = event =>{
		this.props.handleChange(event);
    }

    handleChangePhysical = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(physicalList, event)
		const percent = this.computePercentOfSymptoms(physicalList, event)
		const average = this.computeAverageOfSymptoms(physicalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfPhysicalSymptomsEndorsedPostOneWeek', 
            'PercentOfPhysicalSymptomsEndorsedPostOneWeek', 
            'AverageScoreOfPhysicalSymptomsPostOneWeek',
            'NumberOfTotalSymptomsEndorsedPostOneWeek',
            'PercentOfTotalSymptomsEndorsedPostOneWeek', 
            'AverageScoreOfTotalSymptomsPostOneWeek',
            'PCSSIndexScorePostOneWeek'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeThinking = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(thinkingList, event)
		const percent = this.computePercentOfSymptoms(thinkingList, event)
		const average = this.computeAverageOfSymptoms(thinkingList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfThinkingSymptomsEndorsedPostOneWeek', 
            'PercentOfThinkingSymptomsEndorsedPostOneWeek', 
            'AverageScoreOfThinkingSymptomsPostOneWeek',
            'NumberOfTotalSymptomsEndorsedPostOneWeek',
            'PercentOfTotalSymptomsEndorsedPostOneWeek', 
            'AverageScoreOfTotalSymptomsPostOneWeek',
            'PCSSIndexScorePostOneWeek'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeSleep = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(sleepList, event)
		const percent = this.computePercentOfSymptoms(sleepList, event)
		const average = this.computeAverageOfSymptoms(sleepList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfSleepSymptomsEndorsedPostOneWeek', 
            'PercentOfSleepSymptomsEndorsedPostOneWeek', 
            'AverageScoreOfSleepSymptomsPostOneWeek',
            'NumberOfTotalSymptomsEndorsedPostOneWeek',
            'PercentOfTotalSymptomsEndorsedPostOneWeek', 
            'AverageScoreOfTotalSymptomsPostOneWeek',
            'PCSSIndexScorePost'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

    handleChangeEmotional = event =>{
		event.persist()
		const number = this.computeNumberOfSymptoms(emotionalList, event)
		const percent = this.computePercentOfSymptoms(emotionalList, event)
		const average = this.computeAverageOfSymptoms(emotionalList, event)

		const numberTotal = this.computeNumberOfSymptoms(totalList, event)
		const percentTotal = this.computePercentOfSymptoms(totalList, event)
		const averageTotal = this.computeAverageOfSymptoms(totalList, event)

		const pcssScore = percentTotal * averageTotal / 6.0

		this.props.handlePatientJSONUpdate(
          [event.target.name, 
            'NumberOfEmotionalSymptomsEndorsedPostOneWeek', 
            'PercentOfEmotionalSymptomsEndorsedPostOneWeek', 
            'AverageScoreOfEmotionalSymptomsPostOneWeek',
            'NumberOfTotalSymptomsEndorsedPostOneWeek',
            'PercentOfTotalSymptomsEndorsedPostOneWeek', 
            'AverageScoreOfTotalSymptomsPostOneWeek',
            'PCSSIndexScorePostOneWeek'
          ], 
          [event.target.value, 
            number, 
            percent, 
            average, 
            numberTotal, 
            percentTotal, 
            averageTotal,
            pcssScore
          ]
        );
    }

	getNewest(eventName) {
		var value = 0
		if (this.props.patientJSON === '') {
			if (this.props.patientJSONDefault === '') {
			}
			else if (eventName in this.props.patientJSONDefault) {
				value = this.props.patientJSONDefault[eventName];
			}
		}
		else {
			if (eventName in this.props.patientJSON) {
				value = this.props.patientJSON[eventName];
			}
			else {
				if (this.props.patientJSONDefault === '') {
				}
				else if (eventName in this.props.patientJSONDefault) {
					value = this.props.patientJSONDefault[eventName];
				}
			}		
		}

		return value;
	}

	render() {
//      const { classes } = this.props;

	  return (
        <div>
		  <DateTextField 
            fieldName = "DateOneWeekFollowUpSurvey"
            fieldLabel = "Date of 1 Week Follow Up Survey"
            defaultValue={this.props.patientJSONDefault.DateOneWeekFollowUpSurvey} 
            handleChange={this.props.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "HeadachePostOneWeek"
            fieldLabel = "Headache - Post One Week"
            defaultValue={this.props.patientJSONDefault.HeadachePostOneWeek} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NauseaPostOneWeek"
            fieldLabel = "Nausea - Post One Week"
            defaultValue={this.props.patientJSONDefault.NauseaPostOneWeek} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VomitingPostOneWeek"
            fieldLabel = "Vomiting - Post One Week"
            defaultValue={this.props.patientJSONDefault.VomitingPostOneWeek} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "BalanceProblemsPostOneWeek"
            fieldLabel = "Balance Problems - Post One Week"
            defaultValue={this.props.patientJSONDefault.BalanceProblemsPostOneWeek} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DizzinessPostOneWeek"
            fieldLabel = "Dizziness - Post One Week"
            defaultValue={this.props.patientJSONDefault.DizzinessPostOneWeek} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "VisualProblemsPostOneWeek"
            fieldLabel = "Visual Problems - Post One Week"
            defaultValue={this.props.patientJSONDefault.VisualProblemsPostOneWeek} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FatiguePostOneWeek"
            fieldLabel = "Fatigue - Post One Week"
            defaultValue={this.props.patientJSONDefault.FatiguePostOneWeek} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToLightPostOneWeek"
            fieldLabel = "Sensitivity to Light - Post One Week"
            defaultValue={this.props.patientJSONDefault.SensitivityToLightPostOneWeek} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SensitivityToNoisePostOneWeek"
            fieldLabel = "Sensitivity to Noise - Post One Week"
            defaultValue={this.props.patientJSONDefault.SensitivityToNoisePostOneWeek} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NumbnessTinglingPostOneWeek"
            fieldLabel = "Numbness or Tingling - Post One Week"
            defaultValue={this.props.patientJSONDefault.NumbnessTinglingPostOneWeek} 
            handleChange={this.handleChangePhysical}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "MentalFogPostOneWeek"
            fieldLabel = "Mental Fog - Post One Week"
            defaultValue={this.props.patientJSONDefault.MentalFogPostOneWeek} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingSlowedDownPostOneWeek"
            fieldLabel = "Feeling Slowed Down - Post One Week"
            defaultValue={this.props.patientJSONDefault.FeelingSlowedDownPostOneWeek} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyConcentratingPostOneWeek"
            fieldLabel = "Difficulty Concentrating - Post One Week"
            defaultValue={this.props.patientJSONDefault.DifficultyConcentratingPostOneWeek} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DifficultyRememberingPostOneWeek"
            fieldLabel = "Difficulty Remembering - Post One Week"
            defaultValue={this.props.patientJSONDefault.DifficultyRememberingPostOneWeek} 
            handleChange={this.handleChangeThinking}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "DrowsinessPostOneWeek"
            fieldLabel = "Drowsiness - Post One Week"
            defaultValue={this.props.patientJSONDefault.DrowsinessPostOneWeek} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingLessThanUsualPostOneWeek"
            fieldLabel = "Sleeping Less Than Usual - Post One Week"
            defaultValue={this.props.patientJSONDefault.SleepingLessThanUsualPostOneWeek} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SleepingMoreThanUsualPostOneWeek"
            fieldLabel = "Sleeping More Than Usual - Post One Week"
            defaultValue={this.props.patientJSONDefault.SleepingMoreThanUsualPostOneWeek} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "TroubleFallingAsleepPostOneWeek"
            fieldLabel = "Trouble Falling Asleep - Post One Week"
            defaultValue={this.props.patientJSONDefault.TroubleFallingAsleepPostOneWeek} 
            handleChange={this.handleChangeSleep}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "IrritabilityPostOneWeek"
            fieldLabel = "Irritability - Post One Week"
            defaultValue={this.props.patientJSONDefault.IrritabilityPostOneWeek} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "SadnessPostOneWeek"
            fieldLabel = "Sadness - Post One Week"
            defaultValue={this.props.patientJSONDefault.SadnessPostOneWeek} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "NervousnessPostOneWeek"
            fieldLabel = "Nervousness - Post One Week"
            defaultValue={this.props.patientJSONDefault.NervousnessPostOneWeek} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "FeelingMoreEmotionalPostOneWeek"
            fieldLabel = "Feeling More Emotional - Post One Week"
            defaultValue={this.props.patientJSONDefault.FeelingMoreEmotionalPostOneWeek} 
            handleChange={this.handleChangeEmotional}
			editable={this.props.editable}
          />

		  <RadioGroupSymptoms 
            fieldName = "PainOtherThanHeadachePostOneWeek"
            fieldLabel = "Pain Other Than Headache - Post One Week"
            defaultValue={this.props.patientJSONDefault.PainOtherThanHeadachePostOneWeek} 
            handleChange={this.handleChange}
			editable={this.props.editable}
          />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithPhysicalActivityPostOneWeek"
	        fieldLabel = "Worsen with Physical Activity - Post One Week"
	        defaultValue={this.props.patientJSONDefault.WorsenWithPhysicalActivityPostOneWeek}
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <RadioGroupYesNo 
	        fieldName = "WorsenWithCognitiveActivityPostOneWeek"
	        fieldLabel = "Worsen with Cognitive Activity - Post One Week"
	        defaultValue={this.props.patientJSONDefault.WorsenWithCognitiveActivityPostOneWeek} 
	        handleChange={this.handleChange}
			editable={this.props.editable}
	      />

		  <DisplayOnlyTextField 
	        fieldName = "PCSSIndexScorePostOneWeek"
	        fieldLabel = "PCSS Index Score - Post One Week (Calculated)"
	        value = {this.getNewest("PCSSIndexScorePostOneWeek").toFixed(2)}
	        handleChange={this.handleChange}
	      />

        </div>
	  );
	}
}

export default withStyles(useStyles)(PCSSPostOneWeek);


//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfPhysicalSymptomsEndorsedPostOneWeek"
//	        fieldLabel = "Number of Physical Symptoms Endorsed - Post One Week (Calculated)"
//	        value = {this.getNewest("NumberOfPhysicalSymptomsEndorsedPostOneWeek")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfPhysicalSymptomsEndorsedPostOneWeek"
//	        fieldLabel = "Percent of Physical Symptoms Endorsed - Post One Week (Calculated)"
//	        value = {this.getNewest("PercentOfPhysicalSymptomsEndorsedPostOneWeek").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfPhysicalSymptomsPostOneWeek"
//	        fieldLabel = "Average Score of Physical Symptoms - Post One Week (Calculated)"
//	        value = {this.getNewest("AverageScoreOfPhysicalSymptomsPostOneWeek").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfThinkingSymptomsEndorsedPostOneWeek"
//	        fieldLabel = "Number of Thinking Symptoms Endorsed - Post One Week (Calculated)"
//	        value = {this.getNewest("NumberOfThinkingSymptomsEndorsedPostOneWeek")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfThinkingSymptomsEndorsedPostOneWeek"
//	        fieldLabel = "Percent of Thinking Symptoms Endorsed - Post One Week (Calculated)"
//	        value = {this.getNewest("PercentOfThinkingSymptomsEndorsedPostOneWeek").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfThinkingSymptomsPostOneWeek"
//	        fieldLabel = "Average Score of Thinking Symptoms - Post One Week (Calculated)"
//	        value = {this.getNewest("AverageScoreOfThinkingSymptomsPostOneWeek").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfSleepSymptomsEndorsedPostOneWeek"
//	        fieldLabel = "Number of Sleep Symptoms Endorsed - Post One Week (Calculated)"
//	        value = {this.getNewest("NumberOfSleepSymptomsEndorsedPostOneWeek")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfSleepSymptomsEndorsedPostOneWeek"
//	        fieldLabel = "Percent of Sleep Symptoms Endorsed - Post One Week (Calculated)"
//	        value = {this.getNewest("PercentOfSleepSymptomsEndorsedPostOneWeek").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfSleepSymptomsPostOneWeek"
//	        fieldLabel = "Average Score of Sleep Symptoms - Post One Week (Calculated)"
//	        value = {this.getNewest("AverageScoreOfSleepSymptomsPostOneWeek").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfEmotionalSymptomsEndorsedPostOneWeek"
//	        fieldLabel = "Number of Emotional Symptoms Endorsed - Post One Week (Calculated)"
//	        value = {this.getNewest("NumberOfEmotionalSymptomsEndorsedPostOneWeek")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfEmotionalSymptomsEndorsedPostOneWeek"
//	        fieldLabel = "Percent of Emotional Symptoms Endorsed - Post One Week (Calculated)"
//	        value = {this.getNewest("PercentOfEmotionalSymptomsEndorsedPostOneWeek").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfEmotionalSymptomsPostOneWeek"
//	        fieldLabel = "Average Score of Emotional Symptoms - Post One Week (Calculated)"
//	        value = {this.getNewest("AverageScoreOfEmotionalSymptomsPostOneWeek").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "NumberOfTotalSymptomsEndorsedPostOneWeek"
//	        fieldLabel = "Number of Total Symptoms Endorsed - Post One Week (Calculated)"
//	        value = {this.getNewest("NumberOfTotalSymptomsEndorsedPostOneWeek")}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "PercentOfTotalSymptomsEndorsedPostOneWeek"
//	        fieldLabel = "Percent of Total Symptoms Endorsed - Post One Week (Calculated)"
//	        value = {this.getNewest("PercentOfTotalSymptomsEndorsedPostOneWeek").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />

//		  <DisplayOnlyTextField 
//	        fieldName = "AverageScoreOfTotalSymptomsPostOneWeek"
//	        fieldLabel = "Average Score of All Symptoms - Post One Week (Calculated)"
//	        value = {this.getNewest("AverageScoreOfTotalSymptomsPostOneWeek").toFixed(2)}
//	        handleChange={this.handleChange}
//	      />






